import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

function Loading() {
    return (<>
        <Box sx={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center", minHeight: "10vh" }} >
            <CircularProgress sx={{ color: "var(--primery-color)" }} />
        </Box>
    </>)
}

export default Loading