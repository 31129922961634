import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import Typography from '@mui/material/Typography';
import Date_picker from './Date_picker';
import Time_picker from './Time_picker';
import { useState } from 'react';
import Address_picker from './Address_picker';

const style = {
  position: 'absolute',
  top: '10vh',
  left: '50%',
  transform: 'translateX(-50%)',
  width: 'min(500px , 98%)',
  height: "auto",
  maxHeight: "80vh",
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px',
  overflow: "auto"
};

function Service_book_popup(props) {
  // ----switch components state----
  const [componentsType, setComponentsType] = useState('date');

  return (
    <>
      <div className="service_book_popup">
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
        {/* ---date select popup--- */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          // onClose={props.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box sx={style}>
              {componentsType === 'date' ? <Date_picker setOrderDetialsStore={props.setOrderDetialsStore} setComponentsType={setComponentsType} cancle={props.handleClose} /> : [componentsType == 'time' ? <Time_picker setOrderDetialsStore={props.setOrderDetialsStore} setComponentsType={setComponentsType} cancle={props.handleClose} /> : <Address_picker setOrderDetialsStore={props.setOrderDetialsStore} setComponentsType={setComponentsType} cancle={props.handleClose} orderConfirm={props.orderConfirm} />]}
            </Box>
          </Fade>
        </Modal>
      </div>
    </>
  );
}

export default Service_book_popup;
