import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Discount_price, Packages_price, Package_id } from '../../redux/actions/actions';

function Service_book_plan(props) {
    // ---get url service id form url---
    const { ServiceIdDetails } = useParams()

    const dispatch = useDispatch()
    //   --packagesSelect--
    const packagesSelect = (e) => {
        const { value } = e.target;
        const { id } = e.target;
        const convertNum = Number(value);

        //--set price on price in store--
        // price
        dispatch(Packages_price(convertNum))
        // id
        props.setSelectPackageId(id);
        dispatch(Package_id(id))
    };

    // ---if page reload / product change coupen empty---
    useEffect(() => {
        // --store value in store --
        dispatch(Packages_price(0))
    }, [ServiceIdDetails])


    const packages = useSelector((state) => state.get_packages.packages);


    return (


        <>
            {
                !packages ? (
                    <>

                    </>
                ) : (

                    <div className="plan">
                        <div className="plan_title">
                            <h5>Package</h5>
                            <span>Optional</span>
                        </div>

                        {packages.map((e) => {
                            return (
                                <label
                                    className="form-check-label d-block"
                                    htmlFor={e.id}
                                    key={e.id}
                                >
                                    <div className="selcet_options selected" >
                                        <div className="option">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input option_checkbox"
                                                    type="radio"

                                                    id={e.id}
                                                    value={e.price_total}
                                                    onChange={packagesSelect}
                                                    name="service_package"
                                                />

                                                {e.description}

                                            </div>
                                            <div className="option_price">
                                                $
                                                {e.price}
                                                /$
                                                {e.price_total}
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            );
                        })
                        }

                    </div>
                )
            }

        </>
    );
}

export default Service_book_plan;
