import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import First_Login_Popup from '../First_Login_Popup';
import { Total_price } from '../redux/actions/actions';
import Additional_services from './Service_book/Additional_services';
import Coupen from './Service_book/Coupen';
import Service_book_plan from './Service_book/Service_book_plan';
import WalletBox from './Service_book/WalletBox';

function Service_book_box(props) {
    const dispatch = useDispatch()
    // ---user state for validation---
    const [userDetails, setUserDetails] = useState(null)

    // --givePrice--
    const [givePrice, setGivePrice] = useState(0)
    const [disCounPri, setDisCounPri] = useState(0)
    const [additionPrice, setAdditionPrice] = useState(0)

    useEffect(() => {
        // ---userdetails---
        const userDetailsFormLocal = JSON.parse(localStorage.getItem('knoc-knoc-user-info'));
        setUserDetails(userDetailsFormLocal)
    }, [])
    // ------get amount from store and set price------
    const prices = useSelector((state) => state.get_payment_prices)

    useEffect(() => {
        let servicePrice = prices.package_price == "0" ? prices.mainPrice : prices.package_price
        let additionsalValue = servicePrice + prices.additionalServicesPrice - prices.walletBalanceAdd
        let discountValue = prices.discountPrice

        // ---discount price division---
        let disCountPrice = discountValue && discountValue.type == "fixed" ? Number(discountValue.value) : additionsalValue * Number(discountValue.value) / 100;
        setDisCounPri(disCountPrice)
        setAdditionPrice(additionsalValue)
        setGivePrice(() => {
            if (additionsalValue == disCountPrice) {
                return additionsalValue
            } else {
                return additionsalValue - disCountPrice
            }

        })

    }, [prices, givePrice])
    // ------//get amount from store and set price------


    // ---dispatch totle price---
    const dispatchTotalPrice = () => {
        dispatch(Total_price(givePrice))
    }

    // ----first login popup function----
    //  ---modal states--
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <div className="col-12 col-md-5 col-lg-4 side_bar">
                {!userDetails ? (
                    <First_Login_Popup
                        open={open}
                        handleOpen={handleOpen}
                        handleClose={handleClose}
                    />
                ) : (
                    ''
                )}
                <div className="plans_div">
                    <div className="plans">
                        <Additional_services />
                    </div>
                    <div className="plans">
                        <Service_book_plan setSelectPackageId={props.setSelectPackageId} />
                    </div>
                    <div className="plans">
                        <WalletBox />
                    </div>
                    <div className="plans">
                        <Coupen />
                    </div>

                    <div className="final_price  d-flex justify-content-between px-2 mb-1 mt-3">
                        <p className="pera_common">Discount Price</p>
                        <p className="pera_common">
                            {disCounPri == additionPrice ? "$0" : `-$${disCounPri}`}
                        </p>
                    </div>

                    <div className="final_price  d-flex justify-content-between px-2 mb-1 mt-3">
                        <p className="pera_common">Service Price</p>
                        <p className="pera_common">
                            ${prices.mainPrice}
                        </p>
                    </div>

                    <div className="final_price  d-flex justify-content-between px-2 mb-3 mt-3">
                        <p className="pera_common">Total Price</p>
                        <p className="pera_common">
                            $
                            {givePrice}
                        </p>
                    </div>

                    <div className="button_box">
                        {!userDetails ? (
                            <button className="common_button" onClick={() => {
                                setOpen(true)

                            }}>
                                Book Now
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className="common_button"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                onClick={() => {
                                    props.handleOpen()
                                    dispatchTotalPrice()
                                }}
                            >
                                Book Now
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Service_book_box;
