import React from "react";
// --component--
import Payment_methods from "../Profile_component/Payment_methods";
import Address from "../Profile_component/Address";
import MyFavourites from "../Profile_component/MyFavourites";
import Profile_sidebar from "../Profile_component/Profile_sidebar";
import Rating_section from "../Profile_component/Rating";
// --css--
import "./Profile.css";
// import { Rating } from "@mui/material";
import Vouchers from "../Profile_component/Vouchers";
import Referrals from "../Profile_component/Referrals";
import Cat_provider from "../Profile_component/Cat_provider";
import Get_Help from "../Profile_component/Get_help";
import { useState } from "react";
import About from "../Profile_component/About";
import Terms_Conditions from "../Profile_component/Terms_Conditions";
import { Wallet } from "@mui/icons-material";
import Wallet_compo from "../Profile_component/Wallet_compo";
import { Outlet } from "react-router-dom";
function Profile() {


  // ------function for tabes------------

  const [tabs, settabs] = useState(1)

  const tabsFun = (index) => {
    settabs(index);
  }


  // ----stat for sidebar open close----
  const [sideBar, setsideBar] = useState("sidebar_div");
  const [sideBarBtn, setsideBarBtn] = useState(1);

  const sideBarOpen = () => {
    if (sideBarBtn == 1) {
      setsideBar("sidebar_div active");
      setsideBarBtn(2)
    } else if (sideBarBtn == 2) {
      setsideBar("sidebar_div");
      setsideBarBtn(1)
    }

  }




  // ----------------------------------------------
  return (
    <>
      <>
        {/*   ---------Page--------- */}

        <main>
          <section className="main_content profile_main_content">
            <div className="container-fluid profile_main_div">
              <div className="row">
                <div className="col-12 side_bar_button">
                  <button id="sideBarBtn" onClick={sideBarOpen}>
                    <i className="bi bi-layout-sidebar-inset-reverse" />
                  </button>
                </div>
                <div className="col-12">
                  {/* -----sidebar & contnet----- */}
                  <div className="boxes">
                    {/* --------------sidebar---------- */}
                    <Profile_sidebar indexC={tabs} fun={tabsFun} sideBarC={sideBar} sideBarOpen={sideBarOpen} />
                    {/* --------------//sidebar---------- */}

                    {/* -----------------content----------- */}
                    <div className="contnet_div">
                      {/* address */}
                      {/* <Address indexC={tabs} /> */}
                      {/* //address */}
                      {/* payment_methods */}
                      {/* <Payment_methods indexC={tabs} /> */}
                      {/* //payment_methods */}
                      {/* wallet */}
                      {/* <Wallet_compo indexC={tabs} /> */}
                      {/* //wallet */}

                      {/* favourites */}
                      {/* <MyFavourites indexC={tabs} /> */}
                      {/* //favourites */}

                      {/* ratings */}
                      {/* <Rating_section indexC={tabs} /> */}
                      {/* //ratings */}
                      {/* vouchers */}
                      {/* <Vouchers indexC={tabs} /> */}
                      {/* //vouchers */}
                      {/* referrals */}
                      {/* <Referrals indexC={tabs} /> */}
                      {/* //referrals */}
                      {/* cat_provider */}
                      {/* <Cat_provider indexC={tabs} /> */}
                      {/* //cat_provider */}
                      {/* get help */}
                      {/* <Get_Help indexC={tabs} /> */}
                      {/* //get help */}
                      {/* about */}
                      {/* <About indexC={tabs} /> */}
                      {/* /about */}
                      {/* about */}
                      {/* <Terms_Conditions indexC={tabs} /> */}
                      {/* /about */}
                      <Outlet />
                    </div>

                    {/* ---------------//content ------------------*/}
                  </div>
                  {/* -----//sidebar & contnet----- */}
                </div>
              </div>
            </div>
          </section>
        </main>
        {/*   ---------//Page--------- */}
      </>
    </>
  );
}

export default Profile;
