import React from 'react'

function BannerVideo(props) {
    return (
        <video loop={true} autoPlay="autoplay" muted webkit-playsinline="true" playsInline={true}>
            <source src={`${process.env.REACT_APP_API_KEY}/${props.url}`} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    )
}

export default BannerVideo