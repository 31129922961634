import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { async } from '@firebase/util';
import { useEffect } from 'react';
import { Service_Rating, Service_Review } from '../../redux/actions/actions';


const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: 'var(--primery-color)',
    },
    '& .MuiRating-iconHover': {
        color: 'var(--primery-color)',
    },
});


function Rating_section() {
    const dispatch = useDispatch()
    // ----get cat id from url---
    const urlId = useParams();
    const getSelectedCatId = urlId.ServiceIdDetails;

    // -----get ratings----
    const getRatings = async () => {
        // --call api--
        await axios.post(`${process.env.REACT_APP_API_KEY}/api/get_rating`, {
            "user_firebase_id": "",
            "ser_id": "",
            "ser_slug": `${getSelectedCatId}`
        }).then((res) => {
            dispatch(Service_Review(res.data.data))
            // setServiceReviews(res.data.data)
        }).catch((err) => {
            console.warn(err)
        })
    }
    // -----//get ratings----


    const userDetials = useSelector((state) => state.get_service_reviews.serivceReview)



    useEffect(() => {
        getRatings()
    }, [getSelectedCatId])

    return (
        <>
            <div className="container-fluid">
                {/* ------give rating box------ */}
                <div className="row ">
                    <Divider />
                    <div className="col-12 p-0 py-3">
                        <p className='fs-5 fw-bold m-0'>Reviews...</p>
                    </div>
                    {/* <div className="col-12">
                        <div className="row border-bottom py-2">

                            <div className="col-12   ">
                                <div className="row">
                                    <div className="col-12 px-0">

                                        <textarea className="form-control shadow-none" placeholder="Enter your comment here..." id="floatingTextarea2" style={{ height: "5rem" }} onChange={(e) => setReviewText(e.target.value)}></textarea>
                                    </div>
                                    <div className="col-12 my-2 px-0">

                                        <StyledRating
                                            name="customized-color"
                                            defaultValue={1}
                                            getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
                                            precision={1}
                                            icon={<FavoriteIcon fontSize="inherit" />}
                                            emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
                                            onChange={(e) => setRatingStar(e.target.value)}
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className="col-12   px-0">
                                <button className='common_button' onClick={giveRating}>Comment</button>
                            </div>

                        </div>

                    </div> */}
                </div>
                {/* ------//give rating box------ */}

                {/* ------get rating box------ */}
                <div className="row">
                    <div className="col-12 p-0">

                        {!userDetials || userDetials == undefined ? <div className='d-flex align-items-center g-2'>
                            <img src="../../images/knoc knoc logo preloader.gif" alt="logo" style={{ width: "min(60px , 100%)" }} />
                            <p className="fs-6">No Reviews</p>
                        </div>
                            : userDetials.map((e) => {
                                return (
                                    <React.Fragment key={e?.id}>
                                        {/* <h1>{e.review}</h1> */}
                                        <div className="mb-3 p-0 border-bottom">
                                            <p className="fw-bold">{e.username}</p>
                                            <p className="text-secondary">{e.review}</p>
                                        </div>
                                    </React.Fragment>
                                )
                            })}

                    </div>
                </div>
                {/* ------//get rating box------ */}
            </div>
        </>
    )
}

export default Rating_section