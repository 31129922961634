import React from 'react'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { SiIndeed } from "react-icons/si"
import image from "./footerImage.png"
import image2 from "./Group 11.png"
import './Footer.css'
import { useNavigate } from 'react-router-dom';
import First_Login_Popup from '../First_Login_Popup';
import { Facebook } from '@mui/icons-material';
import { BsTiktok } from 'react-icons/bs'

function Footer() {
    const navigate = useNavigate()

    // ----first login popup function----
    // ---get user data from store---
    const user_info = JSON.parse(localStorage.getItem("knoc-knoc-user-info"))
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            {!user_info ? <First_Login_Popup open={open} handleOpen={handleOpen} handleClose={handleClose} /> : ""}
            <footer>
                <div className='MainFooter'>
                    <div className='LeftFooter'>
                        <img src={image} alt="" />
                    </div>
                    <div className='rightFooter'>
                        <div className='QuickLinks'>
                            <div className='LeftMain'>
                                <div className='Left_title'>
                                    <img src={image2} alt="" />
                                    <h4>Quick Links</h4>
                                </div>
                                <ul>
                                    <li onClick={() => { navigate("/Profile/get_help") }}>Get Help</li>
                                    <li onClick={() => { !user_info ? setOpen(true) : navigate("/Profile/cat_provider") }}>Cat provider</li>
                                    <li onClick={() => { navigate("/Profile/about") }}>About Knoc Knoc</li>
                                    <li onClick={() => { navigate("/Profile/terms_cnditions") }}>Terms & Condition</li>
                                    {/* <li onClick={() => { !user_info ? setOpen(true) : navigate("/Profile") }}>Privacy Policy</li> */}
                                </ul>
                            </div>
                            <div className='RightMain'>
                                <div className='Right_title'>
                                    <img src={image2} alt="" />
                                    <h5>Social Media Links</h5>
                                </div>
                                <div className='Icon'>
                                    <span> <a href="https://wa.me/7339928167" target="_blank" ><BsTiktok style={{ color: "white", width: "100%" }} /></a></span>
                                    <span><a href="https://www.instagram.com/knocknoc_sg/" target="_blank"> <InstagramIcon sx={{ color: "white", width: "100%" }} /></a></span>
                                    <span><a href="https://www.facebook.com/profile.php?id=100085595374675" target="_blank"><Facebook sx={{ color: "white", width: "100%" }} onClick={() => navigate("/")} /></a></span>
                                    {/* <span><a href=""><SiIndeed width="100%" color='white' onClick={() => navigate("/")} /></a></span> */}
                                </div>
                            </div>
                            <div className='appDonwloadLikns'>
                                <span> <a href="https://play.google.com/store/apps/details?id=com.Liuxiaomao.knocknoc" target="_blank" ><img src='./images/get-it-on-google-play-badge.png' style={{ color: "white", width: "100%" }} /></a></span>
                                <span> <a href="https://apps.apple.com/us/app/knoc-knoc-sg/id6445861760" target="_blank" ><img src='./images/appstore.webp' style={{ color: "white", width: "100%" }} /></a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer