import React, { useEffect } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { ADD_categoryes, Get_categories_videos } from '../redux/actions/actions'
import { NavLink } from 'react-router-dom'
import { STATUSES } from '../redux/reducers/reducers'

function Gallery_video_folders() {
    const dispatch = useDispatch()
    // --get categories--
    const categoriesData = useSelector((state) => state.set_categourys)

    useEffect(() => {
        categoriesData.status != STATUSES.IDEL && dispatch(ADD_categoryes())
    }, [])

    return (
        <div className="gallery_video_folders">
            {categoriesData.status === STATUSES.LOADING ? "loading" : categoriesData.services.map((e) => {
                return (
                    <NavLink to={`/Gallery/videos/${e.id}`} key={e.id}>
                        <div className="folderDiv">
                            <div className="imageDiv">
                                <img src={`${process.env.REACT_APP_API_KEY}${e.image}`} alt="" />
                            </div>
                            <div className="folderName">
                                <h5>{e.cat_name}</h5>
                            </div>
                        </div>
                    </NavLink>
                )
            })}

        </div>
    )
}

export default Gallery_video_folders