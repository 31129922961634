import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Get_favorite } from "../redux/actions/actions";
import Swal from "sweetalert2";
import ImageErrorFallback from "../../commonFunctions/ImageErrorFallback";

function MyFavourites(props) {
  const dispatch = useDispatch()
  // ----firebase id get from store---
  const userDataLocal = localStorage.getItem("knoc-knoc-user-info")
  const firebaseIdUser = JSON.parse(userDataLocal)

  // ----show toster----
  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
  // ----get favrate data from api---
  const getFavorite = async () => {

    const getFavoriteData = await axios.post(`${process.env.REACT_APP_API_KEY}/api/get_favoritelist`, {
      "user_firebase_id": firebaseIdUser.firebase_id
    })
    const getfavoriteData_final = getFavoriteData.data.data;
    dispatch(Get_favorite(getfavoriteData_final))
  }

  //  ----get data from store-----
  const favoriteList = useSelector((state) => state.set_favorite.items)
  const favoriteList_filter = favoriteList ? favoriteList.filter((obj, index) => { return index === favoriteList.findIndex(o => obj.ser_id === o.ser_id); }) : ""


  useEffect(() => {
    favoriteList && favoriteList.length < 1 && getFavorite();
  }, [])



  // ----item remove function----
  const itemRemove = async (e) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to delete this!",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: 'var(--primery-color)',
      cancelButtonColor: 'var(--secondary-color)',
      confirmButtonText: 'Yes, remove it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        // ----
        await axios.post(`${process.env.REACT_APP_API_KEY}/api/favorite`, {
          "user_firebase_id": `${firebaseIdUser.firebase_id}`,
          "ser_id": `${e}`,
          "status": 0
        }).then(() => {
          getFavorite();
        }).catch(() => {
          Toast.fire({
            icon: 'error',
            title: 'Something went wrong'
          })
        })
        // ---
      }
    })

  }





  return (<>
    <div
      className="content_items favourites show" >
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 heading_item">My Favourites</div>


          {/* --card */}
          {!firebaseIdUser || !favoriteList ?

            //  --if no items available then show it--
            <>
              <div className="no_services_div">
                <img src="../../images/gif_img_1.gif" alt="#" />
                <p>No Items Available</p>
              </div>
            </>

            :
            <>
              <div className="col-12 cards" >
                {favoriteList_filter.map((e) => {
                  return (

                    <div className="card" key={e.id}>
                      <button className="close_button" onClick={() => itemRemove(e.ser_id)}>
                        <i className="bi bi-x-lg" />
                      </button>
                      <figure>
                        <ImageErrorFallback
                          src={`${process.env.REACT_APP_API_KEY}/${e.image}`}
                          className="card-img-top"
                          alt={e.ser_name}
                        />
                      </figure>
                      <div className="card-body">
                        <p className="card-title">
                          <span>{e.ser_name}</span>
                          <span>
                            <i className="bi bi-star-fill" />
                            4.5
                          </span>
                        </p>
                        <p className="price">${e.price}</p>
                        <div className="btn_div">
                          <NavLink to={`/Profile/${e.cat_slug}/${e.ser_slug}`} className="btn button">
                            Book Now
                          </NavLink>
                        </div>
                      </div>
                    </div>

                  )
                })}
              </div>
            </>
          }
          {/* --//card */}

        </div>
      </div>
    </div>
  </>)
}

export default MyFavourites;