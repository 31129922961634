import { applyMiddleware, createStore, compose } from "redux";
import reducers from "./reducers";
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from "redux-thunk";


const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['set_bannerImages', 'set_categourys', 'Add_gallery_images']
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedReducer = persistReducer(persistConfig, reducers)

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)))

export default store;