import { combineReducers } from "redux";
import { CatagoryReducer, Subcategories, Showservices, Showactivity, Service_details, Showfavorite, ShowUserData, ShowAlerts, GetAddress, GetPackages, GetOrderDetails, GetServiceRating, GetServiceReview, Show_my_packages, Get_additional_service, GetPaymentDetails, getOrderAllDetials, HomePageReviews, GetCategoriesVideos, BannerImages, AddGalleryImages, } from "./reducers";

const reducers = combineReducers({
    set_bannerImages: BannerImages,
    set_categourys: CatagoryReducer,
    Add_gallery_images: AddGalleryImages,
    set_Subcategories: Subcategories,
    Set_services: Showservices,
    Set_service_details: Service_details,
    Set_activities: Showactivity,
    set_favorite: Showfavorite,
    set_userData: ShowUserData,
    set_alert_details: ShowAlerts,
    get_user_address: GetAddress,
    get_packages: GetPackages,
    get_OrderAllDetials: getOrderAllDetials,
    get_orderDetails: GetOrderDetails,
    get_service_reating: GetServiceRating,
    get_service_reviews: GetServiceReview,
    get_my_packages: Show_my_packages,
    get_additional_services: Get_additional_service,
    get_payment_prices: GetPaymentDetails,
    HomePageReviews: HomePageReviews,
    GetCategoriesVideos: GetCategoriesVideos

})

export default reducers;