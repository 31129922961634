import React from 'react'
import './Payment_comp.css'
import suucess from "../../images/Completed-payment.gif"
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
function Payment_success_page() {
    const navigate = useNavigate();
    useEffect(() => {
        setTimeout(() => {
            navigate("/")
        }, 6000)
    }, [])
    return (
        <div className='payment_comp'>
            <span className="shap shap_1"></span>
            <span className="shap shap_2"></span>
            {/* --contnet-- */}
            <div className="content">
                <div className="icon">
                    <img src={suucess} alt="payment success" />

                </div>
                <div className="text">
                    <p className="heading_common">Payment Success</p>
                    <p className="pera">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint, nostrum? Ducimus non libero perferendis totam sed cumque dolor suscipit excepturi?</p>
                    <button onClick={() => { navigate("/") }}>Go Back</button>
                </div>
            </div>
        </div>
    )
}

export default Payment_success_page