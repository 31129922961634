import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import ReactStars from "react-rating-stars-component";
import { Show_alerts } from '../redux/actions/actions';
import { useDispatch } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function MyOrders_detials() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    // --get order if from url--
    const { orderId } = useParams();
    // --orderDetials state--
    const [orderDetials, setOrderDetials] = useState([])
    // --get firebase id from local storage--
    const fireBaseId = JSON.parse(localStorage.getItem("knoc-knoc-user-info"))
    // ---rating state--
    const [rating, setRating] = useState(1)
    // ---rating massage state--
    const [ratingMassage, setRatingMassage] = useState("")
    //  ---error msg---
    const [errorMassage, setErrorMassage] = useState("")

    // alert(orderId)
    // --api call for get order detials--
    const orderDetialsFun = async () => {
        await axios.post(`${process.env.REACT_APP_API_KEY}/api/get_order`, {
            "page_no": "1",
            "user_firebase_id": `${fireBaseId ? fireBaseId.firebase_id : ""}`
        }).then((res) => {
            const resData = res.data.data;
            // --filter data according to order id--
            const orderDetailsGet = resData.filter((e) => e.id == orderId)
            // --convert array to object--
            const convertArrayToObject = Object.assign({}, ...orderDetailsGet)

            // --set data--
            setOrderDetials(convertArrayToObject)

        }).catch((err) => {
            console.log(err)
        })
    }


    useEffect(() => {
        orderDetialsFun()
    }, [])

    // ---api function for give rating---
    const giveRatingFun = async () => {
        await axios.post(`${process.env.REACT_APP_API_KEY}/api/rating`, {
            "user_firebase_id": `${fireBaseId ? fireBaseId.firebase_id : ""}`,
            "order_id": `${orderDetials.id}`,
            "sr_id": `${orderDetials.ser_id}`,
            "rating": rating,
            "review": `${ratingMassage}`
        }).then((res) => {
            setRatingMassage("")
            // --alert data send---
            dispatch(
                Show_alerts({
                    alert_message: "Rating Send Successfully",
                    alert_type: "success",
                })
            );
        }).catch((err) => {
            console.log(err)
        })
    }
    // ----giveRating----
    const giveRating = (e) => {
        e.preventDefault()

        if (ratingMassage === "") {
            setErrorMassage("Pls Enter Message")
        } else {
            giveRatingFun()
        }
    }
    // ----//giveRating----
    let additional_servicesPrice = 0
    let servicePrice = orderDetials.ser_price

    // --get totle price--
    const additional_servicesPrice_set = orderDetials.additional_services ? orderDetials.additional_services.map(e => additional_servicesPrice = additional_servicesPrice + Number(e.price)) : additional_servicesPrice = 0;
    const totlePrice = servicePrice + additional_servicesPrice
    // --//get totle price--


    return (
        <>
            {/* ----upper section---- */}
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 heading_item" style={{
                            borderBottom: "2px solid var(--primery-color)",
                            padding: "0.5rem",
                            fontSize: "1.6rem",
                            fontWeight: '600',
                            marginBottom: "1.2rem",
                            textAlign: "center"
                        }}> Order Detials</div>
                    </div>

                    <div className="container top_bar_div">
                        {/* --topbar */}
                        <div className="row px-sm-3">
                            <div className="col-12 topbar">
                                <span onClick={() => navigate(-1)}>
                                    <i className="bi bi-arrow-left" />
                                    Back
                                </span>
                            </div>
                        </div>
                        {/* --//topbar */}
                    </div>
                </div>
                <div className="container py-3">
                    <div className="row mb-3">
                        {/* --service image---- */}
                        <div className="col-12 col-sm-4 col-md-5 col-lg-6">
                            <LazyLoadImage src={`${process.env.REACT_APP_API_KEY}${orderDetials.ser_image}`} effect="blur" className="img-fluid w-100 rounded" alt="orderDetials_img" />
                        </div>
                        {/* --service detials---- */}
                        <div className="col-12 col-sm-8 col-md-7 col-lg-6 py-2">
                            {/* */}
                            <div className="row">
                                <div className="col-4 d-flex justify-content-between  fs-5 fw-bold">Sevice Name <span>-</span> </div>
                                <div className="col">{orderDetials.ser_name}</div>
                            </div>
                            <div className="row">
                                <div className="col-4 d-flex justify-content-between  fs-5 fw-bold">Subcategory Name <span>-</span> </div>
                                <div className="col"> {orderDetials.subcat_name}</div>
                            </div>
                            <div className="row">
                                <div className="col-4 d-flex justify-content-between  fs-5 fw-bold">Category Name <span>-</span> </div>
                                <div className="col">{orderDetials.cat_name}</div>
                            </div>
                            <div className="row">
                                <div className="col-4 d-flex justify-content-between  fs-5 fw-bold">Invoice No. <span>-</span> </div>
                                <div className="col">{orderDetials.invoice_no} </div>
                            </div>
                            <div className="row">
                                <div className="col-4 d-flex justify-content-between  fs-5 fw-bold">Date <span>-</span> </div>
                                <div className="col"> {orderDetials.date}</div>
                            </div>
                            <div className="row">
                                <div className="col-4 d-flex justify-content-between  fs-5 fw-bold">Time <span>-</span> </div>
                                <div className="col"> {orderDetials.time}</div>
                            </div>
                            <div className="row">
                                <div className="col-4 d-flex justify-content-between  fs-5 fw-bold">Price <span>-</span> </div>
                                <div className="col"> {orderDetials.price}</div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-4 d-flex justify-content-between fs-5 fw-bold">Description <span className="d-none d-sm-inline ">-</span> </div>
                                <div className="col"> {orderDetials.ser_description}</div>
                            </div>
                            {/*  */}
                        </div>
                    </div>
                    {/* ---address--- */}
                    <div className="row mb-3">
                        <div className="col-12 col-sm-6">
                            <p className="fw-bold  fs-5" style={{ color: "var( --primery-color)" }}>
                                Address
                            </p>
                            {
                                orderDetials.address ? <p>{orderDetials.address.house_no},{orderDetials.address.area_city},{orderDetials.address.post_code},{orderDetials.address.street},{orderDetials.address.state}</p> : ""
                            }

                        </div>
                    </div>

                    {/* ---address--- */}
                    <div className="row mb-3">
                        <div className="col-12 col-sm-6">
                            <p className="fw-bold  fs-5" style={{ color: "var( --primery-color)" }}>
                                Add-on Details
                            </p>
                            {
                                orderDetials.additional_services && orderDetials.additional_services.length >= 1 ? orderDetials.additional_services.map((e) => {
                                    return (
                                        <p className="d-flex justify-content-between" >
                                            <span> {e.additional_ser_name}</span><span> -  ${e.price}</span>
                                        </p>
                                    )
                                }) :
                                    <p className="d-flex justify-content-between">
                                        <span>No Services</span>
                                    </p>
                            }

                        </div>
                    </div>


                    {/* ---bill detials--- */}
                    <div className="row mb-3">
                        <div className="col-12 col-sm-6">
                            <p className="fw-bold  fs-5" style={{ color: "var( --primery-color)" }}>
                                Bill detials
                            </p>
                            <p className="d-flex justify-content-between">
                                <span>Service Price</span><span>${orderDetials.ser_price}</span>
                            </p>
                            <p className="d-flex justify-content-between">
                                <span>Actual Price</span><span>${orderDetials.ser_price}</span>
                            </p>
                            <hr />
                            <p className="d-flex justify-content-between">
                                <span className="fw-bolder">Total Amount</span> <span>${totlePrice}</span>
                            </p>
                        </div>
                    </div>

                    {/* ---reating--- */}
                    <div className="row mb-3">
                        <div className="col-12 col-sm-6">
                            <p className="fw-bold  fs-5" style={{ color: "var( --primery-color)" }}>
                                Give Review &amp; Ratings
                            </p>
                            {/* --textarea-- */}
                            <div className='mt-2'>
                                <form onSubmit={giveRating}>
                                    <ReactStars
                                        count={5}
                                        onChange={(e) => setRating(e)}
                                        size={30}
                                        activeColor="#ffd700"
                                        value={0}

                                    />
                                    <div >

                                        <textarea className="form-control my-3 shadow-none" id="exampleFormControlTextarea1" rows="3" placeholder='Write down your review...' value={ratingMassage} onChange={(e) => {
                                            setRatingMassage(e.target.value)
                                            setErrorMassage("")
                                        }}></textarea>
                                        <p className="mb-1 text-danger">{errorMassage}</p>
                                        <button type="submit" className="common_button" >Send</button>
                                    </div>
                                </form>
                            </div>
                            {/* --//textarea-- */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default MyOrders_detials