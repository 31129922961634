import React from 'react'
import "./About.css"

function About(props) {
    return (
        <div className="content_items about show">
            {/* ---------banner---- */}
            <section id="banner">
                <div className="container-fluid">
                    <div className="image-box">
                        <p>About Us</p>
                    </div>
                </div>
            </section>
            {/* ---------//banner---- */}
            {/* --------details --------- */}
            <section id="about-details">
                <div className="container px-0">
                    <div className="row my-3">
                        <div className="col-12 py-3 py-2 py-md-3 ">
                            <p className="heading mb-3">General info on brand and services.</p>
                            <p className="para text-center fw-bold">What is Knoc Knoc?</p>
                            <p className="para mb-2 text-center">

                                A: Founded in 2022, Knoc Knoc is a super app that combines curated and service oriented / caring cat providers to manage and provide a myriad of services to women of the 21st century. From wedding services, essential services, indulgence services, mummy and kiddy services, car workshop services, charity and volunteer services and to even ride hailing services, Knoc Knoc prides and aim to be the first and only super app that a women needs, at all times of the day and always. Meowww
                            </p>
                            <p className="pera mb-2 text-center">Knoc Knoc works with more than 600 cat providers from the whole series of curated services to bring the best and affordable caring and pampering to women. With the whole suite of payment options, buy now pay later and installment payment plan, this is to allow women to have the option to choose on their favourite mode of payment and earn cashback and rebates. </p>
                            <p className="para mb-2 text-center" >To book a service with our cat providers, simply download Knoc Knoc in Apple and Google playstore or contact with our Happy Cat via 87713358 on whatsapp or email us at cscat@knocknoc.biz </p>
                        </div>

                        {/* ---- */}


                    </div>
                </div>
            </section>
        </div>
    )
}

export default About