import React from "react";
import {
    EmailShareButton,
    FacebookShareButton,
    WhatsappShareButton,
} from "react-share";

import { WhatsappIcon, EmailIcon } from "react-share";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Stack } from "@mui/system";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "min(600px , 90%)",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
    textAlign: "center"
};

function Referrals(props) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // --get reffer code from local storage--
    const refferCode = JSON.parse(localStorage.getItem("knoc-knoc-user-info"))

    return (
        <>
            {/* ---modal--- */}
            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography variant="h4">KNOC KNOC</Typography>
                        <Typography variant="subtitle2" sx={{ my: 2 }}>Your friend get $5.00 off their first service when they use your link to sign up and you will receive a $5.00 off voucher</Typography>

                        <Stack direction="row" justifyContent="center" gap="10px">

                            {/* whatsapp share */}
                            <WhatsappShareButton url={`Hey! Join KNOC KNOC and get better services. 🤗 Their FREE Account comes with totally awesome and exciting benefits! 🎁🎊 Sign up using my Referral Code "${refferCode.refferal_code}" or Click here https://knocknoc-react.ondemandservicesappinflutter.online/`}
                                hastag="#knocknoc"
                            >
                                <WhatsappIcon size={50} round={true} />
                            </WhatsappShareButton>
                            {/* //whatsapp share */}

                            {/* email share */}
                            {/* <EmailShareButton url={`Hey! Join KNOC KNOC and get better services. 🤗 Their FREE Account comes with totally awesome and exciting benefits! 🎁🎊 Sign up using my Referral Code " ${refferCode.refferal_code}" or Click here https://knocknoc-react.ondemandservicesappinflutter.online/`}
                                hastag="#knocknoc"
                            >
                                <EmailIcon size={50} round={true} />
                            </EmailShareButton> */}
                            {/* //email share */}
                        </Stack>
                    </Box>
                </Modal>
            </div>
            {/* ---//modal--- */}

            <div
                className="content_items referrals show" >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 heading_item">Vouchers</div>
                        <div className="col-12">
                            <div className="image_div">
                                <img
                                    src="../images/referrals section image.png"
                                    alt="referrals image"
                                    loading="lazy"
                                    decoding="async"
                                />
                            </div>
                            <div className="content">
                                <div className="price">Earn $5.00</div>
                                <div className="pera">
                                    <h5>For each friend you invite to KNOC KNOC</h5>
                                    <p>
                                        Your friend get $5.00 off their first service when
                                        they use your link to sign up and you will receive
                                        a $5.00 off voucher
                                    </p>
                                </div>
                            </div>
                            <div className="buttons" >
                                <a onClick={handleOpen}>Share Link</a>
                                <a>Scan QR code</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Referrals;