

import axios from "axios";
import { Action_types } from "../contants/actions_types";

// ---------ACTIONS-------

const Banner_images = () => async (dispatch) => {
    dispatch({ type: Action_types.BANNER_IMAGES_REQ })
    await axios.get(`${process.env.REACT_APP_API_KEY}/api/Banner`).then((res) => {
        dispatch({ type: Action_types.BANNER_IMAGES_SUCCESS, payload: res.data.data })
    }).catch(() => dispatch({ type: Action_types.BANNER_IMAGES_FAILED }))
}


const ADD_categoryes = () => async (dispatch) => {
    dispatch({ type: Action_types.SHOW_CATEGORIES_REQ })
    // --
    await axios.get(`${process.env.REACT_APP_API_KEY}/api/category`).then((res) => {
        dispatch({ type: Action_types.SHOW_CATEGORIES_SUCCESS, payload: res.data.data })
    }).catch((err) => {
        dispatch({ type: Action_types.SHOW_CATEGORIES_FAIL })
    })
}



const Add_gallery_images = () => async (dispatch) => {
    dispatch({ type: Action_types.SHOW_GALLERY_IMAGES_REQ })
    await axios.post("https://knocknoc-webpanel.knocknoc.sg/api/gallery", {
        "type": "image",
        "cat_id": ""
    }).then((res) => {
        dispatch({ type: Action_types.SHOW_GALLERY_IMAGES_SUCCESS, payload: res.data.data })
    }).catch(() => dispatch({ type: Action_types.SHOW_GALLERY_IMAGES_FAIL }))
}



const Show_sub_categories = (sub_categories) => {
    return {
        type: Action_types.SHOW_SUB_CATEGORIES,
        payload: sub_categories
    }
}


const Show_services = ({ cat_Filter_id, firebase_id }) => async (dispatch) => {
    console.log(cat_Filter_id, firebase_id, "firebase_id")
    dispatch({ type: Action_types.SHOW_SERVICES_REQ })

    await axios.post(`${process.env.REACT_APP_API_KEY}/api/subcategory2`, {
        cat_id: `${cat_Filter_id}`,
        subcat_id: "",
        user_firebase_id: `${!firebase_id ? "" : firebase_id}`,
    }
    )
        .then((res) => {
            const servicesData_final = res.data.data;
            dispatch({ type: Action_types.SHOW_SERVICES_SUCCESS, payload: servicesData_final })

        })
        .catch((error) => {
            dispatch({ type: Action_types.SHOW_SERVICES_FAIL })
        });
}

const Remove_show_services = () => {
    return {
        type: Action_types.REMOVE_SHOW_SERVICES,
        // payload : services
    }
}

const Get_services_detials = (service_details) => {
    return {
        type: Action_types.GET_SERVICE_DETAILS,
        payload: service_details
    }
}
const Remove_services_detials = () => {
    return {
        type: Action_types.REMOVE_SERVICE_DETAILS,
    }
}

const Show_activity = (activities) => {
    return {
        type: Action_types.SHOW_ACTIVITY,
        payload: activities
    }
}

const Get_favorite = (favorite) => {
    return {
        type: Action_types.GET_FAVORITE,
        payload: favorite
    }
}


const Get_user_data = (data) => {
    return {
        type: Action_types.USER_DATA,
        payload: data
    }
}
// ALERT_TYPES
const Show_alerts = (alert_data) => {
    return {
        type: Action_types.ALERT_TYPES,
        payload: alert_data
    }
}


// USER_ADDRESSES
const User_address = (address_data) => {
    return {
        type: Action_types.USER_ADDRESS,
        payload: address_data
    }
}
// PACKAGES
const Service_packages = (packages_data) => {
    return {
        type: Action_types.PACKAGES,
        payload: packages_data
    }
}


//SERVICE_REATING
const Service_Rating = (rating_data) => {
    return {
        type: Action_types.SERVICE_REATING,
        payload: rating_data
    }
}
//SERVICE_REVIWS
const Service_Review = (review_data) => {
    return {
        type: Action_types.SERVICE_REVIWS,
        payload: review_data
    }
}

//SHOW_MY_PACKAGES
const My_packages = (myPackages) => {
    return {
        type: Action_types.SHOW_MY_PACKAGES,
        payload: myPackages,
    }
}

// ADDITIONAL_SERVICES
export const Additional_service = (data) => {
    return {
        type: Action_types.ADDITIONAL_SERVICES,
        payload: data
    }
}

// -----order detials get-----
// OREDER_ALL_DETAILS
export const Order_all_details = (order_allDetials) => {
    return {
        type: Action_types.OREDER_ALL_DETAILS,
        payload: order_allDetials
    }
}

//ORDER_SER_ID
export const Order_ser_id_get = (order_id) => {
    return {
        type: Action_types.ORDER_SER_ID,
        payload: order_id
    }
}

// ADDITIONAL_SERVICE_LIST
export const Additional_service_List = (additional_service_List) => {
    return {
        type: Action_types.ADDITIONAL_SERVICE_LIST,
        payload: additional_service_List
    }
}
//PACKAGE_ID
export const Package_id = (package_id) => {
    return {
        type: Action_types.PACKAGE_ID,
        payload: package_id
    }
}

//ORDER_DATE
export const Order_date_Get = (orderDate_get) => {
    return {
        type: Action_types.ORDER_DATE,
        payload: orderDate_get
    }
}

//ORDER_TIME
export const Order_time_Get = (orderTime_get) => {
    return {
        type: Action_types.ORDER_TIME,
        payload: orderTime_get
    }
}
//ORDER_ADDRESS
export const Order_addressId_Get = (orderAddressId_get) => {
    return {
        type: Action_types.ORDER_ADDRESS_ID,
        payload: orderAddressId_get
    }
}

//ORDER_ADDRESS
export const Order_coupenId_Get = (id) => {
    return {
        type: Action_types.COUPEN_ID,
        payload: id
    }
}

// -----payment detials get-----

//  SERVICE_MAIN_PRICE
export const Service_main_price = (main_price) => {
    return {
        type: Action_types.SERVICE_MAIN_PRICE,
        payload: main_price
    }
}

//  PACKAGES_PRICE
export const Packages_price = (package_price) => {
    return {
        type: Action_types.PACKAGES_PRICE,
        payload: package_price
    }
}

//ADDITIONAL_SERVICES_PRICE
export const Additional_service_price = (additional_service_price) => {
    return {
        type: Action_types.ADDITIONAL_SERVICES_PRICE,
        payload: additional_service_price
    }
}

//DISCOUNT_PRICE
export const Discount_price = (discount_price) => {
    return {
        type: Action_types.DISCOUNT_PRICE,
        payload: discount_price
    }
}

//TOTEL_PRICE
export const Total_price = (total_price) => {
    return {
        type: Action_types.TOTAL_PRICE,
        payload: total_price
    }
}
//WALLET_BALANCE
export const Wallet_balance = (wallet_price) => {
    return {
        type: Action_types.WALLET_BALANCE,
        payload: wallet_price
    }
}
//WALLET_BALANCE_ADD
export const Wallet_balance_add = (wallet_price_Add) => {
    return {
        type: Action_types.WALLET_BALANCE_ADD,
        payload: wallet_price_Add
    }
}

//HOME_PAGE_REVIEWS
export const Home_page_reviews = (reviewsData) => {
    return {
        type: Action_types.HOME_PAGE_REVIEWS,
        payload: reviewsData,
    }
}
//GET_CATEGORIES_VIDEOS
export const Get_categories_videos = (catData) => {
    return {
        type: Action_types.GET_CATEGORIES_VIDEOS,
        payload: catData,
    }
}


export { Banner_images, ADD_categoryes, Add_gallery_images, Show_sub_categories, Show_services, Remove_show_services, Get_services_detials, Remove_services_detials, Show_activity, Get_favorite, Get_user_data, Show_alerts, User_address, Service_packages, Service_Rating, Service_Review, My_packages }