import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Discount_price, Order_coupenId_Get } from '../../redux/actions/actions';

// ----this is child of service_book_plan comp.----
function Coupen(props) {
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState("");
    const [message, setMessage] = useState("")

    // ----show toster----
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    //  ---get user id from locals store--
    const userDetails = JSON.parse(localStorage.getItem('knoc-knoc-user-info'));
    // ---price detials get from store----
    const mainPrice = useSelector((state) => state.get_payment_prices.mainPrice)

    // ----coupen functin----
    const coupenApi = async () => {
        if (inputValue == '') {
            alert('pls enter value');
        } else {
            await axios.post(`${process.env.REACT_APP_API_KEY}/api/coupon`, {
                code: `${inputValue}`,
                user_firebase_id: `${userDetails.firebase_id}`,
                cat_id: "-1",
            }).then((res) => {

                const discount = res.data.data

                // --store value in store --
                if (discount.type == "fixed" && mainPrice < discount.value) {
                    Toast.fire({
                        icon: 'error',
                        title: 'Could not apply coupon on this'
                    })
                } else {
                    dispatch(Discount_price(discount))
                    dispatch(Order_coupenId_Get("1"))
                    // --setMassage--
                    setMessage(discount)
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    };
    // ---if page reload / product change coupen empty---
    useEffect(() => {
        // --store value in store --
        dispatch(Discount_price({ type: "", value: "0" }))
    }, [])
    return (
        <div className="coupen_enter_box">
            <p className="pera_common">Apply Coupon</p>

            <div className="input_box">
                <div className="input">
                    <input type="text" value={inputValue} onChange={(e) => { setInputValue(e.target.value); setMessage(""); dispatch(Discount_price({ type: "", value: "0" })) }} required placeholder='Enter Your Coupon Code' />
                </div>
                <div className="button">
                    <button onClick={coupenApi}>Add</button>
                </div>
            </div>
            {message === "" ? "" : <p className="text-danger pt-1">You get  {message.type == "fixed" ? `$${message.value}` : `${message.value}%`} discount</p>}

        </div>
    );
}

export default Coupen;
