import { async } from '@firebase/util';
import axios from 'axios';
import { signInWithPopup, getAuth } from 'firebase/auth';
import { getToken } from 'firebase/messaging';
import React from 'react'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Show_alerts } from '../component/redux/actions/actions';
import { app, messaging, provider } from '../firebase/firebase';

function useLogin() {
    const dispatch = useDispatch()
    const auth = getAuth(app);
    const [ismobile, setismobile] = useState(false)
    const navigate = useNavigate()


    /* ----------------------- generate fcm token function ---------------------- */
    const generateFcmToken = async () => {
        const persmission = await Notification.requestPermission();
        if (persmission === "granted") {
            //  ganrate token----
            getToken(messaging, { vapidKey: 'BO9qCw3lV3QtZ6zX3L9CGJhLwvsVpRMSxRmoxejSbWAuv-GUeviOfjngNkm1I0o25k_-ISaRbAq0LMG9Nwgmy3U' }).then((currentToken) => {
                if (currentToken) {
                    // console.log(currentToken, "fcm token ---")
                    // ---token store in session---
                    sessionStorage.setItem("myFcmToken", `${currentToken}`)
                } else {
                    // Show permission request UI
                    // console.log('No registration token available. Request permission to generate one.');
                    sessionStorage.setItem("myFcmToken", "null")
                    // ...
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                sessionStorage.setItem("myFcmToken", "null")
                // ...
            });
            //  //ganrate token----
        } else if (persmission === "denied") {
            // console.log("denied")
            sessionStorage.setItem("myFcmToken", "null")
        }
    }
    /* ----------------------- //generate fcm token function ---------------------- */



    /* ------------------------------ social login ------------------------------ */
    // ---get fcmtoken from local session---
    const fcmTokenGet = sessionStorage.getItem("myFcmToken")

    const socialLoginApiCall = async (e) => {
        console.log(e)
        const fullname = e.displayName.split(" ")
        await axios.post(`${process.env.REACT_APP_API_KEY}/api/social_login`, {
            "firebase_id": `${e.uid}`,
            "fcm_token": fcmTokenGet ? fcmTokenGet : "null",
            "firstname": `${fullname[0]}`,
            "lastname": `${fullname[1]}`,
            "email": `${e.email}`,
            "social_type": "google"
        }).then((res) => {
            // ---set data in local storage----

            let data = res.data.data
            console.log(data)
            if (data.phonenumber != "null") {
                localStorage.setItem("knoc-knoc-user-info", JSON.stringify({
                    firebase_id: `${data.firebase_id}`, firstname: `${data.firstname}`,
                    lastname: `${data.lastname}`,
                    email: `${data.email}`,
                    MobileNumber: `${data.phonenumber}`,
                    gender: `${data.gender}`,
                    image: `${data.image || ""}`
                }));
                // ----navigate to home page and reload----
                Swal.fire({
                    title: 'Login Successfull',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000
                })
            }

        }).catch((err) => {
            console.log(err)
            // --alert data send---
            dispatch(Show_alerts({
                alert_message: "Somthing went wrong!",
                alert_type: "error"
            }))
        })
    }
    const signInWithPopupFun = async () => {
        await signInWithPopup(auth, provider).then((data) => {
            const resData = data.user.providerData;
            const uid = data.user.uid
            const phoneNumber = data.user.phoneNumber
            console.log(data.user.phoneNumber, "......")
            const sandData = { ...resData[0], uid, phoneNumber }
            console.log(sandData, "resData")

            // ---call api---
            socialLoginApiCall(sandData)
            if (data.user.phoneNumber == null) {
                setismobile(true)

            } else {
                // socialLoginApiCall(sandData)
                setTimeout(() => {
                    navigate("/")
                    window.location.reload()
                }, 2000)
            }


        })
    }
    /* ------------------------------ //social login ------------------------------ */
    return { generateFcmToken, signInWithPopupFun, ismobile, setismobile }
}

export default useLogin
