function Cat_provider(props) {
    return (
        <>
            <div
                className="content_items cat_provider show">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 heading_item">Cat Provider</div>
                        <div className="col-12 content">
                            <figure>
                                <img
                                    src="../images/cat_provider_banner.webp"
                                    alt="#"
                                    loading="lazy"
                                    decoding="async"
                                />
                            </figure>
                            <div className="link">
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSdutcAPuvm1g37QExqthsmt9uB14MuUKWYYRbSsBv_J7ToUEw/viewform">Join Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cat_provider;