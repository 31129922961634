import React from 'react'
import { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function ImageErrorFallback({ src, fallbackSrc, alt, ...props }) {
    const [imgError, setimgError] = useState(false)
    const handleImageError = () => {
        setimgError(true);
    }
    return (
        <LazyLoadImage
            src={imgError ? "./images/no_image.webp" : src}
            alt={alt}
            onError={handleImageError}
            {...props}
        />
    )
}
