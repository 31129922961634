import axios from "axios";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// ----import css-----
import "./Services.css";
import ServicesCard from "./Services_card";
import ServicesCategorySlider from "./Services_category_slider";
import {
  ADD_categoryes,
  Show_services,
  Show_sub_categories,
} from "../redux/actions/actions";

import BannerVideo from "./BannerVideo";
import { useMemo } from "react";
import { STATUSES } from "../redux/reducers/reducers";
import Loading from "./Loading";
import { Helmet } from "react-helmet";

function Services() {

  // ---dispatch variable---
  const dispatch = useDispatch();
  // ----get cat id from url---
  const urlId = useParams();
  const getSelectedCatId = urlId.ServiceId;
  // --user detials--
  const userDetails = JSON.parse(localStorage.getItem("knoc-knoc-user-info"));
  // --redux store data--
  const storeData = useSelector((state) => state)

  // --SERVICES LIST--
  const [servicesList, setServicesList] = useState(null)
  const [dataForFilter, setDataForFilter] = useState([])

  // ---------api call for get categorys--------------
  const [cat_Filter, setCat_Filter] = useState(null)
  const dataValues = () => {
    if (storeData.set_categourys.status != STATUSES.IDEL) {
      dispatch(ADD_categoryes());
    }
    let categories = storeData.set_categourys.services
    setCat_Filter(categories?.find((e) => e.cat_slug == getSelectedCatId))
  };

  useEffect(() => {
    dataValues();
  }, [storeData.set_categourys.status]);

  // ----set name video link----
  const catName = cat_Filter?.cat_name;
  const bannerVideo = cat_Filter?.banner_video;

  // ---------api call for get sub categorys--------------
  const subCategroyFun = async () => {
    const subCatData = await axios.post(
      `${process.env.REACT_APP_API_KEY}/api/subcategory`,
      { cat_id: `${cat_Filter?.id}` }
    );
    const subCatData_final = subCatData.data.data;
    dispatch(Show_sub_categories(subCatData_final));
  };
  useMemo(() => subCategroyFun(), [cat_Filter])

  // -------- api call for get Services ---------
  const [updateState, setupdateState] = useState(false)
  const getServices = async () => {
    if (storeData.Set_services.storeAllLodedSerives.length > 0 && storeData.Set_services.storeAllLodedSerives.some(e => e.catId === cat_Filter.id)) {
      let getStoredData = storeData.Set_services.storeAllLodedSerives.find((s) => s.catId == cat_Filter.id).data
      setServicesList(getStoredData)
      setDataForFilter(getStoredData)
    } else {
      let runDispatch = await dispatch(Show_services({ cat_Filter_id: cat_Filter.id, firebase_id: userDetails?.firebase_id }))
      setupdateState(true)
    };
  }

  useEffect(() => {
    cat_Filter && getServices();
  }, [cat_Filter]);

  // --this effect only for update new data in page--
  useEffect(() => {
    if (updateState) {
      if (storeData.Set_services.status === STATUSES.IDEL) setServicesList(() => storeData.Set_services.services); setDataForFilter(() => storeData.Set_services.services)
    }
  }, [updateState, storeData.Set_services.status])


  // ---filter data by sub cat---
  const [subCatSelected, setsubCatSelected] = useState("")
  const filterServices = () => {
    subCatSelected === "" ? setServicesList(dataForFilter) : setServicesList(() => dataForFilter && dataForFilter.filter((f) => f.subcat_id === subCatSelected))
  }

  useEffect(() => {
    filterServices()
  }, [subCatSelected, storeData.Set_services.status])


  return (
    <>

      {/* ---daynamic meta tag add--- */}
      <Helmet>
        {cat_Filter?.meta_title && <title>{cat_Filter?.meta_title}</title>}
        {cat_Filter?.meta_title && <meta property="og:title" content={cat_Filter?.meta_title} />}
        {cat_Filter?.meta_keyword && <meta name="keywords" content={cat_Filter?.meta_keyword} />}
        {cat_Filter?.meta_description && <meta property="description" content={cat_Filter?.meta_description} />}
        {cat_Filter?.meta_description && <meta property="og:description" content={cat_Filter?.meta_description} />}
      </Helmet>

      {/*   ---------Page--------- */}
      <motion.div initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}>
        <main
        >
          <section className="main_content_services page_zoom_animation">
            <div className="container_fluid common_container_1720 top_banner">
              <div className="row">
                <div className=" col-12 col-sm-6 heading_div">
                  <img
                    src="../images/paw_big_image.png"
                    alt="paw"
                    className="paw_img"
                    loading="lazy"
                    decoding="async"
                  />
                  <h1 className="heading">{catName}</h1>
                </div>
                <div className="col-12 col-sm-6 img_div">
                  {
                    bannerVideo && <BannerVideo url={bannerVideo} />
                  }

                </div>
              </div>
            </div>
            {/* -------items-------- */}
            <div className="container_fluid common_container_1720 items">
              {/* headding */}
              <div className="row">
                <div className="col-12">
                  <h2 className="heading_common">
                    Category
                    <span>
                      <img src="../../images/paw_image.png" alt="paw_image" />
                    </span>
                  </h2>
                </div>
              </div>
              {/* //headding */}
              {/* sub_categouryList */}
              <div
                className="row sub_categoury_list "
                id="stickey_section_id"
              >
                <div className="col-12">
                  {/* list slider */}
                  <ServicesCategorySlider getId={setsubCatSelected} />
                  {/* list slider */}
                </div>
              </div>
              {/* //sub_categouryList */}
              {/* ------------cards--------------- */}
              <div className="row card_container">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">


                      {!servicesList ?
                        <div className="row">
                          <div className="col-12">
                            <div style={{ minHeight: "30vh", display: "grid", placeContent: "center", width: "100%" }}>
                              <Loading />
                            </div>
                          </div>
                        </div>
                        : servicesList && servicesList.length < 1 ?
                          <>
                            <div className="row">
                              <div className="col-12 text-center">
                                <div className="no_services_div">
                                  <img src="../../images/no_services.gif" alt="#" />
                                  <p>No Services Available</p></div>
                              </div>
                            </div>
                          </>
                          :
                          <div className="cards">
                            {servicesList.map((e) => (
                              <React.Fragment key={e?.id}>
                                <ServicesCard data={e} getSelectedCatId={getSelectedCatId} cat_Filter_id={cat_Filter.id} />
                              </React.Fragment>
                            ))
                            }
                          </div>
                      }

                      {/* //card */}
                    </div>

                  </div>

                </div>
              </div>
              {/* ------------//cards--------------- */}
            </div>
            {/* -------//items-------- */}
          </section>
        </main>
      </motion.div>
      {/*   ---------//Page--------- */}
    </>
  );
}

export default Services;
