import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { My_packages } from "../redux/actions/actions";
import Loading from "../Services_page/Loading";

function MyOrdersList(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [orderloading, setorderloading] = useState(false)
    // ----get data form store----
    const My_packages_list = useSelector((state) => state.get_my_packages.myPackages)
    // ---get user id form localstorage---
    const getUID = JSON.parse(localStorage.getItem("knoc-knoc-user-info"))
    // ---api function---
    const getMy_packages = async () => {
        setorderloading(true)
        await axios.post(`${process.env.REACT_APP_API_KEY}/api/get_order`, {
            "page_no": "1",
            "user_firebase_id": `${getUID ? getUID.firebase_id : ""}`
        }).then((res) => {
            setorderloading(false)
            dispatch(My_packages(res.data.data))
        }).catch((err) => {
            console.log(err)
            setorderloading(false)
        })
    }
    useEffect(() => {
        My_packages_list.length < 1 && getMy_packages()
    }, [])




    return (
        <>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 heading_item" style={{
                        borderBottom: "2px solid var(--primery-color)",
                        padding: "0.5rem",
                        fontSize: "1.6rem",
                        fontWeight: '600',
                        marginBottom: "1.2rem",
                        textAlign: "center"
                    }}> Order History</div>


                    {/* ---data-- */}

                    {orderloading ? <Loading /> : [My_packages_list.length < 1 || My_packages_list == null ? //  --if no items available then show it--
                        <>

                            <div className="no_services_div my-5">
                                <img src="../../images/no_services.gif" alt="#" />
                                <p>No Services Available</p>
                                <NavLink to="/">Book Service</NavLink>
                            </div>

                        </> :
                        <div className="col-12 col-md-10  mx-auto  my-5">
                            <div className="packages_card common_container_1920">
                                {My_packages_list.map((e) => {
                                    return (
                                        <div className="item" key={e.id}>
                                            {/* img */}
                                            <div className="img">
                                                <img src={`${process.env.REACT_APP_API_KEY}${e.ser_image}`} alt="" />
                                            </div>
                                            {/* detials */}
                                            <div className="details">

                                                <p className="heading">{e.ser_name}</p>
                                                <p className="pera_common pera_p py-1">{e.ser_description}</p>
                                                <div className="d-flex justify-content-between align-items-center pe-2">
                                                    <button className="common_button" onClick={() => navigate(`/MyOrders/orderDetials/${e.id}`)}>view</button>
                                                    <p className="pera fw-bold"> Order status : <span className="text-uppercase"> {e.order_status} </span></p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    ]
                    }


                    {/* ---//data-- */}
                </div>
            </div>
        </>
    )
}

export default MyOrdersList;