import * as React from 'react';
import { Box, Button } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useState } from 'react';
import { useEffect } from 'react';
import { Order_time_Get } from '../../redux/actions/actions';
import { useDispatch } from 'react-redux';

function Time_picker(props) {
    const dispatch = useDispatch()
    const [selectTime, setSelectTime] = useState('');
    const [timeValue, setTimeValue] = useState('');

    // --setOrderDetials in main object to service detials page--
    const setOrderDetialsStoreFun = () => {
        const { setOrderDetialsStore } = props;
        setOrderDetialsStore.time = timeValue;

    };

    useEffect(() => {
        setOrderDetialsStoreFun();
    }, [timeValue]);

    // --set order time in store--
    const setOrderTimeInStore = () => {
        dispatch(Order_time_Get(timeValue))
    }
    return (
        <div className="timeSlots">
            <Box
                sx={{
                    textAlign: 'left',
                    padding: '5px 0',
                    marginBottom: '10px',
                    background: '#00c0c947',
                    borderRadius: '10px',
                }}
            >
                <Button
                    sx={{
                        border: 'none',
                        color: 'var(--black-color)',
                        position: 'relative',
                        top: '0px',
                    }}
                    onClick={() => props.setComponentsType('date')}
                >
                    <DateRangeIcon />
                </Button>
                {/* <Button sx={{ border: "none", color: "var(--black-color)", position: "relative", top: "0px" }} onClick={() => props.setComponentsType("time")}><AccessTimeIcon /></Button> */}
            </Box>

            <div className="heading">Select Time Slot</div>
            {/* --slot box-- */}
            <div className="slots">
                {/* --items-- */}
                <div className="item">
                    <label
                        htmlFor="1"
                        className={selectTime == '1' ? 'select' : ''}
                        onClick={() => setSelectTime(1)}
                    >
                        09:00AM To 12:00PM
                    </label>
                    <input
                        type="radio"
                        name="timeSlotItem"
                        id="1"
                        value="09:00AM To 12:00PM"
                        onChange={(e) => setTimeValue(e.target.value)}
                    />
                </div>
                <div className="item ">
                    <label
                        htmlFor="2"
                        className={selectTime == '2' ? 'select' : ''}
                        onClick={() => setSelectTime(2)}
                    >
                        10:00AM To 01:00PM
                    </label>
                    <input
                        type="radio"
                        name="timeSlotItem"
                        id="2"
                        value="10:00AM To 01:00PM"
                        onChange={(e) => setTimeValue(e.target.value)}
                    />
                </div>
                <div className="item">
                    <label
                        htmlFor="3"
                        className={selectTime == '3' ? 'select' : ''}
                        onClick={() => setSelectTime(3)}
                    >
                        11:00AM To 01:00PM
                    </label>
                    <input
                        type="radio"
                        name="timeSlotItem"
                        id="3"
                        value="11:00AM To 01:00PM"
                        onChange={(e) => setTimeValue(e.target.value)}
                    />
                </div>
                <div className="item">
                    <label
                        htmlFor="4"
                        className={selectTime == '4' ? 'select' : ''}
                        onClick={() => setSelectTime(4)}
                    >
                        02:00PM To 05:00PM
                    </label>
                    <input
                        type="radio"
                        name="timeSlotItem"
                        id="4"
                        value="02:00PM To 05:00PM"
                        onChange={(e) => setTimeValue(e.target.value)}
                    />
                </div>
                {/* ---- */}
                <div className="item">
                    <label
                        htmlFor="5"
                        className={selectTime == '5' ? 'select' : ''}
                        onClick={() => setSelectTime(5)}
                    >
                        03:00PM To 06:00PM
                    </label>
                    <input
                        type="radio"
                        name="timeSlotItem"
                        id="5"
                        value="03:00PM To 06:00PM"
                        onChange={(e) => setTimeValue(e.target.value)}
                    />
                </div>
                {/* ---- */}
                <div className="item">
                    <label
                        htmlFor="6"
                        className={selectTime == '6' ? 'select' : ''}
                        onClick={() => setSelectTime(6)}
                    >
                        05:00PM To 08:00PM
                    </label>
                    <input
                        type="radio"
                        name="timeSlotItem"
                        id="6"
                        value="05:00PM To 08:00PM"
                        onChange={(e) => setTimeValue(e.target.value)}
                    />
                </div>
                {/* ---- */}
                <div className="item">
                    <label
                        htmlFor="7"
                        className={selectTime == '7' ? 'select' : ''}
                        onClick={() => setSelectTime(7)}
                    >
                        05:00PM To 09:00PM
                    </label>
                    <input
                        type="radio"
                        name="timeSlotItem"
                        id="7"
                        value="05:00PM To 09:00PM"
                        onChange={(e) => setTimeValue(e.target.value)}
                    />
                </div>

                {/* ---- */}
                <div className="item">
                    <label
                        htmlFor="8"
                        className={selectTime == '8' ? 'select' : ''}
                        onClick={() => setSelectTime(8)}
                    >
                        06:00PM To 09:00PM
                    </label>
                    <input
                        type="radio"
                        name="timeSlotItem"
                        id="8"
                        value="06:00PM To 09:00PM"
                        onChange={(e) => setTimeValue(e.target.value)}
                    />
                </div>
                {/* ---- */}
                <div className="item">
                    <label
                        htmlFor="9"
                        className={selectTime == '9' ? 'select' : ''}
                        onClick={() => setSelectTime(9)}
                    >
                        06:00PM To 10:00PM
                    </label>
                    <input
                        type="radio"
                        name="timeSlotItem"
                        id="9"
                        value="06:00PM To 10:00PM"
                        onChange={(e) => setTimeValue(e.target.value)}
                    />
                </div>



                {/* --//items-- */}
            </div>
            {/* --//slot box-- */}
            <Box sx={{ textAlign: 'right' }}>
                <Button
                    sx={{
                        border: '1px solid var(--primery-color)',
                        color: 'var(--primery-color)',
                        position: 'relative',
                        top: '1rem',
                        left: '-10px',
                    }}
                    onClick={() => {
                        props.cancle();
                        props.setComponentsType('date');
                    }}
                >
                    CANCLE
                </Button>
                <>
                    {timeValue == '' ? (
                        <Button
                            sx={{
                                border: '1px solid var(--primery-color)',
                                color: 'var(--primery-color)',
                                position: 'relative',
                                top: '1rem',
                            }}

                            disabled
                        >
                            DONE
                        </Button>
                    ) : (
                        <Button
                            sx={{
                                border: '1px solid var(--primery-color)',
                                color: 'var(--primery-color)',
                                position: 'relative',
                                top: '1rem',
                            }}
                            onClick={() => {
                                props.setComponentsType('Address')
                                setOrderTimeInStore()
                            }}
                        >
                            DONE
                        </Button>
                    )}
                </>
            </Box>
        </div>
    );
}

export default Time_picker;
