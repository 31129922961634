import React, { useState } from 'react'
import { useEffect } from 'react'
import { NavLink, Outlet, useNavigate } from 'react-router-dom/dist'
import Gallery_images from '../Gallery_component/Gallery_images'
import Gallery_videos from '../Gallery_component/Gallery_videos'
import Gallery_video_folders from '../Gallery_component/Gallery_video_folders'
import './InstagramPosts.css'
import axios from 'axios'
import Page_loading_component from '../Page_loading_component'
function InstagramPosts() {
    const navigate = useNavigate()
    // -----posts--
    const [posts, setPosts] = useState([])
    useEffect(() => {
        const getPosts = async () => {
            await axios.get(`https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink&access_token=IGQVJWdHYyenUzNzBrTWV5WmlGSzhia3F4TXk4UDZApT3lxMGJaUF9hcFBmMHFMaE1OUzkxb1ZAXTkRQVjFveTJncnhnWHhjaU5veEJ5T1hfT01ZANkdpaC1FVVYtZAURrRzhpbUlVT19zTUR4bTNNTFp6awZDZD`).then((res) => {
                setPosts(res.data.data)
            }).catch((err) => {
                console.log(err)
            })
        };
        getPosts();
    }, []);
    return (
        <div className="instagramPosts_page">
            <div className="main_content common_container_1920">
                {/* ----component---- */}
                {!posts || posts.length < 1 ? <Page_loading_component /> :
                    <div className="insta_posts">
                        <div className='images'>
                            {/* --- */}
                            {posts && posts.map((e) => {
                                return (
                                    <>
                                        {
                                            e.media_type === "IMAGE" ?
                                                <div key={e.id} className="image" >
                                                    <figure>
                                                        <img src={`${e.media_url}`} alt="Trulli" />
                                                        <figcaption>{e.caption}</figcaption>
                                                    </figure>
                                                </div>
                                                :
                                                [e.media_type === "VIDEO" ?
                                                    <>
                                                        {/* <video width="100%" style={{ aspectRatio: "16 / 14", borderRadius: "10px" }} controls>
                                                        <source src={`${e.media_url}`} type="video/*" />
                                                    </video> */}
                                                    </>
                                                    :
                                                    null
                                                ]

                                        }
                                    </>
                                )
                            })}
                            {/* --- */}
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
export default InstagramPosts
