import { Box } from '@mui/material'
import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import "./Page_loading_component.css"


function Page_loading_component() {
    return (
        <>
            <Box sx={{ width: "100%", height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} className="preloader_page">
                    {/* <img src="../../../images/knoc knoc logo preloader.gif" alt="#" style={{ width: "100px" }} /> */}
                    {/* <Typography variant="h5" sx={{ textTransform: "capitalize", color: "var(--primery-color)", marginBottom: "1rem" }} gutterBottom>
                        knocknoc
                    </Typography> */}
                    {/* <CircularProgress sx={{ color: "var(--primery-color)" }} /> */}
                    <div className="card">
                        <div className="loading">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </Box>
            </Box>
        </>
    )
}

export default Page_loading_component