import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { NavLink } from 'react-router-dom';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  borderRadius: "10px"
};


function First_Login_Popup(props) {

  return (
    <>
      <div>
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={props.handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box sx={style}>
              {/* <img src='../../images/knoc card logo.png'/> */}

              <Typography sx={{ fontSize: "2rem", fontWeight: "600", textAlign: "center", mb: 2, color: "var(--primery-color)" }}>!Opps</Typography>
              <Typography sx={{ fontSize: "1.3rem", fontWeight: "400", textAlign: "center", mb: 3 }}>Please First Login/Register</Typography>
              <Box sx={{ textAlign: "center" }}>
                <a href="/Login"
                >
                  <Button variant="outlined"
                    sx={{ color: "var(--primery-color)", borderColor: "var(--primery-color)", "&:hover": { color: "var(--primery-color)", borderColor: "var(--primery-color)" } }}>Click</Button>
                </a>

              </Box>
            </Box>
          </Fade>
        </Modal>
      </div>
    </>
  )
}

// ---default props---
First_Login_Popup.defaultProps = {
  open : false,
}
export default First_Login_Popup