import React, { useState } from 'react'
import axios from 'axios';
import { useSelector } from 'react-redux';
import generatePaymentToken from '../../payment/Genrate_token';
// ---this is address component child---
function Payment_button(props) {

    // --get price from store--
    const storeValues = useSelector((state) => state)

    const prices = storeValues.get_payment_prices

    const orderDetials = storeValues.get_OrderAllDetials.orderAllData


    const service_detials = storeValues.Set_service_details.service_detials

    const price = {
        invoice_no: 0,
        priceValue: prices.totalPrice,
        serviceName: service_detials.ser_name
    }

    // ----when payment success full run this fun----
    const payment = async () => {
        await axios.post(`${process.env.REACT_APP_API_KEY}/api/order`, {
            "ser_id": orderDetials.ser_id,
            "package_id": orderDetials.package_id,
            "user_firebase_id": orderDetials.user_firebase_id,
            "address_id": orderDetials.address_id,
            "date": orderDetials.date,
            "time": orderDetials.time,
            "coupon_id": orderDetials.coupon_id,
            "additional_service_id": orderDetials.additional_service_id,
            "wallet_amount": "0",
            "total_amount": price.priceValue,
        }).then((resData) => {
            const data = resData.data
            price.invoice_no = data.invoice.invoice_no
            setTimeout(async () => {
                if (data.status == "200") {
                    var res = await generatePaymentToken(price);
                    window.open(res, '_self');
                } else {
                    alert("something wrong")
                }
            }, 400)

        }).catch((err) => {
            console.log(err)
        })

    }

    return (

        <button className='common_button' onClick={payment}>
            Checkout
        </button>

    )
}

export default Payment_button