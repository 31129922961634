function Featured(){
    return(
        <>
            <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 text-center mx-auto mt-5">

    <img src="./images/page_working_img.webp" alt="#" className="img-fluid mt-5" style={{width:'min(700px , 100%)'}}/>

                        </div>
                    </div>
                    </div>
        </>
    )
}

export default Featured;