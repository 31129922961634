import React, { useEffect, useState } from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './Vouchers.css';
import Swal from "sweetalert2";
import axios from "axios";
function Vouchers(props) {
    const [coupenCode, setCoupenCode] = useState([])
    // ---copy code fun---
    const Toast = Swal.mixin({
        toast: true,
        position: 'center-bottom',
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const copyCode = (e) => {
        Toast.fire({
            icon: 'success',
            title: `${e}`
        })
    }

    // ---coupen list get fun---
    const coupenListFun = async () => {
        await axios.get(`${process.env.REACT_APP_API_KEY}/api/coupon_list`).then((res) => {
            const resData = res.data.data
            setCoupenCode(resData)

        }).catch((err) => console.log(err))
    }
    useEffect(() => {
        coupenListFun()
    }, [])
    return (
        <>
            <div
                className="content_items vouchers show">

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 heading_item">Vouchers</div>
                        {/* <div className="col-12">
                                                            <h5>Discount Code</h5>
                                                            <form action="#">
                                                                <input
                                                                    type="text"
                                                                    placeholder="Enter discount Code"
                                                                    required=""
                                                                />
                                                                <button type="submit" className="common_button">
                                                                    Apply code
                                                                </button>
                                                            </form>
                                                        </div> */}
                        <div className="col-12">

                            {!coupenCode ?
                                <>
                                    <div className="no_services_div text-center">
                                        <img src="../../images/no_discount.gif" alt="#" />
                                        <p>No Vouchers Available</p>
                                    </div>
                                </>
                                : <div className="Maindiv">{
                                    coupenCode.map((e) => {

                                        return (
                                            <div className="SUBMAIN">
                                                <div className="LeftDiv">
                                                    <p>DISCOUNT</p>
                                                </div>
                                                <div className="rightDiv">
                                                    <p>Applied this code and get  <span style={{ color: "var(--black-color)", fontSize: "1.2rem", fontWeight: "500" }}> {e.type == "fixed" ? `$` : `%`}{e.value} </span>OFF for every Service</p>
                                                    <p>Coupon code : <span className="coupenCode"> {e.code}</span></p>
                                                    <p>Only <span style={{ color: "var(--black-color)", fontSize: "1.2rem", fontWeight: "500" }}> {e.coupon_use}</span> Coupon left</p>
                                                    <p>This Code Expire on {e.expire}</p>
                                                    <CopyToClipboard text={e.code}>
                                                        <div className="copyBtn" onClick={() => copyCode(e.code)}>
                                                            <button ><ContentCopyIcon /></button>
                                                        </div>
                                                    </CopyToClipboard>
                                                </div>
                                            </div>
                                        )
                                    })
                                }</div>

                            }
                        </div>
                        <div className="col-12">
                            <a href="#">
                                <img
                                    src="../images/vouncher_section_invait_image.webp"
                                    alt="vouchers invait"
                                    loading="lazy"
                                    decoding="async"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Vouchers;