import * as React from 'react';
import { NavLink } from 'react-router-dom';
import LikeButton from './Like_button';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ImageErrorFallback from '../../commonFunctions/ImageErrorFallback';
import { AnimatePresence, motion } from 'framer-motion'


function Services_card({ data, cat_Filter_id }) {

  return (
    <AnimatePresence mode='wait'>
      <motion.div className="card_item" initial={{ opacity: 0 }} animate={{ opacity: 1, }} exit={{ opacity: 0 }} transition={{ duration: 0.3 }}>
        <div className="image_div">
          <NavLink to={`/${data.cat_slug}/${data.ser_slug}`}>
            <figure>
              <ImageErrorFallback
                src={data.image && `${process.env.REACT_APP_API_KEY}/${data.image}`}
                alt={data.ser_name}
                title={data.ser_name}
              />
              <figcaption>{data.ser_name}</figcaption>
            </figure>
            <span className="star">
              <i className="bi bi-star-fill" />
              4.5
            </span>
          </NavLink>
        </div>
        <div className="card_contnet">
          <div className="card_name_div">
            <p className="title">{data.ser_name}</p>
            <LikeButton val={data.id} favStatus={data.favorite_status} cat_Filter_id={cat_Filter_id} />
          </div>
          <p className="price">
            $
            {data.price}
          </p>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

export default Services_card;
