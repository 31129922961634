function Payment_methods(props) {
    return (
        <>
            <div className="content_items payment_methods show">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-7 mx-auto mt-5">

                            <img src="./images/page_working_img.webp" alt="#" className="img-fluid" />

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Payment_methods;