import React, { useEffect } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { User_address } from "../redux/actions/actions";
import Swal from "sweetalert2";


function Address(props) {
  const dispatch = useDispatch();

  // ----add and edit btn toggle----
  const [toggleBtn, setToggleBtn] = useState(true)
  // ----edit item id----
  const [editItem, setEditItem] = useState("")

  const userAddressData = useSelector((state) => state.get_user_address.addresses)

  // ----get firebase if from localstorage----
  const userDataLocal = localStorage.getItem("knoc-knoc-user-info")
  const firebaseIdUser = JSON.parse(userDataLocal)

  // -----get addresses  api call------
  const getAddress = async () => {
    const getAddress_Api = await axios.post(`${process.env.REACT_APP_API_KEY}/api/get_address`, {
      "users_id": `${firebaseIdUser.firebase_id}`
    })
    const userAddress = getAddress_Api.data.data
    dispatch(User_address(userAddress))
  }

  useEffect(() => {
    userAddressData && userAddressData.length < 1 && getAddress()
  }, [])



  // -----//get address   api call------

  // ---value state for add values in address array----
  const [values, setValues] = useState({
    users_id: `${firebaseIdUser.firebase_id}`,
    house_no: "",
    street: "",
    post_code: "",
    area_city: "",
    state: "",
  })

  // ---get value from to form and add values state-----
  const getValue = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    // let post_code = e.target.value;
    // let area_city = e.target.value;
    // let state = e.target.value;
    setValues((pre) => {
      return { ...pre, [name]: value }
    })

  }

  // ---//value state for add values in address array----

  // ----toster-----
  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 4000,
    customClass: "swalToster",// custome class and style in index.js
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  // -----add new address----
  const addNewBtn = () => {
    // ---add new btn show in popup--
    setToggleBtn(true)
    // ---remove values from inputs after add address--
    setValues((pre) => ({ ...pre, house_no: "", street: "", post_code: "", area_city: "", state: "" }))

  }
  const addNewAddress = async () => {
    if (values.users_id == "" || values.house_no == "" || values.street == "" || values.post_code == "" || values.area_city == "" || values.state == "") {
      Toast.fire({
        background: "#ff4e49",
        color: 'white',
        icon: 'error',
        iconColor: "white",
        title: 'Pls fill all fields'
      })
    } else {
      await axios.post(`${process.env.REACT_APP_API_KEY}/api/user_address`, {
        "users_id": `${values.users_id}`,
        "house_no": `${values.house_no}`,
        "street": `${values.street}`,
        "post_code": `${values.post_code}`,
        "area_city": `${values.area_city}`,
        "state": `${values.state}`
      }).then(() => {
        // --alert data send--
        // dispatch(Show_alerts({
        //   alert_message: "Address is Add",
        //   alert_type: "success"
        // }))


        Toast.fire({
          background: "var(--primery-color)",
          color: 'white',
          icon: 'success',
          iconColor: "white",
          title: 'Address is Added'
        })


        // --call address function for auto update addresses--
        getAddress();


      }).catch(() => {
        Toast.fire({
          background: "#ff4e49",
          color: 'white',
          icon: 'error',
          iconColor: "white",
          title: 'Somthing wrong'
        })
      })
      setToggleBtn(true)
    }

  }
  // -----//add new address----

  // -----Edit  address function----

  const editAddressID = (e) => {

    // --get id form clicked address and add in state--
    setEditItem(e.id)
    // --add value on input for update old values--
    values.house_no = e.house_no
    values.street = e.street
    values.post_code = e.post_code
    values.area_city = e.area_city
    values.state = e.state
    // --show edit btn on popup--
    setToggleBtn(false)

  }

  const editAddress = async () => {

    if (values.users_id == "" || values.house_no == "" || values.street == "" || values.post_code == "" || values.area_city == "" || values.state == "") {
      Toast.fire({
        background: "#ff4e49",
        color: 'white',
        icon: 'error',
        iconColor: "white",
        title: 'Pls fill all fields'
      })
    } else {
      // --add clicked address value in state--

      // --call api fun--
      await axios.post(`${process.env.REACT_APP_API_KEY}/api/update_address`, {
        "id": `${editItem}`,
        "users_id": `${values.users_id}`,
        "name": "demo",
        "house_no": `${values.house_no}`,
        "street": `${values.street}`,
        "post_code": `${values.post_code}`,
        "area_city": `${values.area_city}`,
        "state": `${values.state}`
      }).then(() => {


        Toast.fire({
          background: "var(--primery-color)",
          color: 'white',
          icon: 'success',
          iconColor: "white",
          title: 'Your address has been updated',
        })

        // ---call addresses fun for auto update---
        getAddress()
      }).catch(() => {
        Toast.fire({
          icon: 'error',
          title: 'Somthing wrong'
        })
      })
    }
  }
  // -----//Edit  address function----

  // -----delete Address-----
  const deleteAddress = async () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "var(--primery-color)",
      cancelButtonColor: "var(--secondary-color)",
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios.post(`${process.env.REACT_APP_API_KEY}/api/delete_address`, {
          "id": `${editItem}`
        }).then(() => {
          // ---call addresses fun for auto update---
          getAddress()
        }).catch(() => {
          Toast.fire({
            icon: 'error',
            title: 'Somthing wrong'
          })
        })
        // ---
        Swal.fire({
          title: 'success',
          text: "Your Address has been deleted",
          icon: 'success',
          confirmButtonColor: "var(--primery-color)",
          confirmButtonText: 'ok'
        })
      }
    })

  }
  // -----//delete Address-----



  return (
    <>

      {/* ----------modal for add address------- */}
      <div className="modal fade" id="addressAdd" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Add New Address</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { width: '100%' },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField type="text" id="filled-basic" label="house_no" variant="filled" sx={{ mb: 3 }} value={values.house_no} name="house_no" onChange={getValue} />
                <TextField type="text" id="filled-basic" label="street" variant="filled" sx={{ mb: 3 }} value={values.street} name="street" onChange={getValue} />
                <TextField type="number" id="filled-basic" label="post_code" variant="filled" sx={{ mb: 3 }} value={values.post_code} name="post_code" onChange={getValue} />
                <TextField type="text" id="filled-basic" label="area_city" variant="filled" sx={{ mb: 3 }} value={values.area_city} name="area_city" onChange={getValue} />
                <TextField type="text" id="filled-basic" label="state" variant="filled" sx={{ mb: 3 }} value={values.state} name="state" onChange={getValue} />

              </Box>
            </div>
            <div className="modal-footer">
              {!toggleBtn ? <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={deleteAddress}>Delete</button> : ""}
              {toggleBtn ? <button type="button" className="btn common_button" data-bs-dismiss="modal" aria-label="Close" onClick={addNewAddress}>Add Address</button> : <button type="button" className="btn common_button" data-bs-dismiss="modal" aria-label="Close" onClick={editAddress}>Edit adddress</button>}
            </div>
          </div>
        </div>
      </div>

      {/* ------------//modal for add address---------------- */}
      <div
        className="content_items address show" >

        <div className="container-fluid address_container">
          <div className="row">
            <div className="col-12 heading_item">Your Addresses</div>
            <div className="col-12 add_new_address">
              <a data-bs-toggle="modal" data-bs-target="#addressAdd" onClick={addNewBtn}>Add New Address</a>
            </div>
            <div className="col-12 cards">
              <div className="row mx-2">
                {/* ------ */}
                {!userDataLocal ? "pls login" : !userAddressData ?

                  //  --if no items available then show it--
                  <>
                    <div className="no_services_div">
                      <img src="../../images/no_address.gif" alt="#" />
                      <p>No Address Available</p>
                    </div>
                  </>

                  : userAddressData.map((e) => {
                    return (
                      <div className="col-12 card_add" key={e.id}>
                        <div className="title">
                          <h5>Home</h5>
                          <a data-bs-toggle="modal" data-bs-target="#addressAdd" onClick={() => editAddressID(e)}>
                            <i className="bi bi-pencil-fill" /> Edit
                          </a>
                        </div>
                        <div className="pera">
                          {e.house_no},
                          {e.street},
                          {e.area_city},
                          {e.state}-
                          {e.post_code}
                        </div>

                      </div>
                    )
                  })}
                {/* --------- */}
              </div>
            </div>
            {/* <div className="col-12 add_new_address">
              <a data-bs-toggle="modal" data-bs-target="#addressAdd" onClick={addNewBtn}>Add New Address</a>
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default Address;