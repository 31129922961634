import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Additional_service_List, Additional_service_price } from '../../redux/actions/actions';

function Additional_services(props) {
    const dispatch = useDispatch()
    // -----get values form store---
    const additional_services = useSelector((state) => state.get_additional_services.Additional_services);

    const [priceqty, setPriceqty] = useState(0)
    // --packagesSelect array--
    const [selectedServices, setSelectedServices] = useState([])
    // -- array for get price--
    const [selectPrice, setselectPrice] = useState(0);

    // const serivceSelect = (e) => {
    //     let idValue = e.target.id
    //     let selectedPriceGet = Number(e.target.value)
    //     // ---
    //     if (e.target.checked == true) {
    //         setSelectedServices([...selectedServices, idValue])
    //         setselectPrice(selectPrice + selectedPriceGet)

    //     } else {
    //         setSelectedServices(() => selectedServices.filter((e) => e != idValue))
    //         setselectPrice(selectPrice - selectedPriceGet);
    //     }

    //     // ---dispatch for service id's list save in store---


    // };

    const increment = (e) => {

        // --set price---
        setselectPrice(selectPrice + Number(e.price))
        // --ser service list--
        const checkServiceList = selectedServices.find((f) => f.id == e.id)
        if (checkServiceList) {
            const newArray = selectedServices.filter((g) => g.id != checkServiceList.id)
            setSelectedServices([...newArray, { 'id': `${e.id}`, 'quantity': checkServiceList.quantity + 1 }])
        } else {
            setSelectedServices([...selectedServices, { 'id': `${e.id}`, 'quantity': 1 }])
        }
    }
    const decrement = (e) => {


        // --ser service list--
        const checkServiceList = selectedServices.find((f) => f.id == e.id)
        if (checkServiceList) {
            if (checkServiceList.quantity >= 1) {
                const newArray = selectedServices.filter((g) => g.id != checkServiceList.id)
                setSelectedServices([...newArray, { 'id': `${e.id}`, 'quantity': checkServiceList.quantity - 1 }])
                // --set price---
                setselectPrice(selectPrice - Number(e.price))
            }
            else {
                const newArray = selectedServices.filter((g) => g.id != checkServiceList.id)
                setSelectedServices([...newArray])
            }
        }



    }


    useEffect(() => {
        //  ---sand additional price in store---
        // console.log(selectedServices, selectPrice, "dispatch - selectPrice selectPrice selectPrice selectPrice")

        // const convert = JSON.stringify(selectedServices)
        dispatch(Additional_service_List(selectedServices))
        dispatch(Additional_service_price(selectPrice))

    }, [selectPrice])

    return (
        <>
            {!additional_services ? (
                <>

                </>
            ) : (<div className="plan">



                <>
                    <div className="plan_title">
                        <h5>Add-on</h5>
                        <span>Optional</span>
                    </div>

                    {
                        additional_services.map((e, index) => {

                            return (
                                <label
                                    className="form-check-label d-block"
                                    htmlFor="flexCheckDefault3"
                                    key={e.id}
                                >
                                    <div className="selcet_options selected" >
                                        <div className="option">
                                            <label>
                                                {e.additional_ser_name}
                                            </label>


                                            <div className="option_price">
                                                $
                                                {e.price}

                                            </div>
                                        </div>
                                        <div className="plusMinus_button">
                                            <div className="buttonBox">
                                                <button onClick={() => decrement({ "id": e.id, "price": e.price })}>-</button>

                                                <input type="text" maxLength="2" value={selectedServices.find((v) => v.id == e.id) ? selectedServices.find((v) => v.id == e.id).quantity : "0"} />

                                                <button onClick={() => increment({ "id": e.id, "price": e.price })}>+</button>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            );
                        })
                    }
                </>
            </div>
            )
            }
        </>
    );
}

export default Additional_services;
