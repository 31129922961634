
import React from 'react';
import { Routes, Route } from 'react-router-dom';
// -------import component-------------
import Navabr from './component/Navbar';
// import Home from './component/Pages/Home';
import Featured from './component/Pages/Featured';
import Activity from './component/Pages/Activity';
import Profile from './component/Pages/Profile';
import MyOrders from './component/Pages/MyOrders';
import WalletCompo from './component/Profile_component/Wallet_compo';

import MyOrdersDetials from './component/My_orders/MyOrders_detials';
import MyOrdersList from './component/My_orders/MyOrdersList';
import Address from './component/Profile_component/Address';
import Payment_methods from './component/Profile_component/Payment_methods';
import Login from './component/Pages/Login';
import Register from './component/Pages/Register';
import Services from './component/Services_page/Services';
import ServiceDetails from './component/Services_page/Service_details';
import Alerts from './component/Alert';
import FirstLoginPopup from './component/First_Login_Popup';
import SearchPage from './component/Pages/Search_page';
import PageLoadingComponent from './component/Page_loading_component';
import ErrorPage from './Error_page';
import Footer from "./component/footer/Footer"
import PaymentSuccessPage from './component/staticPages/Payment_success_page';
import PaymentFailedPage from './component/staticPages/Payment_fail_page';
import MyFavourites from './component/Profile_component/MyFavourites';
import RatingSection from './component/Profile_component/Rating';
import Vouchers from './component/Profile_component/Vouchers';
import Referrals from './component/Profile_component/Referrals';
import CatProvider from './component/Profile_component/Cat_provider';
import GetHelp from './component/Profile_component/Get_help';
import About from './component/Profile_component/About';
import TermsConditions from './component/Profile_component/Terms_Conditions';
import Gallery from './component/Pages/Gallery';
import GalleryVideos from './component/Gallery_component/Gallery_videos';
import GalleryImages from './component/Gallery_component/Gallery_images';
import GalleryVideoFolders from './component/Gallery_component/Gallery_video_folders';
import { ChatBotFun } from './Features/ChatBot/ChatBot';
import PrivateRoute from './Routes/PrivateRoute';
import InstagramPosts from './component/Pages/InstagramPosts';
// --//components--

// ----for page animate----
import { AnimatePresence } from 'framer-motion'
import { useLocation } from 'react-router-dom'


// -----home lazy load-----
const LazyHome = React.lazy(() => import("./component/Pages/Home"))


function App() {
  const location = useLocation()
  return (
    <>
      {/* -----alerts----- */}
      <Alerts />
      {/* -----alerts----- */}
      {/* ----first login popup---  */}
      <FirstLoginPopup />
      {/* ------navabr------ */}
      <Navabr />
      {/*  ------//navabr------ */}
      <AnimatePresence mode='wait' initial={false}>
        <Routes location={location} key={location.pathname}>
          {/* ------Home------ */}
          <Route path="/" element={<React.Suspense fallback={<PageLoadingComponent />}><LazyHome /></React.Suspense>} />
          {/* ------//Home------ */}
          {/* ------Featured------ */}
          <Route exact path="/Featured" element={<PrivateRoute ><Featured /></PrivateRoute>} />
          {/* ------//Featured------ */}
          {/* ------Activity------ */}
          <Route element={<PrivateRoute />}>
            <Route exact path="/Activity" element={<Activity />} />
          </Route>
          {/* ------//Activity------ */}
          {/* ------Gallery----- */}
          <Route exact path="/Gallery" element={<Gallery />} >
            <Route exact path="/Gallery" element={<GalleryImages />} />
            <Route exact path="/Gallery/videos" element={<GalleryVideoFolders />} />
            <Route exact path="/Gallery/videos/:CatId" element={<GalleryVideos />} />
          </Route>

          {/* -----InstagramPosts---- */}
          <Route exact path="/InstagramPosts" element={<InstagramPosts />} />
          {/* ------Profile------ */}
          <Route exact path="/Profile" element={<Profile />} >
            <Route element={<PrivateRoute />}>
              <Route exact path="/Profile/addresses" element={<Address />} />
              <Route exact path="/Profile/wallet" element={<WalletCompo />} />
              <Route exact path="/Profile/my_favourites" element={<MyFavourites />} />
              <Route exact path="/Profile/my_ratings" element={<RatingSection />} />
              <Route exact path="/Profile/vouchers" element={<Vouchers />} />
              <Route exact path="/Profile/referrals" element={<Referrals />} />
              <Route exact path="/Profile/cat_provider" element={<CatProvider />} />
            </Route>
            <Route exact path="/Profile/get_help" element={<GetHelp />} />
            <Route exact path="/Profile/about" element={<About />} />
            <Route exact path="/Profile/terms_cnditions" element={<TermsConditions />} />
          </Route>
          {/* ------//Profile------ */}
          {/* ------NewBooking------ */}
          <Route element={<PrivateRoute />}>
            <Route exact path='/MyOrders' element={<MyOrders />} />
            <Route exact path='/MyOrders/MyOrders_list' element={<MyOrdersList />} />
            <Route exact path='/MyOrders/orderDetials/:orderId' element={<MyOrdersDetials />} />
            <Route exact path="/payment-success" element={<PaymentSuccessPage />} />
          </Route>
          {/* ------//NewBooking------ */}
          <Route exact path="/Login" element={<Login />} />
          <Route exact path="/Register" element={<Register />} />
          {/* -----------routs for profile pages---------- */}
          {/* <Route exact path='/Addresses' element={<Address/>}/>
      <Route exact path='/Payment_Methods' element={<Payment_methods/>}/> */}
          {/* -----------Services pages---------- */}
          <Route exact path="/:ServiceId" element={<Services />} />
          <Route exact path="/:ServiceId/:ServiceIdDetails" element={<ServiceDetails />} />
          {/* <Route exact path='/Services/:ServiceId/Service_details/:ServiceIdDetails/:subCatFilter' element={<Service_details />} /> */}
          {/* -----favourite package show detials----- */}
          <Route exact path="/Profile/:ServiceId/:ServiceIdDetails" element={<ServiceDetails />} />
          {/* ------Search------ */}
          <Route exact path="/search/:searchValue" element={<SearchPage />} />
          {/* ------//search------ */}
          {/* ------payment success / faild page----- */}
          <Route exact path="/Payment-failed" element={<PaymentFailedPage />} />

          {/* ------//payment success / faild page----- */}
          <Route exact path="*" element={<ErrorPage />} />
        </Routes>
      </AnimatePresence>

      {/* ----footer---- */}
      <Footer />
      {/* ----//footer---- */}
      {/* -----chat bot box----- */}
      <ChatBotFun />
      {/* -----//chat bot box----- */}
    </>
  );
}

export default App;
