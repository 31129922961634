import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';

function Rating_number({ serviceId }) {
    // ----service reating calcuating----
    const [serviceRating, setServiceRating] = useState({
        one: 0,
        two: 0,
        three: 0,
        four: 0,
        five: 0,
    })
    // ----//service reating calcuating----

    // ---api call for get service rating number---
    const getRatingNumberFun = async () => {
        await axios.post(`${process.env.REACT_APP_API_KEY}/api/total_rating`, {
            "sr_id": `${serviceId}`
        }).then((res) => {
            const apiData = res.data.data

            setServiceRating((pre) => apiData ? apiData : pre)
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        getRatingNumberFun()
    }, [])

    let totalVotes = serviceRating.one + serviceRating.two + serviceRating.three + serviceRating.four + serviceRating.five;
    let average = (serviceRating.one + 2 * serviceRating.two + 3 * serviceRating.three + 4 * serviceRating.four + 5 * serviceRating.five) / totalVotes;
    let finalReating = isNaN(average) ? "5.0" : average.toFixed(1)

    return (
        <p className="rating">
            <span className="star">
                <i className="bi bi-star-fill" />
            </span>
            <span>
                {!serviceRating ? " " : `${finalReating}(${totalVotes})`}</span>
            <span className="link">
                {/* <a href="#">Reviews &gt;</a> */}
            </span>
        </p>
    )
}

export default Rating_number