import { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import Update_profile from "./Update_profile";
import ImageErrorFallback from "../../commonFunctions/ImageErrorFallback";

function Profile_sidebar(props) {
  const navigate = useNavigate();

  // ---update prfile popup state--
  const [upadteProfileOpen, setUpadteProfileOpen] = useState(false);
  const handleClose = () => setUpadteProfileOpen(false);
  const profile = JSON.parse(localStorage.getItem("knoc-knoc-user-info"));
  const profileImage = profile ? profile.image : "";
  // ---get user data from store---
  const userData = useSelector((state) => state.set_userData.userDetials);

  // ----logout function--------
  const logout = () => {
    localStorage.removeItem("knoc-knoc-user-info");
    // ----navigate to home page and reload----
    navigate("/");
    window.location.reload();
  };

  return (
    <>
      {/* sidebar */}
      <div className={props.sideBarC}>
        {/*  */}
        <div className="log_out_button">
          <a onClick={logout}>
            <i className="bi bi-box-arrow-right" /> Logout
          </a>
        </div>
        {/*  */}
        <div className="profile_image">
          <div className="img">
            <ImageErrorFallback
              src={profileImage ? `${process.env.REACT_APP_API_KEY}/${profileImage}` : "https://www.w3schools.com/howto/img_avatar.png"}
              alt="#"
              loading="lazy"
              decoding="async"
            />
            <div className="edit_image">
              <label>
                <span onClick={() => setUpadteProfileOpen(true)}>
                  <i className="bi bi-pencil-fill" />
                </span>
              </label>
              {/* <input type="file" id="select_img" hidden /> */}
            </div>
          </div>
          <div className="name mt-2">
            <h4 className="text-capitalize">
              {" "}
              {!userData ? "User Name" : userData.firstname}
            </h4>
            {/* <span onClick={() => setUpadteProfileOpen(true)}>
              <i className="bi bi-pencil-fill" />
            </span> */}
            {/* ---modal for update profile--- */}
            <Update_profile
              upadteProfileOpen={upadteProfileOpen}
              handleClose={handleClose}
            />
            {/* ---//modal for update profile--- */}
          </div>
          <div className="rating">
            (
            <span>
              <i className="bi bi-star-fill" />
            </span>
            4.5)
          </div>
        </div>
        {/*  */}
        <div className="my_account_div">
          <h5 className="heading">My Account</h5>
          <div className="items">
            <ul>
              <li
                className="account_li"
                id="package"
                onClick={() => {
                  props.fun(1);
                  props.sideBarOpen();

                }}
              >
                <NavLink to="/Profile/addresses">
                  Addresses
                </NavLink>
              </li>
              {/* <li className={props.indexC === 2 ? "account_li active" : "account_li"} id="package" onClick={() => {
                props.fun(2)
                props.sideBarOpen()
              }}>
                Linked Payment Methods
              </li> */}
              <li
                className="account_li"
                id="wallet"
                onClick={() => {
                  props.fun(2);
                  props.sideBarOpen();

                }}
              >

                <NavLink to="/Profile/wallet">
                  Wallet
                </NavLink>
              </li>
              <li
                className="account_li"
                id="favourites"
                onClick={() => {
                  props.fun(4);
                  props.sideBarOpen();

                }}
              >
                <NavLink to="/Profile/my_favourites">

                  My Favourites
                </NavLink>
              </li>
              <li
                className="account_li"
                id="ratings"
                onClick={() => {
                  props.fun(5);
                  props.sideBarOpen();

                }}
              >
                <NavLink to="/Profile/my_ratings">

                  My Ratings
                </NavLink>
              </li>
              <li
                className="account_li"
                id="vouchers"
                onClick={() => {
                  props.fun(6);
                  props.sideBarOpen();

                }}
              >
                <NavLink to="/Profile/vouchers">

                  Vouchers
                </NavLink>
              </li>
              <li
                className="account_li"
                id="referrals"
                onClick={() => {
                  props.fun(7);
                  props.sideBarOpen();

                }}
              >
                <NavLink to="/Profile/referrals">

                  Referrals
                </NavLink>
              </li>
              <li
                className="account_li"
                id="cat_provider"
                onClick={() => {
                  props.fun(8);
                  props.sideBarOpen();

                }}
              >
                <NavLink to="/Profile/cat_provider">

                  Become a cat provider
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        {/*  */}
        <div className="support_div">
          <h5 className="heading">Support</h5>
          <div className="items">
            <ul>
              <li
                id="get_Help"
                className="account_li"
                onClick={() => {
                  props.fun(9);
                  props.sideBarOpen();

                }}
              >
                <NavLink to="/Profile/get_help">

                  Get Help
                </NavLink>
              </li>
              {/* <li
                id="Feedback"
                className={props.indexC === 10 ? "account_li " : "account_li"}
                onClick={() => {
                  props.fun(10);
                  props.sideBarOpen();
                }}
              >
                Feedback
              </li> */}
              <li
                id="About_KNOC"
                className="account_li"
                onClick={() => {
                  props.fun(11);
                  props.sideBarOpen();

                }}
              >
                <NavLink to="/Profile/about">

                  About KNOC KNOC
                </NavLink>
              </li>
              <li
                id="Conditions"
                className="account_li"
                onClick={() => {
                  props.fun(12);
                  props.sideBarOpen();

                }}
              >
                <NavLink to="/Profile/terms_cnditions">
                  Terms &amp; Conditions / Privacy
                </NavLink>

              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* //sidebar */}
    </>
  );
}

export default Profile_sidebar;
