import React, { useEffect, useState } from 'react';
import useFavorite from '../../commonFunctions/useFavorite ';



function Like_button(props) {

    const [favStatus, setFavStatus] = useState(0)
    // --user details get form localstore[friebase id]--
    const [user_id, setuser_id] = useState('');
    // ---get custome hook from useFavorite---
    const { add_fav, remove_fav } = useFavorite();
    useEffect(() => {
        setFavStatus(props.favStatus)
        // ---set user firebase id---
        const userDetails = JSON.parse(localStorage.getItem('knoc-knoc-user-info'));
        setuser_id(!userDetails ? '' : userDetails.firebase_id);
    }, []);


    return (
        <>
            {!user_id ? (
                ''
            ) : (
                favStatus === 0 ? <button
                    id={props.val}
                    className='like_button'
                    onClick={() => { add_fav(props.val, setFavStatus, props.cat_Filter_id); }}
                >
                    <i className="bi bi-heart-fill" />
                </button> :
                    <button
                        id={props.val}
                        className='like_button text-dark'
                        onClick={() => remove_fav(props.val, setFavStatus, props.cat_Filter_id)}
                    >
                        <i className="bi bi-heart-fill" />
                    </button>
            )}
        </>
    );
}
export default Like_button;
