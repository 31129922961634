import React, { useState } from 'react'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import './Gallery.css'

function Gallery() {
    const location = useLocation()
    console.log(location, "location")

    // -----catagory bar active state--
    const [activeClass, setactiveClass] = useState("PROMOTIONS")
    const ActiveClassFun = (e) => {
        setactiveClass(e.target.id)
    }

    return (

        <div className="Gallery_page">
            <section className="stickey_section " id="stickey_section_id">
                <section className="upper_tab_section ">

                    <div className="upper_tab_div activity_page">
                        <div className="bar">
                            <ul>
                                {/* <li className="bg_label thired" /> */}
                                <NavLink to="/Gallery">
                                    <li className={location.pathname === "/Gallery" ? "tab_item active" : "tab_item"} id='PROMOTIONS' onClick={(e) => {
                                        ActiveClassFun(e)
                                    }}>Images</li>
                                </NavLink>
                                <NavLink to="/Gallery/videos">
                                    <li className={location.pathname !== "/Gallery" ? "tab_item active" : "tab_item"} id='CAMPAIGNS' onClick={(e) => {
                                        ActiveClassFun(e)
                                    }}>Videos</li>
                                </NavLink>
                            </ul>
                        </div>
                    </div>

                </section>
                {/* //order time filter */}
            </section>
            <div className="main_content common_container_1920">
                {/* ----component---- */}
                <div className="gallery_content">
                    {/* {activeClass == "PROMOTIONS" ? <Gallery_images /> : <Gallery_video_folders />} */}
                    <Outlet />
                </div>

            </div>
        </div>
    )
}

export default Gallery