import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Modal, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Swal from 'sweetalert2'
import "./Login_register.css";
// -----firebase----
import { app, messaging, provider } from "../../firebase/firebase";
import {
  getAuth,
  linkWithCredential,
  linkWithPhoneNumber,
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithPopup,
} from "firebase/auth";
// ---phone number--
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useDispatch } from "react-redux";
import { Show_alerts } from "../redux/actions/actions";
import { getToken } from "firebase/messaging";
import { async } from "@firebase/util";
import useLogin from "../../commonFunctions/useLogin";

// ---main function-----
function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // -----modal-------
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // ---fcm token---
  const [fcmToken, setFcmToken] = useState("")

  // ----loading button---
  const [loading, setLoading] = useState(false);

  // -----phone number value------
  const [phoneError, setPhoneError] = useState("");

  // ------------------------------------Api Values-------------------------------//
  const [phoneNumberValue, setphoneNumber] = useState("");
  const getPhoneNum = (e) => {
    setphoneNumber(e);
    setPhoneError("");

    if (phoneNumberValue != "") {
      setLoading(true);
    } else {
      setLoading(false);
    }
  };

  // ----otp input values----
  const [verifyOtp, setVerifyOtp] = useState("");
  const getOtpValues = (e) => {
    setVerifyOtp(e.target.value);
  };



  // ---fcm token custom hook---
  const { generateFcmToken, signInWithPopupFun, ismobile, setismobile } = useLogin();
  // console.log(ismobile, "ghajab ka hai din")
  useEffect(() => {
    // ---when user come to register fcm token generate---
    generateFcmToken()
  }, [])

  // ---get fcmtoken from local session---
  const fcmTokenGet = sessionStorage.getItem("myFcmToken")
  const auth = getAuth(app);

  // ----login form with api(5)----
  const apiDetials = {
    firebase_id: "",
    fcm_token: "null",
    phonenumber: "",
  };

  // -------Update Profile--------//
  const FcmToken = sessionStorage.getItem("myFcmToken")
  const fullname = auth.currentUser && auth.currentUser.displayName?.split(" ")

  const updateUserInfo = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/updateprofile`, {
        id: "",
        firebase_id: `${auth.currentUser.uid}`,
        fcm_token: `${FcmToken}`,
        firstname: `${fullname[0]}`,
        lastname: `${fullname[1]}`,
        email: `${auth.currentUser.email}`,
        phonenumber: `${phoneNumberValue}`,
        gender: `${"male" || ""}`,
        image: `${auth.currentUser.photoURL}`,
      }
      )
      .then((res) => {
        // --alert data send---

        dispatch(
          Show_alerts({
            alert_message: "Login Successfull",
            alert_type: "success",
          }),

        )
        setTimeout(() => {
          navigate("/")
          window.location.reload()
        }, 2000)

        //--modal close when profile update--
        // props.handleClose();
        // --update data in localstorage and show in page--
        localStorage.setItem("knoc-knoc-user-info", JSON.stringify({
          id: "",
          firebase_id: `${auth.currentUser.uid}`,
          fcm_token: `${FcmToken}`,
          firstname: `${fullname[0]}`,
          lastname: `${fullname[1]}`,
          email: `${auth.currentUser.email}`,
          phonenumber: `${phoneNumberValue}`,
          gender: `${"male" || ""}`,
          image: `${auth.currentUser.photoURL}`,
        }));
        // --reload winodw--
        // window.location.reload();

      })
      .catch((err) => console.log(err));
  };
  const loginApi = async () => {

    const apiData = await axios.post(
      `${process.env.REACT_APP_API_KEY}/api/login`,
      {
        firebase_id: apiDetials.firebase_id,
        fcm_token: fcmTokenGet ? fcmTokenGet : "null",
        phonenumber: apiDetials.phonenumber,
      }
    );
    const userData_final = apiData.data.data;
    console.log(apiData)
    // ----set data in local storage----
    localStorage.setItem("knoc-knoc-user-info", JSON.stringify(userData_final));

    // --alert--
    Swal.fire({
      title: 'Login Successfull',
      icon: 'success',
      showConfirmButton: false,
      timer: 2000
    }
    ).then(() => {
      // --navigate to home page and reload--
      navigate("/");
      window.location.reload();
    }).catch(() => {
      // --alert data send---
      dispatch(Show_alerts({
        alert_message: "Somthing went wrong!",
        alert_type: "error"
      }))
    })
  };
  //  ----//login form with api----

  // ----phone number chack in data base(4)----
  const phoneNumberChack = async () => {
    const res = await axios.post(
      `${process.env.REACT_APP_API_KEY}/api/checkphonenumber`,
      {
        phonenumber: phoneNumberValue,
      }
    );
    console.log(res)
    if (res.data.status == "400") {
      // ---loading button inable---
      setLoading(true);
      // --alert data send---
      dispatch(
        Show_alerts({
          alert_message: "Phone Number Is Not Exist",
          alert_type: "error",
        })
      );

    }
    else if (res.data.status == "204") {
      // --alert data send---
      dispatch(
        Show_alerts({
          alert_message: "your account has been deleted",
          alert_type: "info",
        })
      );
    }
    else if (res.data.status == "200") {
      // ---loading button inable---
      setLoading(true);

      // -----sign in functions-------
      setPhoneError("");
      // ---login button disable---
      setLoading(false);

      // --upper function call here--
      onCaptchVerify();

      // ----
      const phoneNumber = phoneNumberValue;
      console.warn(phoneNumber);
      const appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        .then((confirmationResult) => {
          console.log(confirmationResult, ">>>>>")
          // --if number is vrify from fire then sand a otp to number and otp modal open--
          setOpen(true);
          // ...
          window.confirmationResult = confirmationResult;
          // ...
        })
        .catch((error) => {
          console.log(error);
          // --alert data send---
          dispatch(
            Show_alerts({
              alert_message: `${error.message}`,
              alert_type: "error",
            })
          );
        });


    }
  };
  // ----//phone number chack in data base----

  // ----phone number chack in data base while linking(4)----
  const phoneCheckForLogin = async (e) => {
    e.preventDefault()
    console.log("check")
    const res = await axios.post(
      `${process.env.REACT_APP_API_KEY}/api/checkphonenumber`,
      {
        phonenumber: phoneNumberValue,
      }
    );
    if (res.data.status == "200") {
      // ---loading button inable---
      // setLoading(true);
      dispatch(
        Show_alerts({
          alert_message: "Phone Number Is Already Exist",
          alert_type: "error",
        })
      );
    } else if (res.data.status == "400") {
      mobileLink()

    }
  };
  // -------------------------firebase functions-----------------------


  // ----recaptcha verify(2)----
  const onCaptchVerify = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          //-- when recaptcha is verify then run signin funcion--
          if (ismobile) {
            mobileLink()
          } else {
            onSignInFunction();
          }

        },
        "expired-callback": () => { },
      },
      auth
    );
  };
  // ----//recaptcha verify----

  // ----sign in function(1)----
  const regex = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  const singapurRegex = /^(\+65|65|0)\d{8}$/;
  const onSignInFunction = (e) => {
    e.preventDefault();
    if (regex.test(phoneNumberValue) || singapurRegex.test(phoneNumberValue)) {

      // ---phone num check---
      phoneNumberChack();

    } else {
      setPhoneError("Please provide a valid phone number");
    }
  };
  // ----//sign in function----

  // ----verify otp function(3)----
  const verifyCode = () => {
    // --pass here otp input code and chack otp `verifyOtp`|--
    window.confirmationResult
      .confirm(verifyOtp)
      .then((result) => {
        // User signed in successfully.
        const user = result.user;
        // -- show alert if user in login--

        // --hide otp popup--
        setOpen(false);
        //  ---set user values in apiDetials state for call api user api---

        // --user values in apiDetials then call api phone number chack function--
        if (ismobile) {
          updateUserInfo()
        } else {
          apiDetials.firebase_id = user.uid;
          apiDetials.phonenumber = user.phoneNumber;
          // --login api call--
          loginApi()
        }

        // ...
      })
      .catch((error) => {
        // ---if otp is false show alert--
        // --alert data send--
        dispatch(
          Show_alerts({
            alert_message: "Otp is Not Matched",
            alert_type: "error",
          })
        );
      });
  };
  // ---- //verify otp function ----
  // -----------------login function for Popup--------//
  const mobileLink = () => {
    if (regex.test(phoneNumberValue) || singapurRegex.test(phoneNumberValue)) {
      setPhoneError("");
      // ---login button disable---
      setLoading(false);

      // e.preventDefault();
      // phoneCheckForLogin()
      // --upper function call here--
      onCaptchVerify();

      // ----
      const phoneNumber = phoneNumberValue;
      console.warn(phoneNumber);
      const appVerifier = window.recaptchaVerifier;
      linkWithPhoneNumber(auth.currentUser, phoneNumber, appVerifier)
        .then((confirmationResult) => {
          // --if number is vrify from fire then sand a otp to number and otp modal open--
          // alert("Hi Swaggy")
          setOpen(true);

          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          console.log(confirmationResult, ">><<<<<")
          // ...

        })
        .catch((error) => {
          // --alert data send---
          dispatch(
            Show_alerts({
              alert_message: `${error.message}`,
              alert_type: "error",
            })
          );
        });
    } else {
      // e.preventDefault();
      setPhoneError("Please provide a valid phone number");
    }
  }

  // --------------------------------------//firebase functions-----------------------

  return (
    <>
      <>
        {/* ---modal--- */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              height: "100vh",
              width: "100vw",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
            }}
          >
            {/* ----otp verify--- */}
            <div className="otp_popup">
              {/* <Button className="closeBtn" onClick={handleClose}>
                <i className="bi bi-x"></i>
              </Button> */}
              <div className="otp_banner">
                <img src="./images/Otp_banner.webp" alt="#" />
              </div>
              <div className="otpInputs">
                <h3 className="heading">
                  {" "}
                  <i className="bi bi-file-earmark-lock2"></i> Authentication
                  Code
                </h3>
                <input
                  type="number"
                  className="form-control shadow-none"
                  placeholder="ENTER YOUR OTP..."
                  value={verifyOtp}
                  onChange={getOtpValues}
                />
                <button
                  className="btn submit_btn shadow-none"
                  onClick={verifyCode}
                >
                  otp verify
                </button>
              </div>
            </div>
          </Box>
        </Modal>
        {/* ---//modal--- */}

        {/*------------------LinkMobileNumber--------------------------------------------}
 {/* ---modal--- */}
        <Modal open={ismobile}  >
          <Box
            sx={{
              height: "100vh",
              width: "100vw",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
            }}
          >
            {/* ----otp verify--- */}
            <div className="otp_popup">
              <button onClick={() => setismobile(false)} style={{ position: 'absolute', top: "5%", right: "3%", fontSize: "1.5rem", border: "none", background: "transparent" }}><i class="bi bi-x-circle"></i></button>
              <div className="linkInputs">
                <h3 className="heading">

                  Enter Mobile Number
                </h3>
                <br />
                <PhoneInput
                  placeholder="Enter phone number"
                  value={phoneNumberValue}
                  countryCallingCodeEditable={false}
                  defaultCountry="SG"
                  className="inputPhone"
                  onChange={getPhoneNum}
                  required
                  minLength="4"
                />
                <button
                  className="btn submit_btn shadow-none"
                  onClick={phoneCheckForLogin}
                >
                  Send Otp
                </button>
              </div>
            </div>
          </Box>
        </Modal>

        {/*------------------LinkMobileNumber--------------------------------------------}



        {/* forms section */}
        <section className="forms_section">
          {/* ----recaptcha contianer---- */}
          <div id="recaptcha-container"></div>
          {/* ----//recaptcha contianer---- */}

          {/* login form container */}
          <div className="container form_container login_form_container">
            {/* ---login_form--- */}
            <div className="row form_row login_form_main_row">
              <div className="col-12 text-end pt-2 pe-2">
                <NavLink to="/">
                  <i className="bi bi-x-circle fs-4" />
                </NavLink>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-12  form_col">
                    {/*login form */}

                    <div className="login_form form_div">
                      <form
                        onSubmit={
                          onSignInFunction
                        }
                        className="needs-validation"
                        noValidate
                      >
                        <div>
                          <p className="heading_common">Login</p>
                        </div>

                        <div className="mb-3">
                          {/* <input
                      type="tel"
                      className="form-control"
                      value={phoneNumberValue}
                      onChange ={getPhoneNum}
                      placeholder="Enter Your Number"
                      aria-describedby="emailHelp"
                      required
                    /> */}

                          <PhoneInput
                            placeholder="Enter phone number"
                            value={phoneNumberValue}
                            countryCallingCodeEditable={false}
                            defaultCountry="SG"
                            className="inputPhone"
                            onChange={getPhoneNum}
                            required
                            minLength="4"
                          />
                          <div className="valid-text text-danger text-start py-2 text-capitalise">
                            {phoneError}
                          </div>
                        </div>
                        {!loading ? (
                          <button className="btn submit_button" disabled>
                            Login
                          </button>
                        ) : (
                          <button type="submit" className="btn submit_button">
                            Login
                          </button>
                        )}
                      </form>
                    </div>

                    {/*//login form */}
                    {/* other method */}
                    <div className="othere_method">
                      <div>
                        <p className="pera_common">Login with</p>
                      </div>
                      <div className="icons">
                        <ul>

                          <li
                            title="Login with GOOGLE"
                            onClick={() => {
                              signInWithPopupFun();

                            }}
                          >
                            <a>
                              <i className="bi bi-google" />
                            </a>
                          </li>

                        </ul>
                      </div>
                    </div>
                    {/* //other method */}
                  </div>
                  {/* already account */}
                  <div className="switch_button">
                    Not have an account?
                    <NavLink
                      to="/Register"
                      className="login_form_switch_button "
                    >
                      Create an account
                    </NavLink>
                  </div>
                  {/* //already account */}
                  {/*  */}
                  {/*             <div className="col-12 col-sm-4 image_col">
        <div className="image_div">
          <img src="img/mobile-login-image.webp" />
        </div>
      </div> */}
                </div>
              </div>
            </div>
            {/* ---//login_form--- */}
          </div>
          {/* //login form container */}
        </section>
        {/* //forms section */}
      </>
    </>
  );
}

export default Login;
