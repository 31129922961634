import ChatIcon from '@mui/icons-material/Chat';
import chatIcon_img from '../../images/chatBot_icon.png'
import './ChatBot.css'
export const ChatBotFun = () => {

    return (
        <div className="df-messenger-box">
            <df-messenger
                intent="WELCOME"
                chat-icon={chatIcon_img}
                chat-title="KnocKnoc"
                agent-id="ffcf32cb-f840-465e-bb8b-18e4c0a7b6ad"
                language-code="en"
            ></df-messenger>
        </div>
    )
}