import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import Like_button from '../Services_page/Like_button';
import Loading from '../Services_page/Loading';

// ----------serach function---------------
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.25),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.45),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));
// ----------//serach function---------------


function Search_page(props) {
    // ---get value from url---
    const urlValue = useParams()
    // --searchvalue--
    const [serachValue, setSearchValue] = useState()
    // ---navigate---
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)


    // ----search data-----
    const [searchData, setSearchData] = useState()
    // ---api call for serach data---
    const serachValuesFun = async () => {

        // ---
        await axios.post(`${process.env.REACT_APP_API_KEY}/api/subcategory2`, {
            "search": `${serachValue ? serachValue : urlValue.searchValue}`,
            "cat_id": "",
            "subcat_id": "",
            "user_firebase_id": ""
        }).then((res) => {
            console.log(res.data)
            setSearchData(res.data.data)
        }).catch((err) => {
            console.log(err)
        })
        setLoading(false)
    }

    useEffect(() => {
        setLoading(true)
        const delayDebounceFn = setTimeout(() => {
            serachValuesFun();
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
    }, [serachValue])

    return (
        <div className='common_container_1720 mt-2 mt-md-4 mt-lg-5'>
            <div className='container-fluid'>
                <div className="row">
                    <div className="col-12">


                        <Box sx={{ flexGrow: 1 }}>
                            <AppBar position="static" sx={{ background: "var(--background)", padding: "0", boxShadow: "none", borderRadius: "10px" }}>
                                <Toolbar>
                                    <IconButton
                                        size="small"
                                        edge="start"
                                        color="inherit"
                                        aria-label="open drawer"
                                        sx={{ mr: 2 }}
                                        onClick={() => navigate(-1)}
                                    >
                                        <ArrowBackIcon />
                                    </IconButton>
                                    <Typography
                                        variant="h6"
                                        noWrap
                                        component="div"
                                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                                    >
                                        Search Result
                                    </Typography>
                                    <Search>
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                            placeholder="Search…"
                                            inputProps={{ 'aria-label': 'search' }}
                                            onChange={(e) => setSearchValue(e.target.value)}
                                        />
                                    </Search>
                                </Toolbar>
                            </AppBar>
                        </Box>
                    </div>
                </div>
                {/* ---- */}
                <div className="row">
                    <div className="col-12 card_container px-2">
                        {/* ---card--- */}
                        {loading ? <Loading /> : !searchData ?
                            <>
                                <div className="no_services_div">
                                    <img src="../../images/no_services.gif" alt="#" />
                                    <p>No Services Available</p>
                                </div>
                            </>
                            : <div className="cards"> {searchData.map((e, index) => {

                                return (
                                    <>
                                        <div class="card_item" key={index}>
                                            <div class="image_div">
                                                {/* <a class="" href="/Services/10/Service_details/47"> */}
                                                <NavLink to={`/${e.cat_slug}/${e.ser_slug}`}>
                                                    <figure>
                                                        <img src={`${process.env.REACT_APP_API_KEY}/${e.image}`} alt={e.ser_name}
                                                            title={e.ser_name} loading="lazy" decoding="async" />
                                                    </figure>
                                                </NavLink>
                                                <span class="star">
                                                    <i class="bi bi-star-fill"></i>
                                                    <p>4.5</p>
                                                </span>
                                                {/* </a> */}
                                            </div>
                                            <div class="card_contnet">
                                                <div class="card_name_div">
                                                    <p className="title">{e.ser_name}</p>
                                                    <Like_button val={e.id} favStatus={e.favorite_status} getServicesFun={serachValuesFun} />
                                                </div>
                                                <p class="price">${e.price}</p>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                            </div>}
                        {/* ---//card--- */}


                    </div>
                </div>
            </div>
        </div>
    )
}

export default Search_page