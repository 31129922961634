import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { Get_user_data } from "./redux/actions/actions";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import First_Login_Popup from "./First_Login_Popup";
//custom hook
import useLogout from "../commonFunctions/useLogout";

function Navabr() {

  // ------state for mobie menu open/close----
  const [Menu, setMenu] = useState('responsive_part')
  const [navItem, setNavItem] = useState("home")
  const dispatch = useDispatch()
  const navigate = useNavigate()

  //logout
  const { logOut_Function } = useLogout()

  const mobileMenu = () => {
    if (Menu == 'responsive_part') {
      setMenu('responsive_part show')
    } else {
      setMenu('responsive_part')
    }
  }

  // ----get user data from localstorage----
  const user_info = JSON.parse(localStorage.getItem("knoc-knoc-user-info"))

  useEffect(() => {
    // Get_user_data
    dispatch(Get_user_data(user_info))
  }, [])

  // ---get user data from store---
  const userData = useSelector((state) => state.set_userData.userDetials)



  // ----logout function--------
  const logout = () => {
    logOut_Function()
  }


  // ----first login popup function----
  //  ---modal states--
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);



  return (
    <>
      {!userData ? <First_Login_Popup open={open} handleOpen={handleOpen} handleClose={handleClose} /> : ""}

      <header>
        <div className="site_header">
          {/* site_logo */}
          <div className="site_logo">
            <NavLink to="/">
              <img src="../../../images/knoc knoc logo.png" alt="site_logo" />
            </NavLink>
          </div>
          {/* == */}
          <div className={Menu}>
            {/*    nav_div    */}
            <div className="nav_div">
              <nav>
                <ul>
                  <li>
                    <NavLink to="/" className="home_page" onClick={() => {
                      setNavItem("home")
                      mobileMenu()
                    }}>
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/Gallery" className="gellery_page" onClick={() => {
                      setNavItem("Gallery")
                      mobileMenu()
                    }}>
                      Gallery
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/InstagramPosts" className="feature_page" onClick={() => {
                      setNavItem("InstagramPosts")
                      mobileMenu()
                    }}>
                      Instagram
                    </NavLink>
                  </li>
                  <li>

                    <NavLink to="/Activity" className="activity_page" onClick={() => {
                      setNavItem("activity")
                      mobileMenu()
                    }}>
                      Activity
                    </NavLink>

                  </li>
                  <li>

                    <NavLink to="/Profile/addresses" className="profile_page" onClick={() => {
                      setNavItem("profile")
                      mobileMenu()
                    }}>
                      Profile
                    </NavLink>

                  </li>
                  <li>
                    <NavLink to="/MyOrders/MyOrders_list" className="newBoking" onClick={() => {
                      setNavItem("newBoking")
                      mobileMenu()
                    }}>My Orders</NavLink>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          {/* options */}
          <div className="cart_option">
            {/* login/register link */}
            <div className="login_link pe-2">
              {!localStorage.getItem("knoc-knoc-user-info") ? <>
                <NavLink to="/Login" >
                  Login/register
                </NavLink>
              </> : <>
                <Stack direction="row">
                  <Avatar sx={{ background: "#04989f", border: "1px solid #04989f" }}>{!userData ? "U" : userData.firstname?.charAt(0)}</Avatar>
                  <div className="dropdown">
                    <button className="btn  dropdown-toggle text-light text-capitalize shadow-none" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">

                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li onClick={logout}><a className="dropdown-item text-dark">Logout</a></li>
                    </ul>
                  </div>
                </Stack></>}

            </div>
            {/* //login/register link */}
          </div>

          {/* == */}
          {/* ---navbar_mobile_icon---- */}
          <div className="navbar_mobile_icon">
            <button onClick={mobileMenu}>
              <i className="bi bi-filter-circle" />
            </button>
          </div>
        </div>
      </header>
    </>
  );
}

export default Navabr;
