import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Divider, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Show_alerts } from "../redux/actions/actions";
import { CleaningServices, PhotoCamera } from "@mui/icons-material";
import Avatar from "react-avatar-edit";
import img from "./../../images/selectImage.png";
import { useEffect } from "react";
import Skeleton from '@mui/material/Skeleton';
import loader_image from '../../images/loader_image.gif'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "min(600px , 98%)",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

function Update_profile(props) {
  const dispatch = useDispatch();

  const [file, setFile] = React.useState(img);
  const [btnDisable, setBtnDisable] = React.useState(true);
  const [imgLoading, setImgLoading] = React.useState(false)
  const [firsterror, setfirsterror] = React.useState("");
  const [lasterror, setlasterror] = React.useState("");
  const [gendererror, setgendererror] = React.useState("");

  // ----state for store inputs values----
  const [values, setValues] = React.useState({
    profileImage: "",
    firstName: "",
    lastName: "",
    gender: "",
  });


  // ---user state---
  const [firebase_id, setFirebase_id] = React.useState("");
  const [mobileNumber, setMobileNumber] = React.useState("");
  const [emailId, setEmailId] = React.useState("");
  const [fcm_token, setFcm_token] = React.useState("");

  // ---avtar state---
  const [avtarImg, setAvtarImg] = React.useState(null);
  // const [image, setImage] = React.useState("")

  const getValue = (e) => {
    let val = e.target.value;
    let name = e.target.name;
    if (values.firstName != "") {
      setfirsterror("")
    }
    if (values.lastName != "") {
      setlasterror("")
    }
    if (values.gender != "") {
      setgendererror("")
    }

    setValues((pre) => {
      return { ...pre, [name]: val };
    });
    // --btn enable--
    setBtnDisable(false);
  };
  const ImageHandler = (e) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file);
    // ----set image in  file for preview---
    setFile(URL.createObjectURL(e.target.files[0]));
  };
  const onLoad = (fileString, file) => {
    if (file == "image/webp") {
      var arr = fileString
        .split("data:image/webp;base64,")
        .map((item) => item.trim());
    } else {
      var arr = fileString
        .split("data:image/png;base64,")
        .map((item) => item.trim());
    }
    const base64 = arr[1];
    updateImage(base64);
    // dispatch(UpdateImage(base64));
  };
  const updateImage = async (base64) => {
    // --loading true--

    setImgLoading(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/base64`,
        {
          file: `${base64}`,
          extension: ".png",
        }
      )
      .then((res) => {

        const resData = res.data;
        setValues({ ...values, ...{ profileImage: `${resData}` } });
        // --loading false--
        setImgLoading(false)
        // --btn enable--
        setBtnDisable(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result, file.type);
    };
  };
  // ---api call for update user infomation---
  // -api data state-
  const apiValues = {
    id: "",
    firebase_id: `${firebase_id}`,
    fcm_token: `${fcm_token}`,
    firstname: `${values.firstName}`,
    lastname: `${values.lastName}`,
    email: `${emailId}`,
    phonenumber: `${mobileNumber}`,
    gender: `${values.gender}`,
    image: `${values.profileImage}`,
  };

  const updateUserInfo = async () => {
    if (values.firstName === "" || values.lastName === "" || values.gender === "null") {
      console.log("alert")
      if (values.firstName === "") {
        setfirsterror("First name is required")
      }

      if (values.lastName === "") {
        setlasterror("last name is required")
      }
      if (values.gender === "null") {
        setgendererror("Please select the gender")
      }
    }

    else {
      console.log("update")
      await axios
        .post(
          `${process.env.REACT_APP_API_KEY}/api/updateprofile`,
          apiValues
        )
        .then((res) => {
          // --alert data send---
          dispatch(
            Show_alerts({
              alert_message: "Profile Update Successfull",
              alert_type: "success",
            })
          );
          //--modal close when profile update--
          props.handleClose();
          // --update data in localstorage and show in page--
          localStorage.setItem("knoc-knoc-user-info", JSON.stringify(apiValues));
          // --reload winodw--
          window.location.reload(false);

        })
        .catch((err) => console.log(err));
    }


  };

  // ---get user detials form store---
  const userDetails = useSelector((state) => state.set_userData.userDetials);
  // ----user details add before update value----
  // console.log(userDetails, ">>><<<<<<")
  useEffect(() => {
    if (!userDetails) {
      console.log("wait");
    } else {
      setFirebase_id(userDetails.firebase_id);
      setMobileNumber(userDetails.MobileNumber || userDetails.phonenumber);
      setEmailId(userDetails.email);
      setFcm_token(userDetails.fcm_token);

      // --
      setValues({
        ...values,
        ...{
          profileImage: userDetails.image,
          firstName: userDetails.firstname,
          lastName: userDetails.lastname,
          gender: userDetails.gender,
        },
      });
    }
  }, [userDetails]);

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={props.upadteProfileOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <FormControl sx={{ width: "100%" }}>
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "1.6rem",
                marginBottom: "10px",
              }}
            >
              Update User Details
            </Typography>
            <Divider />

            {/* ----avtar---- */}
            {imgLoading ?
              <>
                <Box sx={{ width: "50%", aspectRatio: "16/16", borderRadius: "10px", marginTop: "3px", background: "rgba(0,0,0,0.1)", display: "flex", alignItems: "center", justifyContent: "center" }} >
                  <img src={loader_image} alt="#" style={{ maxWidth: "200px" }} />
                </Box>
                {/* <Skeleton variant="rectangular" sx={{ width: "50%", aspectRatio: "16/16", height: "auto", borderRadius: "10px", marginTop: "3px" }} >
                </Skeleton> */}
              </>
              :

              <label htmlFor="profile">
                <Box
                  sx={{
                    width: "50%",
                    margin: "1rem 0",
                  }}
                >
                  <img
                    src={values.profileImage ? `${process.env.REACT_APP_API_KEY}/${values.profileImage}` : file ? file : "../select_image.png"}
                    alt="Profile Image"
                    style={{
                      width: "100%",
                      aspectRatio: "1 / 1",
                      borderRadius: "10px",
                      border: "1px solid lightgray",
                    }}

                  />
                </Box>
                <input
                  type="file"
                  id="profile"
                  onChange={ImageHandler}
                  accept="image/png"
                  hidden
                />
              </label>

            }

            {/* ----//avtar---- */}

            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "1.2rem",
                margin: "10px 0",
                color: "#222",
              }}
            >
              User Name
            </Typography>

            <Stack
              spacing={2}
              direction={{ xs: "column", md: "row" }}
              sx={{ marginBottom: "1rem", width: "100%" }}
            >
              <Box>

                <TextField
                  id="outlined-basic"
                  name="firstName"
                  label="Firstname"
                  variant="outlined"
                  value={values.firstName}
                  onChange={getValue}
                  sx={{ width: "100%" }}
                />
                <span style={{ color: "red", fontSize: "14px" }}>{firsterror}</span>
              </Box>
              <Box>

                <TextField
                  id="outlined-basic"
                  name="lastName"
                  label="Lastname"
                  variant="outlined"
                  value={values.lastName}
                  onChange={getValue}
                  sx={{ width: "100%" }}
                />
                <span style={{ color: "red", fontSize: "14px" }}>{lasterror}</span>
              </Box>
            </Stack>
            <Typography
              sx={{ fontWeight: "500", fontSize: "1.2rem", color: "#222" }}
            >
              Gender
            </Typography>

            <Stack>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="female"
                  control={
                    <Radio
                      checked={values.gender == "female" && true}
                      sx={{
                        color: "var(--primery-color)",
                        "&.Mui-checked": { color: "var(--primery-color)" },
                      }}
                      name="gender"
                      onChange={getValue}
                    />
                  }
                  label="Female"
                />
                <FormControlLabel
                  value="male"
                  control={
                    <Radio
                      checked={values.gender == "male" && true}
                      sx={{
                        color: "var(--primery-color)",
                        "&.Mui-checked": { color: "var(--primery-color)" },
                      }}
                      name="gender"
                      onChange={getValue}
                    />
                  }
                  label="Male"
                />
                <FormControlLabel
                  value="other"
                  control={
                    <Radio
                      checked={values.gender == "other" && true}
                      sx={{
                        color: "var(--primery-color)",
                        "&.Mui-checked": { color: "var(--primery-color)" },
                      }}
                      name="gender"
                      onChange={getValue}
                    />
                  }
                  label="Other"
                />

              </RadioGroup>

            </Stack>
            <span style={{ color: "red", fontSize: "14px" }}>{gendererror}</span>
            {/* --button-- */}
            <Stack spacing={1} direction="row" margin={"1rem 0 0 0"}>
              <Button
                variant="outlined"
                sx={{
                  borderColor: "var(--primery-color)",
                  color: "var(--primery-color)",
                  boxShadow: "none",
                  "&:hover": { borderColor: "var(--primery-color)" },
                }}
                onClick={props.handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "var(--primery-color)",
                  boxShadow: "none",
                  "&:hover": { backgroundColor: "var(--primery-color)" },
                }}
                onClick={updateUserInfo}
                disabled={btnDisable}
              >
                Update
              </Button>
            </Stack>
          </FormControl>
        </Box>
      </Modal>
    </div>
  );
}

export default Update_profile;
