
// -----defaine all action types here------

export const Action_types = {
    // --
    BANNER_IMAGES_REQ: "BANNER_IMAGES_REQ",
    BANNER_IMAGES_SUCCESS: "BANNER_IMAGES_SUCCESS",
    BANNER_IMAGES_FAILED: "BANNER_IMAGES_FAILED",
    // --
    SHOW_CATEGORIES_REQ: "SHOW_CATEGORIES_REQ",
    SHOW_CATEGORIES_SUCCESS: "SHOW_CATEGORIES_SUCCESS",
    SHOW_CATEGORIES_FAIL: "SHOW_CATEGORIES_FAIL",

    // --
    SHOW_GALLERY_IMAGES_REQ: "SHOW_GALLERY_IMAGES_REQ",
    SHOW_GALLERY_IMAGES_SUCCESS: "SHOW_GALLERY_IMAGES_SUCCESS",
    SHOW_GALLERY_IMAGES_FAIL: "SHOW_GALLERY_IMAGES_FAIL",
    // --
    SHOW_SUB_CATEGORIES: "SHOW_SUB_CATEGORIES",
    // --
    SHOW_SERVICES_REQ: "SHOW_SERVICES_REQ",
    SHOW_SERVICES_SUCCESS: "SHOW_SERVICES_SUCCESS",
    SHOW_SERVICES_FAIL: "SHOW_SERVICES_FAIL",

    REMOVE_SHOW_SERVICES: "REMOVE_SHOW_SERVICES",
    GET_SERVICE_DETAILS: "GET_SERVICE_DETAILS",
    REMOVE_SERVICE_DETAILS: "REMOVE_SERVICE_DETAILS",
    SHOW_ACTIVITY: "SHOW_ACTIVITY",
    GET_FAVORITE: "GET_FAVORITE",
    USER_DATA: "USER_DATA",
    ALERT_TYPES: "ALERT_TYPES",
    USER_ADDRESS: "USER_ADDRESS",
    PACKAGES: "PACKAGES",
    SERVICE_REATING: "SERVICE_REATING",
    SERVICE_REVIWS: "SERVICE_REVIWS",
    SHOW_MY_PACKAGES: "SHOW_MY_PACKAGES",
    ADDITIONAL_SERVICES: "ADDITIONAL_SERVICES",
    // -----order detials get-----
    OREDER_ALL_DETAILS: "OREDER_ALL_DETAILS",
    ORDER_SER_ID: "ORDER_SER_ID",
    ADDITIONAL_SERVICE_LIST: "ADDITIONAL_SERVICE_LIST",
    PACKAGE_ID: "PACKAGE_ID",
    ORDER_DATE: "ORDER_DATE",
    ORDER_TIME: "ORDER_TIME",
    ORDER_ADDRESS_ID: "ORDER_ADDRESS_ID",
    COUPEN_ID: "COUPEN_ID",
    // -----payment detials get-----
    SERVICE_MAIN_PRICE: "SERVICE_MAIN_PRICE",
    PACKAGES_PRICE: "PACKAGES_PRICE",
    ADDITIONAL_SERVICES_PRICE: "ADDITIONAL_SERVICES_PRICE",
    DISCOUNT_PRICE: "DISCOUNT_PRICE",
    TOTAL_PRICE: "TOTAL_PRICE",
    WALLET_BALANCE: "WALLET_BALANCE",
    WALLET_BALANCE_ADD: "WALLET_BALANCE_ADD",
    HOME_PAGE_REVIEWS: "HOME_PAGE_REVIEWS",
    // ---get categories in videos file---
    GET_CATEGORIES_VIDEOS: "GET_CATEGORIES_VIDEOS"
}