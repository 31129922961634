import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Wallet_balance } from '../redux/actions/actions';

function Wallet_compo(props) {
    const dispatch = useDispatch()
    // --get userId from localstorage--
    const [userId, setUserId] = useState("")
    const userDetials = JSON.parse(localStorage.getItem("knoc-knoc-user-info"));
    const [walletBal, setWalletBal] = useState("0")
    // ---get wallet bannce api call---
    const walletBalance = async () => {
        await axios.post(`${process.env.REACT_APP_API_KEY}/api/wallet_amount_get`, {
            "user_firebase_id": `${userId}`
        }).then((res) => {
            setWalletBal(res.data.amount)
            dispatch(Wallet_balance(res.data.amount))
        }).catch((err) => {
            console.log(err)
        })
    }

    const setIdFun = () => {

        setUserId(userDetials ? userDetials.firebase_id : "")
    }
    useEffect(() => {
        walletBalance()
        setIdFun()
    }, [walletBal])
    return (
        <div
            className="content_items referrals show">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 heading_item">Wallet</div>
                    <div className="col-12">
                        <div className="image_div">
                            <img
                                src="../images/referrals section image.png"
                                alt="referrals image"
                                loading="lazy"
                                decoding="async"
                            />
                        </div>
                        <div className="content">
                            <div className="price">Balance ${walletBal}</div>
                            {/* <div className="pera">
                                <h5>For each friend you invite to KNOC KNOC</h5>
                                <p>
                                    Your friend get $5.00 off their first service when
                                    they use your link to sign up and you will receive
                                    a $5.00 off voucher
                                </p>
                            </div> */}
                        </div>
                        {/* <div className="buttons">
                            <a href="#">Share Link</a>
                            <a href="#">Scan QR code</a>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Wallet_compo