import axios from 'axios';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function useLogout() {
    // ----toster-----
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 4000,
        customClass: "swalToster",// custome class and style in index.js
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const navigate = useNavigate()
    const user_info = JSON.parse(localStorage.getItem("knoc-knoc-user-info"))
    const logOut_Function = async () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to logout!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "var(--primery-color)",
            cancelButtonColor: "var(--secondary-color)",
            confirmButtonText: 'Yes'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await axios.post(`${process.env.REACT_APP_API_KEY}/api/logout`, {
                    "firebase_id": `${user_info.firebase_id}`
                }).then((res) => {
                    console.log(res, 'logout')
                    localStorage.removeItem("knoc-knoc-user-info");
                }).catch(() => {
                    Toast.fire({
                        icon: 'error',
                        title: 'Somthing wrong'
                    })
                })
                // ---
                Swal.fire({
                    title: 'Logout successfully',
                    icon: 'success',
                    confirmButtonColor: "var(--primery-color)",
                    confirmButtonText: 'ok'
                }).then(() => {
                    // --navigate to home page and reload--
                    navigate("/");
                    window.location.reload();
                })
            }
        })

    }
    return { logOut_Function }
}

export default useLogout