// ---import css----
import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import './Services.css';
import ServiceBookPopup from './Service_book/Service_book_popup';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Additional_service, Get_services_detials, Order_all_details, Order_ser_id_get, Remove_services_detials, Service_main_price, Service_packages, Show_alerts, } from '../redux/actions/actions';
import ServiceBookBox from './Service_book_box';
import RatingSection from './Service_book/Rating_section';
import RatingNumber from './Rating_number';

// --swiper--
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import "swiper/css";
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Autoplay } from 'swiper';
import { Helmet } from 'react-helmet';



function Service_details() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //  ---modal states--
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // --user details get form localstore[friebase id]--
  const [user_id, setuser_id] = useState('');

  // ----get id from url----
  const GetServiceId = useParams();
  const serviceId = GetServiceId.ServiceIdDetails;



  // -----api call for get srvice details-----
  const getServices_detials = async () => {
    try {
      const servicesDetialsData = await axios.post(`${process.env.REACT_APP_API_KEY}/api/get_service_by_id`, {
        "ser_slug": `${serviceId}`
      });
      const servicesDetialsData_final = servicesDetialsData.data.data;
      //  ----filter packages according to main catagory-
      const servicesDetials_Filter = Object.assign({}, ...servicesDetialsData_final);

      dispatch(Get_services_detials(servicesDetials_Filter));

      // ---set price in main price in store---
      dispatch(Service_main_price(servicesDetials_Filter.price))

    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    getServices_detials();
    return () => {
      dispatch(Remove_services_detials())
    }
  }, []);



  //  ----get service data from store----
  const services_data = useSelector((state) => state.Set_service_details);
  const service = services_data && services_data.service_detials



  // ----package api call function for show packages----
  const packagesApi = async () => {
    await axios.post(`${process.env.REACT_APP_API_KEY}/api/package`, {
      cat_id: '',
      sr_id: `${service && service?.id}`,
    },
    )
      .then((res) => {
        dispatch(Service_packages(res.data.data));
      })
      .catch((err) => { });
  };

  // ---- api call function for additional services----
  const additionalServicesApi = async () => {

    await axios.post(`${process.env.REACT_APP_API_KEY}/api/additional_service_get`, {
      sr_id: `${service && service?.id}`,
    },
    )
      .then((res) => {
        dispatch(Additional_service(res.data.data));
      })
      .catch((err) => { });
  };

  // ----//package api call function for show packages----
  const userDetails = JSON.parse(localStorage.getItem('knoc-knoc-user-info'));
  useEffect(() => {
    // ---set user firebase id---
    setuser_id(!userDetails ? '' : userDetails.firebase_id);
    // --service id set for get payment time--
    dispatch(Order_ser_id_get(service?.id))
    // ---
    packagesApi();
    // ---
    additionalServicesApi();
  }, [service]);

  // -----------Api call for order confirem----------
  const orderConfirm = async () => {
    // -call api-
    await axios.post(`${process.env.REACT_APP_API_KEY}/api/order`,
      orderDetialsStore,
    )
      .then((res) => {
        // --alert data send---
        dispatch(Show_alerts({
          alert_message: 'Service Book Successfull',
          alert_type: 'success',
        }));
      })
      .catch((err) => { });
  };
  // -----------//Api call for order confirem----------


  // ================order detials get and store then patch api===============
  const orderDetialsGet = useSelector((state) => state.get_orderDetails)
  const orderDetialsStore = {
    ser_id: `${service && service.id}`,
    package_id: `${orderDetialsGet.package_id}`,
    user_firebase_id: `${user_id}`,
    address_id: `${orderDetialsGet.address_id}`,
    additional_service_id: orderDetialsGet.additional_service_id,
    coupon_id: `${orderDetialsGet.coupon_id}`,
    date: `${orderDetialsGet.date}`,
    time: `${orderDetialsGet.time}`,
  };
  //  --set add details in store--
  useEffect(() => {
    dispatch(Order_all_details(orderDetialsStore))
  }, [orderDetialsGet])

  // ================//order detials get and store then patch api===============

  // ---scroll to top fun----
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      {/* ---daynamic meta tag add--- */}
      <Helmet>
        {service?.meta_title && <title>{service?.meta_title}</title>}
        {service?.meta_title && <meta property="og:title" content={service?.meta_title} />}
        {service?.meta_keyword && <meta name="keywords" content={service?.meta_keyword} />}
        {service?.meta_description && <meta property="description" content={service?.meta_description} />}
        {service?.meta_description && <meta property="og:description" content={service?.meta_description} />}
      </Helmet>

      {/* ---modal--- */}
      <ServiceBookPopup
        handleOpen={handleOpen}
        handleClose={handleClose}
        open={open}
        setOrderDetialsStore={orderDetialsStore}
        orderConfirm={orderConfirm}
      />
      {/*   ---------Page--------- */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >

        <main>
          <section className="main_content book_page page_zoom_animation">
            <div className="container-fluid top_bar_div">
              {/* --topbar */}
              <div className="row px-3">
                <div className="col-12 topbar">
                  <span onClick={() => navigate(-1)}>
                    <i className="bi bi-arrow-left" />&nbsp;Back
                  </span>
                </div>
              </div>
              {/* --//topbar */}
            </div>
            <div className="container-fluid common_container_1720 book_detials_container">
              <div className="row">
                {/* --details */}
                <div className="col-12 col-md-7 col-lg-7 details">
                  <div className="image_box">


                    <Swiper
                      style={{ '--swiper-navigation-color': '#fff', '--swiper-pagination-color': '#fff', }}
                      autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true
                      }}
                      lazy={true}
                      pagination={{
                        clickable: true,
                      }}
                      modules={[Pagination, Autoplay]}
                      className="mySwiper"
                    >
                      {service?.image?.map((e, ind) => (
                        <SwiperSlide key={`serviceDetiaslImg${ind}`}>
                          <img src={`${process.env.REACT_APP_API_KEY}${e}`} loading="lazy" alt='imgSlider' />
                        </SwiperSlide>
                      ))}

                      {service.video &&
                        <SwiperSlide >
                          <video loop={true} autoPlay="autoplay" muted webkit-playsinline="true" playsInline={true}>
                            <source src={`${process.env.REACT_APP_API_KEY}/${service.video}`} type="video/mp4" />
                            Your browser does not support the video.
                          </video>
                        </SwiperSlide>
                      }

                    </Swiper>
                  </div>
                  {/* price_box */}
                  <div className="price_box">
                    <div className="title_div">
                      <h2 className="title_name">{service.ser_name}</h2>
                      <RatingNumber serviceId={service.id} />
                    </div>
                    <div className="price">
                      $
                      {service.price}
                    </div>
                  </div>
                  {/* Description */}
                  <div className="description_box">
                    <p className="heading">Description</p>
                    <p className="pera">{service.description}</p>
                  </div>
                </div>
                {/* --//details */}
                {/* sidebar */}
                <ServiceBookBox
                  handleOpen={handleOpen}
                  handleClose={handleClose}
                  open={open}
                />
                {/* //sidebar */}
              </div>

              {/* ---reating section--- */}
              <div className="col-12 col-md-7 col-lg-7 details">
                <RatingSection />
              </div>
              {/* ---//reating section--- */}
            </div>
          </section>
        </main>
      </motion.div>
      {/*   ---------//Page--------- */}
    </>
  );
}
export default Service_details;
