import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { Show_services } from '../component/redux/actions/actions';

function useFavorite() {
    const dispatch = useDispatch()

    // --user details get form localstore[friebase id]--
    const [user_id, setuser_id] = useState('');

    // ----show toster----
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    //  ----service add in fav----
    const add_fav = async (e, setFavStatus, cat_Filter_id_get) => {

        // --add item fun call--
        await axios
            .post(
                `${process.env.REACT_APP_API_KEY}/api/favorite`,
                {
                    user_firebase_id: `${user_id}`,
                    ser_id: `${e}`,
                    status: 1,
                },
            )
            .then(() => {
                setFavStatus(1)
                dispatch(Show_services({ cat_Filter_id: cat_Filter_id_get, firebase_id: user_id }))
                Toast.fire({
                    icon: 'success',
                    title: 'Add in favorite list'
                })
            })
            .catch((err) => {
                console.log(err);
            });
    };

    //  ----service remove in fav----
    const remove_fav = async (e, setFavStatus, cat_Filter_id_get) => {

        // --add item fun call--
        await axios
            .post(
                `${process.env.REACT_APP_API_KEY}/api/favorite`,
                {
                    user_firebase_id: `${user_id}`,
                    ser_id: `${e}`,
                    status: 0,
                },
            )
            .then(() => {
                setFavStatus(0)
                dispatch(Show_services({ cat_Filter_id: cat_Filter_id_get, firebase_id: user_id }))
                Toast.fire({
                    icon: 'success',
                    title: 'Remove From Favorite List'
                })
            })
            .catch((err) => {
                console.log(err);
            });
    };


    useEffect(() => {
        // ---set user firebase id---
        const userDetails = JSON.parse(localStorage.getItem('knoc-knoc-user-info'));
        setuser_id(!userDetails ? '' : userDetails.firebase_id);
    }, []);


    return { add_fav, remove_fav }
}

export default useFavorite