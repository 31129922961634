import axios from 'axios';
import jwt from "jwt-simple";


// const generatePaymentToken = async () => {
//     alert("payment")
//     try {


//         const response = await axios.post('https://pgw.2c2p.com/payment/4.1/paymentToken', payload);
//         console.log(response)
//     } catch (error) {
//         console.error(error);
//     }
// };



const generatePaymentToken = async (price) => {

    var jwtTokenDecode;
    try {
        let payload = {
            "merchantID": "702702000002346",
            "invoiceNo": `${price.invoice_no}`,
            "description": `${price.serviceName?.replace(/[^a-zA-Z0-9 ]/g, '')}`,
            "amount": `${price.priceValue}`,
            "currencyCode": "SGD",
            // "nonceStr": "a8092512-b144-41b0-8284-568bb5e9264c",
            // "paymentChannel": [],
            // "request3DS": "Y",
        }
        const secretKey = '083819787D08A2D3AC086136238A108F7D797CDCD2FE9D4DABBED0079159B758';
        var jwtToken = await jwt.encode(payload, secretKey);

        // let p = {
        //     "payload": jwtToken
        // };
        // const response = await axios.post('https://pgw.2c2p.com/payment/4.1/paymentToken', {
        //     "payload": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJtZXJjaGFudElEIjoiNzAyNzAyMDAwMDAyMzQ2IiwiaW52b2ljZU5vIjoiMTUyMzk1MzY2MSIsImRlc2NyaXB0aW9uIjoidGVzdCBpdGVtIiwiYW1vdW50IjoxMDAwLCJjdXJyZW5jeUNvZGUiOiJTR0QifQ.iHZYVSWY62FDCEIv9wzBBKrlHD6Y84rlxa5MhR6B7UY"
        // });


        await axios({
            method: 'post',
            url: 'https://pgw.2c2p.com/payment/4.1/paymentToken',
            data: {
                "payload": `${jwtToken}`
            },
            headers: { 'Content-Type': 'application/json' }

        }).then((val) => {


            jwtTokenDecode = jwt.decode(val.data.payload, secretKey);
        });


        return jwtTokenDecode.webPaymentUrl;

    } catch (error) {
        console.error(error);
        throw error;
    }
};



export default generatePaymentToken;