// Import Swiper React components--------
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules------
import { FreeMode } from 'swiper';
import { useDispatch, useSelector } from 'react-redux';
import axios, { Axios } from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Get_sub_cat_id } from '../redux/actions/actions';
import { Box, CircularProgress } from '@mui/material';

function Services_category_slider(props) {
  // -------subcategory slider state and function-----
  const [sub_catActive, setsub_catActive] = useState(0);

  const activeTab = (e) => {
    setsub_catActive(e);
  };



  // -----get sub cat. form store---
  const subCatGet = useSelector((state) => state.set_Subcategories.sub_categories);


  return (
    <>

      <Swiper
        slidesPerView={"auto"}
        spaceBetween={30}
        freeMode
        modules={[FreeMode]}
        className="category_list_slider"
      >
        <SwiperSlide
          className={sub_catActive == '0' ? 'swiper-slide tab_item active' : 'swiper-slide tab_item'}
          id="All"
          onClick={() => {
            activeTab(0);
            props.getId('');
          }}
        >
          <div>All</div>
        </SwiperSlide>
        {/* --- */}
        {!subCatGet ? <SwiperSlide
          className='swiper-slide tab_item'
          id="noItems"
        >
          <CircularProgress />
        </SwiperSlide> : subCatGet.map((e, index) => (
          <SwiperSlide
            className={sub_catActive == (index + 1) ? 'swiper-slide tab_item active' : 'swiper-slide tab_item'}
            key={e.id}
            id={e.id}
            onClick={() => {
              activeTab(index + 1);
              props.getId(e.id);
            }}
          >
            {e.subcat_name}
          </SwiperSlide>
        ))}
        {/* --- */}
      </Swiper>

    </>
  );
}

export default Services_category_slider;
