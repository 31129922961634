
import { useSelector } from "react-redux";
import Activity_card from "./Activity_card";
function ActivityCards(props) {

  // -----get activities from store-----
  const promotionActivities = useSelector((state) => state.Set_activities.activities)

  return (
    <>
      <div className="row activitys_sections Promotions_section activitys_sections_active" >
        {/* upcoming */}
        <div className="col-12 cards_section">
          <div className="row g-2 g-md-4 g-lg-5 px-2 px-md-4 px-lg-5">
            {/* ----map on getdata---- */}
            {!promotionActivities ?
              <>Loading...</>
              : promotionActivities.map((e) => {
                console.log(e)
                return (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3" key={e.id}>
                    <Activity_card name={e.name} image={e.image} idC={e.id} start_date={e.start_date} end_date={e.end_date} getActivityIdC={props.getActivityId} />
                  </div>
                )
              })}
            {/* ----map on getdata---- */}

          </div>
          {/* ------------//Promotions---------------- */}
        </div>
      </div>
    </>
  )
}


export default ActivityCards