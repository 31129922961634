import * as React from 'react';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { Order_date, Order_date_Get, Order_details } from '../../redux/actions/actions';
import { useEffect } from 'react';
import { Box, Button } from '@mui/material';

const isWeekend = (date) => {
  const day = date.day();
  return day === -1 || day === 7;
};

function Date_picker(props) {
  const dispatch = useDispatch();

  // ----pick crrunt day date----
  const datePick = new Date();
  const currntDate = `${datePick.getFullYear()}` + '-' + `${datePick.getMonth() < 10 ? '0' + `${datePick.getMonth() + 1}` : datePick.getMonth() + 1}` + '-' + `${datePick.getDate() < 10 ? '0' + `${datePick.getDate()}` : datePick.getDate()}`;


  // --min date--
  const minDateSet = dayjs(`${currntDate}T00:00:00.000`);

  //  ---date store state---
  const [dateValue, setDateValue] = React.useState(dayjs(currntDate));

  // get date form values
  const orderDate = `${dateValue.$y}` + '-' + `${dateValue.$M < 10 ? '0' + `${dateValue.$M + 1}` : dateValue.$M + 1}` + '-' + `${dateValue.$D < 10 ? '0' + `${dateValue.$D}` : dateValue.$D}`;


  // --setOrderDetials in main object to service detials page--
  const setOrderDetialsStoreFun = () => {
    const { setOrderDetialsStore } = props;
    setOrderDetialsStore.date = orderDate;

  };

  useEffect(() => {
    setOrderDetialsStoreFun();
  }, [orderDate]);

  // ---set date in store---
  const setDateInStore = () => {

    dispatch(Order_date_Get(orderDate))
  }


  return (
    <Box className="datePicker_Box">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker
          // orientation="horizontal"
          componentsProps={{
            actionBar: {
              actions: [''],
            },
          }}

          openTo="day"
          value={dateValue}
          shouldDisableDate={isWeekend}
          minDate={minDateSet}
          onChange={(newValue) => {
            setDateValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <Box sx={{ textAlign: 'right' }}>
        <Button
          sx={{
            border: '1px solid var(--primery-color)', color: 'var(--primery-color)', position: 'relative', top: '-30px', left: '-10px',
          }}
          onClick={() => {
            props.cancle();
            props.setComponentsType('date');
          }}
        >

          CANCEL
        </Button>
        <Button
          sx={{
            border: '1px solid var(--primery-color)', color: 'var(--primery-color)', position: 'relative', top: '-30px',
          }}
          onClick={() => {
            props.setComponentsType('time')
            setDateInStore()
          }}
        >
          DONE
        </Button>

      </Box>
    </Box>
  );
}

export default Date_picker;
