import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
import { memo } from 'react';


const Alerts = memo((props) => {
  const [showAlert, setshowAlert] = React.useState(false)
  // --any activity to show and suto hide alrt---

  const trueAlert = () => {
    // -show-
    setshowAlert(true);
    // -hide-
    setTimeout(() => {
      setshowAlert(false);
    }, 3500)
  }

  // ---get alertdata from store---
  const alertData = useSelector((state) => state.set_alert_details.alertDetials)

  React.useEffect(() => {
    // -show-
    trueAlert();
  }, [alertData])


  return (
    <Stack sx={{ width: 'min(400px ,90%)', margin: "auto", position: "fixed", bottom: "10px", right: "10px", zIndex: "9999", display: `${showAlert ? "block" : "none"}` }} spacing={2} >
      {alertData && <Alert variant="filled" severity={alertData ? alertData.alert_type : ""}>{alertData ? alertData.alert_message : ""}</Alert>}
    </Stack>
  );
})

// --display name--
Alerts.displayName = "Alerts"
export default Alerts