import axios from 'axios'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Wallet_balance, Wallet_balance_add } from '../../redux/actions/actions'

function WalletBox() {
    const dispatch = useDispatch()
    // --get useId from localstorage--
    const userId = JSON.parse(localStorage.getItem("knoc-knoc-user-info"));
    // ------get balance fro api------
    const walletBalanceGet = async () => {
        await axios.post(`${process.env.REACT_APP_API_KEY}/api/wallet_amount_get`, {
            "user_firebase_id": `${userId ? userId.firebase_id : ""}`
        }).then((res) => {

            dispatch(Wallet_balance(res.data.amount))
        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        walletBalanceGet()
    }, [])
    // ------//get balance fro api------

    // ---balacne get from store---
    // const walletBalance = 0;
    const walletBalance = useSelector((state) => state.get_payment_prices.walletBalance)

    // ---//balacne get from store---
    // ------add wallet price in payment values store------
    const addWalletPrice = async (e) => {
        // --get amount from api--
        await axios.post(`${process.env.REACT_APP_API_KEY}/api/wallet_amount`, {
            "user_firebase_id": `${userId ? userId.firebase_id : ""}`,
            "amount": `${e}`
        }).then((res) => {
            // --add amount on store for minus form main price--
            dispatch(Wallet_balance_add(e))
        }).catch((err) => console.log(err))
    }
    // ------//add wallet price in payment values store------
    return (
        <div className="coupen_enter_box position-relative walletPrice_box">
            <p className="pera_common">Wallet Amount</p>
            <p> <b> ${walletBalance}</b></p>
            <small>*insufficient balance</small>
            {walletBalance > 0 ?
                <input type="radio" className='form-check-input shadow-none' style={{ position: "absolute", bottom: "20px", right: "15px", width: "1.2rem", height: "1.2rem" }} onClick={() => addWalletPrice(walletBalance)} /> : ""
            }
        </div>
    )
}

export default WalletBox