function Activity_card(props) {
  return (<>
    {/* card */}

    <div className="card p-2">
      <figure>
        <img
          src={`${process.env.REACT_APP_API_KEY}/${props.image}`}
          className="card-img-top"
          alt="..."
          loading="lazy"
          decoding="async"
        />
        <figcaption>{props.name}</figcaption>
      </figure>
      <div className="card-body">
        <h5 className="card-title" style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap"
        }}>{props.name}</h5>
        <p className="card-text mb-2">{props.start_date} - {props.end_date}</p>

        <button
          href="#"
          className="btn  view_detail_button"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          id={props.idC}
          onClick={props.getActivityIdC}
        >
          View Details
        </button>
      </div>
    </div>

    {/* //card */}
  </>)
}

export default Activity_card