import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Outlet } from 'react-router-dom';

import ActivityCards from '../Activity_component/Promotions';
import Promotion from '../Activity_component/Promotions';
import { Show_activity } from '../redux/actions/actions';
import Loading from '../Services_page/Loading';
import './Activity.css'

function Activity() {

  const dispatch = useDispatch()
  // ----loading state----
  const [loading, setLoading] = useState(true)
  // ----items available status----
  const [itemsStatus, setItemsStatus] = useState(0)


  // -----catagory bar active state--
  const [activeClass, setactiveClass] = useState("PROMOTIONS")
  const ActiveClassFun = (e) => {
    setactiveClass(e.target.id)
  }

  //  -----timing store state-----

  const [timing, setTiming] = useState("past");
  const timing_store = (e) => {

    setTiming(e.target.id)
  }


  // ------get activities for Promotion---
  const activities_promotion = async () => {
    const promotionData = await axios.post(`${process.env.REACT_APP_API_KEY}/api/activity`, {
      "type": `${activeClass}`,
      "sub_type": `${timing}`
    }).then((res) => {
      // response data
      const promotionData_final = res.data.data;
      // condition if data in the api so add data else no items component show
      if (promotionData_final) {
        // --loading--
        setLoading(false)
        // status change when items load
        setItemsStatus(0)
        dispatch(Show_activity(promotionData_final))
      } else {
        // status change when items not found
        setItemsStatus(1)
      }

    }).catch((err) => {
      console.log(err)
    })


  }




  // -----get activities from store for shoe detials in modal---------
  const promotionActivities_detail = useSelector((state) => state.Set_activities.activities);
  const [modaldetails, setmodaldetails] = useState()

  const getActivityId = (e) => {
    const activity = e.target.id;
    const filterActivity = promotionActivities_detail.filter((e) => e.id == activity)
    setmodaldetails(...filterActivity)
    console.log(modaldetails, "modaldetail2")
  }



  useEffect(() => {
    activities_promotion();

  }, [timing, activeClass])


  return (
    <>

      <>
        <section className="stickey_section page_zoom_animation" id="stickey_section_id">
          <section className="upper_tab_section ">
            <form action="#">
              <div className="upper_tab_div activity_page">
                <div className="bar">
                  <ul>
                    {/* <li className="bg_label thired" /> */}
                    <li className={activeClass == "PROMOTIONS" ? "tab_item active" : "tab_item"} id='PROMOTIONS' onClick={ActiveClassFun}>Promotions</li>
                    <li className={activeClass == "CAMPAIGNS" ? "tab_item active" : "tab_item"} id='CAMPAIGNS' onClick={ActiveClassFun}>Campaigns</li>
                    <li className={activeClass == "CHARITY" ? "tab_item active" : "tab_item"} id='CHARITY' onClick={ActiveClassFun}>Charity</li>
                  </ul>
                </div>
              </div>
            </form>
          </section>
          {/* order time filter */}
          <div className="time_filter">
            <ul>
              <li className={timing == "past" ? "timeFilter_item active" : "timeFilter_item"} id='past' onClick={timing_store}>Past</li>
              <li className={timing == "current" ? "timeFilter_item active" : "timeFilter_item"} id='current' onClick={timing_store}>Current</li>
              <li className={timing == "upcoming" ? "timeFilter_item active" : "timeFilter_item"} id='upcoming' onClick={timing_store}>upcoming</li>


            </ul>
          </div>
          {/* //order time filter */}
        </section>
        {/*--------//navbar------------*/}
        {/* -----modal---- */}
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Promotion
                </h5>
                <button
                  type="button"
                  className="btn-close "
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="modal_image mb-3">
                  <figure>
                    <img
                      src={!modaldetails ? "" : `${process.env.REACT_APP_API_KEY}/${modaldetails.image}`}
                      alt="knocknoc"
                      className="img-fluid"
                      loading="lazy"
                      decoding="async"
                    />
                  </figure>
                </div>
                <div className="content">
                  <div className="service_title">
                    <h5 className="title_name">Promotions</h5>
                    <p className="date">{!modaldetails ? "" : modaldetails.start_date} - {!modaldetails ? "" : modaldetails.end_date}</p>
                  </div>
                  <div className="discrption">
                    <h5 className='mb-1 mt-2'>Description</h5>
                    <p className="pera m-0">
                      {!modaldetails ? "" : modaldetails.description}
                    </p>
                  </div>
                </div>
              </div>
              {/* <div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
  <button type="button" class="btn btn-primary">Save changes</button>
</div> */}
            </div>
          </div>
        </div>
        {/* -----//modal---- */}
        {/*   ---------Page--------- */}
        <main className='activityMain page_zoom_animation'>
          <section className="main_content common_container_1920">
            <div className="container-fluid mt-2 mt-md-5  px-2 px-md-3 px-lg-2 pb-3 pb-md-3 pb-lg-5 cards_sections">
              {/* ------------cards component---------------- */}
              {/* ---if items found then enter first condition and show loading then itmes--- */}
              {itemsStatus == 0 ?
                [loading == true ? <Loading /> : <ActivityCards getActivityId={getActivityId} />]
                :
                //  --if no items available then show it--
                <>
                  <div className="no_services_div">
                    <img src="../../images/no_services.gif" alt="#" />
                    <p>No Activity Available</p>
                  </div>
                </>
              }



              {/* ------------//cards component---------------- */}

            </div>
          </section>
        </main>
        {/*   ---------//Page--------- */}
      </>

    </>
  )
}

export default Activity;