import { Outlet } from "react-router-dom";
import MyOrdersList from "../My_orders/MyOrdersList";
import MyOrders_detials from "../My_orders/MyOrders_detials";
import './MyOrders.css'

function MyOrders() {
    return (
        <>
            <div className="myOrders_mainContainer">
                <Outlet />
                {/* <MyOrdersList /> */}
                {/* <MyOrders_detials /> */}
            </div>
        </>
    )
}

export default MyOrders;