import * as React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Show_alerts } from '../redux/actions/actions';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';

// ---phone number--
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
// ----firbase----
import { getAuth, linkWithPhoneNumber, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { getMessaging, getToken } from "firebase/messaging";
import { app, messaging, provider } from '../../firebase/firebase';
import { signInWithPopup } from 'firebase/auth';
// --css--
import './Login_register.css'
import { Button } from '@mui/material';
import { ConstructionOutlined } from '@mui/icons-material';
import useLogin from '../../commonFunctions/useLogin';



// ---main function---
function Register() {

  const navigate = useNavigate();
  // ---modal---
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // ---fcm token custom hook---
  const { generateFcmToken, signInWithPopupFun, ismobile } = useLogin();
  useEffect(() => {
    // ---when user come to register fcm token generate---
    generateFcmToken()
  }, [])

  // ---get fcmtoken from local session---
  const fcmTokenGet = sessionStorage.getItem("myFcmToken")


  // ----input formets---
  let regex = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  let regex2 = /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/
  let nameRegex = /^[a-zA-Z ]{2,30}$/;
  let emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  const singapurRegex = /^(\+65|65|0)\d{8}$/;
  // ----loading button---
  const [loading, setLoading] = useState(true)

  // --------------states-------------
  //dispatch
  const dispatch = useDispatch()
  // for chack mobile number and otp state
  const [Phonevalue, setPhonevalue] = useState("")
  const [verifyOtp, setVerifyOtp] = useState("")
  const getOtpValues = (e) => {
    setVerifyOtp(e.target.value)
  }

  // ---error massages states---
  const [errorName, setErrorName] = useState("")
  const [errorLastName, setErrorLastName] = useState("")
  const [errorEmail, setErrorEmail] = useState("")
  const [errorMobileNumber, setErrorMobileNumber] = useState("")
  const [errorGender, setErrorGender] = useState("")
  // ---//error massages states---
  // ---all inputs values get an set in state---
  const [values, setValues] = useState({
    firebase_id: "",
    firstname: "",
    lastname: "",
    email: "",
    MobileNumber: "",
    gender: "",
    agreeCondition: false,
  })


  const valuesGet = (e) => {

    let name = e.target.name;
    let value = e.target.value;
    setValues((pre) => {
      return { ...pre, [name]: value }
    })

    // validate
    if (values.firstname != "" || nameRegex.test(values.firstname)) {
      setErrorName("")
    }
    if (values.lastname != "" || nameRegex.test(values.lastname)) {
      setErrorLastName("")
    }
    if (values.email != "" || emailRegex.test(values.email)) {
      setErrorEmail("")
    }
    if (Phonevalue != "" || regex2.test(Phonevalue)) {
      setErrorMobileNumber("")
    }
    if (values.gender != "") {
      setErrorGender("")
    }


  }
  const auth = getAuth(app);
  // ---//all inputs values get an set in state---
  // ------Update Profile Info---------------------------------//
  const fullname = auth.currentUser && auth.currentUser.displayName?.split(" ")
  const updateUserInfo = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/updateprofile`, {
        id: "",
        firebase_id: `${auth.currentUser.uid}`,
        fcm_token: `${fcmTokenGet}`,
        firstname: `${fullname[0]}`,
        lastname: `${fullname[1]}`,
        email: `${auth.currentUser.email}`,
        phonenumber: `${Phonevalue}`,
        gender: `${"male" || ""}`,
        image: `${auth.currentUser.photoURL}`,
      }
      )
      .then((res) => {
        // --alert data send---
        dispatch(
          Show_alerts({
            alert_message: "Login Successfull",
            alert_type: "success",
          }))
        setTimeout(() => {
          navigate("/")
          window.location.reload();
        }, 1000)


        //--modal close when profile update--
        // props.handleClose();
        // --update data in localstorage and show in page--
        localStorage.setItem("knoc-knoc-user-info", JSON.stringify({
          id: "",
          firebase_id: `${auth.currentUser.uid}`,
          fcm_token: `${fcmTokenGet}`,
          firstname: `${auth.currentUser.displayName}`,
          lastname: `${auth.currentUser.displayName}`,
          email: `${auth.currentUser.email}`,
          phonenumber: `${values.MobileNumber}`,
          gender: `${"male" || ""}`,
          image: `${auth.currentUser.photoURL}`,
        }));
        // --reload winodw--


      })
      .catch((err) => console.log(err));
  };
  const mobileLink = () => {
    if (regex.test(Phonevalue) || singapurRegex.test(Phonevalue)) {
      setErrorMobileNumber("");
      // ---login button disable---
      setLoading(false);

      // e.preventDefault();
      // phoneCheckForLogin()
      // --upper function call here--
      onCaptchVerify();

      // ----
      const phoneNumber = Phonevalue;
      console.warn(phoneNumber);
      const appVerifier = window.recaptchaVerifier;
      linkWithPhoneNumber(auth.currentUser, phoneNumber, appVerifier)
        .then((confirmationResult) => {
          // --if number is vrify from fire then sand a otp to number and otp modal open--

          setOpen(true);

          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          console.log(confirmationResult, ">><<<<<")
          // ...

        })
        .catch((error) => {
          console.log(error);
          // --alert data send---
          dispatch(
            Show_alerts({
              alert_message: "Something went wrong!",
              alert_type: "error",
            })
          );
        });
    } else {
      // e.preventDefault();
      setErrorMobileNumber("Please provide a valid phone number");
    }
  }
  // ----------------Phone Number Check for login-----------------------------//
  const phoneCheckForLogin = async (e) => {
    e.preventDefault()
    console.log("check")
    const res = await axios.post(
      `${process.env.REACT_APP_API_KEY}/api/checkphonenumber`,
      {
        phonenumber: Phonevalue,
      }
    );
    if (res.data.status == "200") {
      // ---loading button inable---
      // setLoading(true);
      dispatch(
        Show_alerts({
          alert_message: "Phone Number Is Already Exist",
          alert_type: "error",
        })
      );
    } else if (res.data.status == "400") {
      mobileLink()

    }
  };
  // -------------function for add user row in databse if otp is true----------------
  const userDataEntry = async () => {
    await axios.post(`${process.env.REACT_APP_API_KEY}/api/register`, {
      "firebase_id": values.firebase_id,
      "fcm_token": fcmTokenGet ? fcmTokenGet : "null",
      "firstname": values.firstname,
      "lastname": values.lastname,
      "email": values.email,
      "phonenumber": values.MobileNumber,
      "gender": values.gender
    }).then((res) => {

      if (res.data.status == 409) {
        // --alert data send---
        dispatch(Show_alerts({
          alert_message: "This Email Is Already Exist",
          alert_type: "error"
        }))
      } else {
        // ---set data in local storage----
        localStorage.setItem("knoc-knoc-user-info", JSON.stringify(values));
        // --alert data send---
        Swal.fire({
          title: 'Register Successfull',
          icon: 'success',
          showConfirmButton: false,
          timer: 2000
        }
        ).then(() => {
          navigate("/");
          window.location.reload();
        })
      }

    }).catch(() => {
      // --alert data send---
      dispatch(Show_alerts({
        alert_message: "Somthing went wrong!",
        alert_type: "error"
      }))
    })
  }
  // -------------//function for add user row in databse if otp is true----------------

  // ----Phone number chack in data base----
  const phoneNumberChack = async () => {
    const res = await axios.post(`${process.env.REACT_APP_API_KEY}/api/checkphonenumber`, {
      "phonenumber": values.MobileNumber
    })
    if (res.data.status == "400") {
      // ---loading button inable---
      setLoading(true)

      // --if phone number is not exicet in data base then new user add--
      userDataEntry();

    }
    else if (res.data.status == "200") {
      // ---loading button inable---
      setLoading(true)
      // --alert data send---
      dispatch(Show_alerts({
        alert_message: "Phone Number Is Already Exist",
        alert_type: "error"
      }))

    }
  }
  // ----//Phone number chack in data base----

  // -------------//function for add user row in databse if otp is true----------------

  // --------------------------------------firebase functions-----------------------

  // ----recaptcha verify----
  const onCaptchVerify = () => {
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        if (ismobile) {
          mobileLink()
        } else {
          onSignInFunction();
        }

      },
      "expired-callback": () => {
      },
    }, auth);
  }
  // ----//recaptcha verify----


  // ----sign in function ----
  const onSignInFunction = () => {

    // validate
    if (values.firstname == "" || !nameRegex.test(values.firstname)) {
      setErrorName("Please provide valid first name")
    }
    else if (values.lastname == "" || !nameRegex.test(values.lastname)) {
      setErrorLastName("Please provide valid last name")
    }
    else if (values.email == "" || !emailRegex.test(values.email)) {
      setErrorEmail("Please provide a valid email")
    }
    else if (Phonevalue == "" || !regex2.test(Phonevalue)) {
      setErrorMobileNumber("Please provide a valid phone number")
    }
    else if (values.gender == "") {
      setErrorGender("Please select gender")
    }
    else {

      // ---loading button disable---
      setLoading(false)
      // ----upper function call here---
      onCaptchVerify();
      // ----
      const phoneNumber = Phonevalue;
      const appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        .then((confirmationResult) => {
          // --alert masaage--
          // --alert data send--
          dispatch(Show_alerts({
            alert_message: "Otp send successfully",
            alert_type: "success"
          }))
          // -- if phone number is verify from firebase then and otp and show popup--
          setOpen(true)
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          // ...
        }).catch((error) => {
          console.error(error)
          // Error; SMS not sent
          // --alert data send--
          dispatch(Show_alerts({
            alert_message: `${error.message}`,
            alert_type: "error"
          }))
        });
    }
  }
  // ----//sign in function ----

  // ----verify otp function----
  const verifyCode = () => {
    window.confirmationResult.confirm(verifyOtp).then((result) => {
      // User signed in successfully.
      const user = result.user;
      // -- if otp is verify then hide popup--
      setOpen(false)

      // --phone number and firbaseID add in values object--
      values.MobileNumber = Phonevalue
      values.firebase_id = user.uid

      // ---call data add in database function---
      if (ismobile) {
        // alert("Check")
        updateUserInfo()
      } else {

        phoneNumberChack();
      }
      // ...
    }).catch((error) => {
      // ---if otp is false show alert--
      // --alert data send--
      dispatch(Show_alerts({
        alert_message: "Otp is Not Matched",
        alert_type: "error"
      }))
    });
  }
  // ----//verify otp function----
  // --------------------------------------//firebase functions-----------------------







  return (
    <>
      {/* forms section */}
      <section className="forms_section">
        {/* ---modal--- */}
        {/* <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ height: "100vh", width: "100vw", display: "flex", justifyContent: "center", flexDirection: "column", gap: "10px", alignItems: "center" }}>
            <input type="number" className='form-control' value={verifyOtp} onChange={getOtpValues} />
            <button className='btn btn-success' onClick={verifyCode}>otp verify</button>

          </Box>
        </Modal> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ position: "relative", height: "100vh", width: "100vw", display: "flex", justifyContent: "center", flexDirection: "column", gap: "10px", alignItems: "center" }}>

            {/* ----otp verify--- */}
            <div className="otp_popup">
              {/* <Button class="closeBtn" onClick={handleClose}><i class="bi bi-x"></i></Button> */}
              <div className="otp_banner">
                <img src="./images/Otp_banner.webp" alt="#" />
              </div>
              <div className="otpInputs">
                <h3 className="heading"> <i class="bi bi-file-earmark-lock2"></i>  Authentication Code</h3>
                <input type="number" className='form-control shadow-none' placeholder="ENTER YOUR OTP..." value={verifyOtp} onChange={getOtpValues} />
                <button className='btn submit_btn shadow-none' onClick={verifyCode}>otp verify</button>
              </div>
            </div>
          </Box>
        </Modal>
        {/* ---//modal--- */}
        {/*------------------LinkMobileNumber--------------------------------------------}
 {/* ---modal--- */}
        <Modal
          open={ismobile}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              height: "100vh",
              width: "100vw",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
            }}
          >
            {/* ----otp verify--- */}
            <div className="otp_popup">
              {/* <Button className="closeBtn" onClick={handleClose}>
                <i className="bi bi-x"></i>
              </Button> */}

              <div className="linkInputs">
                <h3 className="heading">
                  Enter Mobile Number
                </h3>
                <br />
                <PhoneInput
                  placeholder="Enter phone number"
                  value={Phonevalue}
                  countryCallingCodeEditable={false}
                  defaultCountry="SG"
                  className='inputPhone'
                  onChange={setPhonevalue} />
                <button
                  className="btn submit_btn shadow-none"
                  onClick={phoneCheckForLogin}
                >
                  Send Otp
                </button>
              </div>
            </div>
          </Box>
        </Modal>

        {/* ----recaptcha contianer---- */}
        <div id='recaptcha-container'></div>
        {/* ----//recaptcha contianer---- */}
        {/* ================== */}
        {/* register form container */}
        <div className="container form_container register_form_container">
          {/* ---login_form--- */}
          <div className="row form_row login_form_main_row">
            <div className="col-12 text-end pt-2 pe-2">
              <NavLink to="/Login">
                <i className="bi bi-x-circle fs-4" />
              </NavLink>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-12 form_col">
                  {/*login form */}
                  <div className="login_form form_div">
                    <div>
                      <p className="heading_common">Register</p>
                    </div>

                    <div className="row  input_divs">
                      <div className="col-12 mb-3">
                        {/* First name */}
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          name='firstname'
                          placeholder="First Name"
                          aria-describedby="emailHelp"
                          onChange={valuesGet}
                          required
                        />

                        <div className='text-start text-danger py-1'> {errorName} </div>
                      </div>
                      {/* Last name */}
                      <div className="col-12 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          name='lastname'
                          placeholder="Last Name"
                          aria-describedby="emailHelp"
                          onChange={valuesGet}
                          required
                        />
                        <div className='text-start text-danger py-1'> {errorLastName} </div>
                      </div>
                      {/* Email */}
                      <div className="col-12 mb-3">
                        <input
                          type="email"
                          className="form-control"
                          id="exampleInputEmail1"
                          name='email'
                          placeholder="E-mail"
                          aria-describedby="emailHelp"
                          onChange={valuesGet}
                          required
                        />
                        <div className='text-start text-danger py-1'> {errorEmail} </div>
                      </div>
                    </div>
                    {/* --- */}
                    <div className=" row  input_divs">
                      {/* phone */}
                      <div className="col-12 mb-3">
                        <PhoneInput
                          placeholder="Enter phone number"
                          value={Phonevalue}
                          countryCallingCodeEditable={false}
                          defaultCountry="SG"
                          className='inputPhone'
                          onChange={setPhonevalue} />
                        <div className='text-start text-danger py-1'> {errorMobileNumber} </div>
                      </div>
                      {/* gender */}
                      <div className="col-12 mb-3 d-flex align-items-center">
                        <div className=" form-check-inline">
                          <input
                            type="radio"
                            name="gender"
                            id="inlineRadio1"
                            defaultValue="male"
                            onChange={valuesGet}
                          />
                          <label
                            className="form-check-label ms-1"
                            htmlFor="inlineRadio1"
                          >
                            Male
                          </label>
                        </div>
                        <div className=" form-check-inline">
                          <input
                            type="radio"
                            name="gender"
                            id="inlineRadio2"
                            defaultValue="female"
                            onChange={valuesGet}
                          />
                          <label
                            className="form-check-label ms-1"
                            htmlFor="inlineRadio2"
                          >
                            Female
                          </label>
                        </div>
                        <div className=" form-check-inline">
                          <input
                            type="radio"
                            name="gender"
                            id="inlineRadio3"
                            defaultValue="othere"
                            onChange={valuesGet}
                          />
                          <label
                            className="form-check-label ms-1"
                            htmlFor="inlineRadio3"
                          >
                            Other
                          </label>
                        </div>
                        <div className='text-start text-danger py-1'> {errorGender} </div>
                      </div>
                    </div>
                    {/* chack_form_div */}
                    <div className="chack_form_div">
                      <div className="mb-2 form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck2"
                          name='agreeCondition'
                          value={true}
                          onChange={valuesGet}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleCheck1"
                        >
                          Agree with
                          <span>
                            <b> terms and conditions</b>
                          </span>
                        </label>
                      </div>
                      {/* <div class="forget_password">
                        <p><a href="#">Forget password</a></p>
                      </div> */}
                    </div>
                    {/* //chack_form_div */}
                    {!loading ? <button className="btn submit_button" disabled>
                      Register
                    </button> : <button onClick={onSignInFunction} className="btn submit_button">
                      Register
                    </button>}




                  </div>
                  {/*//login form */}
                  {/* other method */}
                  <div className="othere_method">
                    <div>
                      <p className="pera_common">Register with</p>
                    </div>
                    <div className="icons">
                      <ul>

                        <li title="Login with GOOGLE" onClick={signInWithPopupFun}>
                          <a>
                            <i className="bi bi-google" />
                          </a>
                        </li>


                      </ul>
                    </div>
                    {/* already account */}
                    <div className="switch_button">
                      Already have an account ?
                      <NavLink to="/Login">
                        Login Here
                      </NavLink>
                    </div>
                    {/* //already account */}
                  </div>
                  {/* //other method */}
                </div>
                {/*  */}
                {/*             <div class="col-12 col-sm-4 image_col">
              <div class="image_div">
                <img src="img/mobile-login-image.webp" />
              </div>
            </div> */}
              </div>
            </div>
          </div>
          {/* ---//login_form--- */}
        </div>
        {/* //register form container */}
      </section>
      {/* //forms section */}
    </>

  )
}

export default Register;