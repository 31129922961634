import { Box, Button, CircularProgress, Divider } from '@mui/material';
import axios from 'axios';
import React, { useLayoutEffect } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Order_addressId_Get, User_address } from '../../redux/actions/actions';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useNavigate } from 'react-router-dom';
import Payment_button from './Payment_button';

function Address_picker(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [addressloader, setaddressloader] = useState(false)
    //   ---useradrees---
    const [userAddressSelectId, setUserAddressSelectId] = useState('');

    //   ---useradrees---
    const [userAddressListShow, setUserAddressListShow] = useState();


    // ----userfire base id get from store----
    const fireBaseIdGet = useSelector((state) => state.set_userData.userDetials?.firebase_id);

    // ---address api call---
    const addressFun = async () => {
        setaddressloader(true)
        await axios.post(`${process.env.REACT_APP_API_KEY}/api/get_address`, { users_id: fireBaseIdGet }).then((res) => {
            const userAddress = res.data.data;
            dispatch(User_address(userAddress));
            setUserAddressListShow(userAddress)
            setaddressloader(false)
        }).catch((err) => {
            setaddressloader(false)
            console.log(err);
        });
    };

    // ----get address from store----
    const addressList = useSelector((state) => state.get_user_address.addresses);

    useLayoutEffect(() => {
        addressFun();
    }, []);

    useEffect(() => {
        setUserAddressListShow(addressList)
    }, [addressList]);


    // --setOrderDetials in main object to service detials page--
    const setOrderDetialsStoreFun = () => {
        const { setOrderDetialsStore } = props;
        setOrderDetialsStore.address_id = userAddressSelectId;
        // console.log(setOrderDetialsStore);
    };

    useEffect(() => {
        setOrderDetialsStoreFun();
        // ---set address id in store(state not currnt time update so i call useEffet for save value)---
        dispatch(Order_addressId_Get(userAddressSelectId))
    }, [userAddressSelectId]);


    const allValues = useSelector((state) => state)


    return (
        <>
            <Box sx={{
                textAlign: 'left', padding: '5px 0', marginBottom: '10px', background: '#00c0c947', borderRadius: '10px',
            }}
            >
                <Button
                    sx={{
                        border: 'none', color: 'var(--black-color)', position: 'relative', top: '0px',
                    }}
                    onClick={() => props.setComponentsType('date')}
                >
                    <DateRangeIcon />
                </Button>
                <Button
                    sx={{
                        border: 'none', color: 'var(--black-color)', position: 'relative', top: '0px',
                    }}
                    onClick={() => props.setComponentsType('time')}
                >
                    <AccessTimeIcon />
                </Button>
            </Box>

            {/* ------ */}
            {addressloader ? <Box sx={{ display: "flex", justifyContent: "center", padding: "10px 0" }}> <CircularProgress sx={{ color: "var(--primery-color)" }} />  </Box> : !userAddressListShow || userAddressListShow === undefined
                //  --if no items available then show it--
                ? (
                    <>
                        <div className="no_services_div">
                            <img src="../../../images/no_address.gif" alt="#" />
                            <p>No Address Available</p>
                        </div>
                        <div className="address_ok_btns">
                            <Box sx={{ textAlign: 'right' }}>
                                <Button
                                    sx={{
                                        border: '1px solid var(--primery-color)', color: 'var(--primery-color)', position: 'relative', left: '-15px',
                                    }}
                                    onClick={() => {
                                        props.cancle();
                                        props.setComponentsType('date');
                                    }}
                                >
                                    CANCEL
                                </Button>
                                <Button
                                    sx={{ border: '1px solid var(--primery-color)', color: 'var(--primery-color)', position: 'relative' }}
                                    onClick={() => {
                                        navigate('/Profile/addresses');
                                    }}
                                >
                                    ADD ADDRESS
                                </Button>

                            </Box>
                        </div>

                    </>
                )
                : (
                    <div className="userAddress_box">


                        <p className="heading">Select Address</p>
                        <Divider sx={{ margin: '10px 0' }} />
                        {/* --address-- */}
                        {userAddressListShow.map((e, index) => (
                            <label htmlFor={e.id} key={index}>
                                <div className="addressItem">
                                    <div>
                                        <p className="title">Home</p>
                                        <p className="pera">
                                            {e.house_no}
                                            {' '}
                                            {e.area_city}
                                            {' '}
                                            {e.post_code}
                                            {' '}
                                            {e.street}
                                            {' '}
                                            {e.state}
                                        </p>
                                    </div>
                                    <div>
                                        <input type="radio" name="address" id={e.id} onChange={(e) => {
                                            setUserAddressSelectId(e.target.id)
                                        }} />
                                    </div>
                                </div>
                            </label>
                        ))}
                        {/* --//address-- */}
                        <div className="address_ok_btns">
                            <Box sx={{ textAlign: 'right' }}>
                                <Button
                                    sx={{

                                        border: '1px solid var(--primery-color)', color: 'var(--primery-color)', position: 'relative', left: '-10px', textTransform: "capitalize"
                                    }}
                                    onClick={() => {
                                        props.cancle();
                                        props.setComponentsType('date');
                                    }}
                                >
                                    Cancel
                                </Button>
                                {/* <Button sx={{ border: "1px solid var(--primery-color)", color: "var(--primery-color)", position: "relative" }} onClick={() => {
                                // function for call api
                                props.orderConfirm()
                                //function for hidepop up
                                props.cancle()
                                // function for rander first calander
                                props.setComponentsType("date")
                            }
                            }>BOOK SERVICE</Button> */}
                                {userAddressSelectId == '' ? <button className="common_button bg-transparent text-secondary border" disabled>Checkout</button>
                                    : <Payment_button />}
                            </Box>
                        </div>
                    </div>
                )}
        </>
    );
}

export default Address_picker;

//---order entry function in payment button component---
