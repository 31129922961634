import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider } from '@mui/material';


function Get_Help(props) {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            <div
                className="content_items get_Help show" >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 heading_item">Get Help</div>
                        <div className="col-12 content">
                            <h5>How we can help you ?</h5>
                            <div className="items">
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ boxShadow: "none", marginBottom: "1rem" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        sx={{ border: "1px solid var(--primery-color)", borderRadius: "10px", fontSize: "1.2rem" }}
                                    >
                                        <Typography sx={{ width: '80%', flexShrink: 0, fontSize: "1.2rem", color: "var(--primery-color)", }}>
                                            Knoc Knoc Professionals
                                        </Typography>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {/* ------- */}
                                        <p className="para mt-2">Questions about our knoc knoc professionals</p>
                                        <div className="mt-2">
                                            <p className="para">
                                                What are cat providers all about?
                                            </p>
                                            <p className="para">A: Our service providers as we call them, cat providers to serve our cat supporters which is our valued users. </p>

                                        </div>
                                        <div className="mt-2">
                                            <p className="para">
                                                What languages do our cat providers meow on?
                                            </p>
                                            <p className="para">A: Our cat providers can meow a wide variety of languages and dialects including:
                                                <ul style={{ listStyle: "doted" }}>
                                                    <li>English</li>
                                                    <li>Chinese</li>
                                                    <li>Dialects such as Hokkien, Teochew, Cantonese, Hainanese, Hakka and more.</li>
                                                </ul>

                                            </p>

                                        </div>
                                        <div className="mt-2">
                                            <p className="para">
                                                What do our cat providers do when they come for the services users booked?
                                            </p>
                                            <p className="para">A: To meow and provide their services to our users. </p>

                                        </div>
                                        {/* --- */}
                                        {/* ------- */}
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={{ boxShadow: "none", marginBottom: "1rem" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2bh-content"
                                        id="panel2bh-header"
                                        sx={{ border: "1px solid var(--primery-color)", borderRadius: "10px", fontSize: "1.2rem" }}
                                    >
                                        <Typography sx={{ width: '80%', flexShrink: 0, fontSize: "1.2rem", color: "var(--primery-color)", }}>
                                            Change of plans and waivers
                                        </Typography>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {/* ------- */}
                                        <p className="para mt-2">Questions on our policy</p>
                                        <div className="mt-2">
                                            <p className="para">
                                                Request for change of plans
                                            </p>
                                            <p className="para">A: At Knoc Knoc, we understand how plans can change due to unforeseen circumstances.  </p>

                                        </div>
                                        <div className="my-2">

                                            <p className="para">For our services booked before our cat providers come within the time range of 48 hours, we allow changes to our users so that the time slot allocated can be change to a different time slot. For other changes made less than 24hours, please contact Happy Cat via 87713358 on whatsapp or email us at cscat@knocknoc.biz and we will help base on case to case basis.

                                            </p>

                                        </div>
                                        <div className="mt-2">
                                            <p className="para">
                                                Waiver for change/cancellation fees
                                            </p>
                                            <p className="para">A: For each scheduled service, our cat providers have committed their time to deliver their meows and best service to our users. Cancellation and change fees are imposed to ensure our cat providers get well fed on their favourite milk.  </p>

                                        </div>
                                        <div className="mt-2">
                                            <p className="para">We understand that changes are a constant thing in our lives, should a cancellation or change be due to a extenuating circumstance, you may appeal a cancellation fee by sending an email to cscat@knocknoc.biz </p>
                                        </div>
                                        <div className="mt-2">
                                            <p className="para">All waiver requests will be reviewed on a case to case basis. </p>
                                        </div>

                                        {/* --- */}
                                        {/* ------- */}
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Get_Help;