import { Action_types } from "../contants/actions_types";
import { ADD_categoryes, My_packages, Show_sub_categories } from "../actions/actions";



// --STATUSES--
export const STATUSES = Object.freeze({
  IDEL: 'idel',
  ERROR: 'error',
  LOADING: 'loading'
})


// ---banner images---
const BannerImages = (state = { images: [], status: "loading" }, { type, payload }) => {
  switch (type) {
    case "BANNER_IMAGES_REQ":
      return { status: "loading" }
    case "BANNER_IMAGES_SUCCESS":
      return { ...state, images: payload, status: "idel" }
    case "BANNER_IMAGES_FAILED":
      return { status: "error" }
    default:
      return state
  }
}


const initialState = {
  status: STATUSES.LOADING,
  services: []
}

const CatagoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SHOW_CATEGORIES_REQ":
      return { status: STATUSES.LOADING }
    case "SHOW_CATEGORIES_SUCCESS":
      return { ...state, services: payload, status: STATUSES.IDEL };
    case "SHOW_CATEGORIES_FAIL":
      return { status: STATUSES.ERROR }
    default:
      return state;
  }
}

const AddGalleryImages = (state = { gallery_images: [], status: "loading" }, { type, payload }) => {
  switch (type) {
    case "SHOW_GALLERY_IMAGES_REQ":
      return { status: "loading" }
    case "SHOW_GALLERY_IMAGES_SUCCESS":
      return { ...state, gallery_images: payload, status: "idel" }
    case "SHOW_GALLERY_IMAGES_FAIL":
      return { status: "error" }
    default:
      return state
  }
}



const Subcategories = (state = {}, { type, payload }) => {
  switch (type) {
    case Action_types.SHOW_SUB_CATEGORIES:
      return { ...state, sub_categories: payload };
    default:
      return state
  }
}

// ------services reducer----

const servicesData = {
  status: STATUSES.LOADING,
  services: [],
  storeAllLodedSerives: [],
}

const Showservices = (state = servicesData, { type, payload }) => {
  switch (type) {
    case "SHOW_SERVICES_REQ":
      return { ...state, status: STATUSES.LOADING }
    case "SHOW_SERVICES_SUCCESS":
      payload && state.storeAllLodedSerives.unshift({ catId: payload[0].cat_id, data: payload })
      return { ...state, services: payload, status: STATUSES.IDEL }
    case "SHOW_SERVICES_FAIL":
      return { ...state, status: STATUSES.ERROR }
    default:
      return state
  };
}

// ------service detials-----
const service_detials = {
  service_detials: []
}

const Service_details = (state = service_detials, { type, payload }) => {
  switch (type) {
    case Action_types.GET_SERVICE_DETAILS:
      return { ...state, service_detials: payload }
    case Action_types.REMOVE_SERVICE_DETAILS:
      return { ...state, service_detials: [] }
    default:
      return state
  };
}

// ------services reducer----

const activityData = {
  activities: []
}

const Showactivity = (state = activityData, { type, payload }) => {
  switch (type) {
    case Action_types.SHOW_ACTIVITY:
      return { ...state, activities: payload }
    default:
      return state
  };
}


// --------------------
const favorites = {
  items: []
}
const Showfavorite = (state = favorites, { type, payload }) => {
  switch (type) {
    case Action_types.GET_FAVORITE:
      return { ...state, items: payload }
    default:
      return state
  };
}

// ------------user data---------

const ShowUserData = (state = {}, { type, payload }) => {
  switch (type) {
    case Action_types.USER_DATA:
      return { ...state, userDetials: payload }
    default:
      return state
  }
}


// ------------alert data---------

const ShowAlerts = (state = {}, { type, payload }) => {
  switch (type) {
    case Action_types.ALERT_TYPES:
      return { ...state, alertDetials: payload }
    default:
      return state
  }
}


// ------------address data---------
const addressData = {
  addresses: []
}
const GetAddress = (state = addressData, { type, payload }) => {
  switch (type) {
    case Action_types.USER_ADDRESS:
      return { ...state, addresses: payload }
    default:
      return state
  }
}

// ------------address data---------
const packagesData = {
  packages: []
}
const GetPackages = (state = packagesData, { type, payload }) => {
  switch (type) {
    case Action_types.PACKAGES:
      return { ...state, packages: payload }
    default:
      return state
  }
}



// ------------service rating---------
const GetServiceRating = (state = {}, { type, payload }) => {
  switch (type) {
    case Action_types.SERVICE_REATING:
      return { ...state, serviceReating: payload }
    default:
      return state
  }
}

// ----------Service_Review----------

const GetServiceReview = (state = { serivceReview: [] }, { type, payload }) => {
  switch (type) {
    case Action_types.SERVICE_REVIWS:
      return { ...state, serivceReview: payload }
    default:
      return state
  }
}


// -----show my packages-----
const Show_my_packages = (state = { myPackages: [] }, { type, payload }) => {
  switch (type) {
    case Action_types.SHOW_MY_PACKAGES:
      return { myPackages: payload }
    default:
      return state
  }
}


//----additional services-----
export const Get_additional_service = (state = { services: [] }, { type, payload }) => {
  switch (type) {
    case Action_types.ADDITIONAL_SERVICES:
      return { Additional_services: payload }
    default:
      return state
  }
}

// --------------order detials data------------

export const getOrderAllDetials = (state = { orderAllData: [] }, { type, payload }) => {
  switch (type) {
    case Action_types.OREDER_ALL_DETAILS:
      return { orderAllData: payload }
    default:
      return state
  }
}


const orderDetials = {
  ser_id: "",
  package_id: "",
  user_firebase_id: "",
  address_id: "",
  additional_service_id: [],
  coupon_id: "",
  date: "",
  time: ""
}

const GetOrderDetails = (state = orderDetials, { type, payload }) => {
  // alert(`${JSON.stringify(payload)}`)
  switch (type) {
    // ---
    case Action_types.ORDER_SER_ID:
      return { ...state, ser_id: payload }
    // --
    case Action_types.ADDITIONAL_SERVICE_LIST:
      return { ...state, additional_service_id: payload }
    // --
    case Action_types.PACKAGE_ID:
      return { ...state, package_id: payload }
    // --
    case Action_types.ORDER_DATE:
      return { ...state, date: payload }
    // --
    case Action_types.ORDER_TIME:
      return { ...state, time: payload }
    // --
    case Action_types.ORDER_ADDRESS_ID:
      return { ...state, address_id: payload }
    // --
    case Action_types.COUPEN_ID:
      return { ...state, coupon_id: payload }
    // --
    default:
      return state
  }
}


// -----payment detials get-----
const paymentDetials = {
  mainPrice: 0,
  discountPrice: {},
  package_price: 0,
  additionalServicesPrice: 0,
  totalPrice: 0,
  walletBalance: 0,
  walletBalanceAdd: 0,
}

export const GetPaymentDetails = (state = paymentDetials, { type, payload }) => {
  switch (type) {
    // --
    case Action_types.SERVICE_MAIN_PRICE:
      return { ...state, mainPrice: payload }
    // --
    case Action_types.PACKAGES_PRICE:
      return { ...state, package_price: payload }
    // --
    case Action_types.ADDITIONAL_SERVICES_PRICE:
      return { ...state, additionalServicesPrice: payload }
    // --
    case Action_types.DISCOUNT_PRICE:
      return { ...state, discountPrice: payload }
    // --
    case Action_types.TOTAL_PRICE:
      return { ...state, totalPrice: payload }
    //--
    case Action_types.WALLET_BALANCE:
      return { ...state, walletBalance: payload }
    //--
    case Action_types.WALLET_BALANCE_ADD:
      return { ...state, walletBalanceAdd: payload }
    default:
      return state
  }
}

//reviewsData
export const HomePageReviews = (state = { reviewsData_list: [] }, { type, payload }) => {
  switch (type) {
    case Action_types.HOME_PAGE_REVIEWS:
      return { ...state, reviewsData_list: payload }
    default:
      return state
  }
}



//GET_CATEGORIES_VIDEOS
export const GetCategoriesVideos = (state = { catData_list: [] }, { type, payload }) => {
  switch (type) {
    case Action_types.GET_CATEGORIES_VIDEOS:
      return { ...state, catData_list: payload }
    default:
      return state
  }
}







export { BannerImages, CatagoryReducer, AddGalleryImages, Subcategories, Showservices, Service_details, Showactivity, Showfavorite, ShowUserData, ShowAlerts, GetAddress, GetPackages, GetOrderDetails, GetServiceRating, GetServiceReview, Show_my_packages }