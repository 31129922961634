import React, { useEffect, useState } from 'react'
import PageLoadingComponent from '../Page_loading_component'
import { Add_gallery_images } from '../redux/actions/actions'
import { useDispatch, useSelector } from 'react-redux'
import { STATUSES } from '../redux/reducers/reducers'
import ImageErrorFallback from '../../commonFunctions/ImageErrorFallback'

function Gallery_images() {
    const dispatch = useDispatch()

    // --images selected--
    const [imgSrc, setImgSrc] = useState("")
    const getSrc = (e) => {
        setImgSrc(e.target.src)
    }

    const galleryImages = useSelector((state) => state.Add_gallery_images)
    useEffect(() => {
        galleryImages.status != STATUSES.IDEL && dispatch(Add_gallery_images())
    }, [])
    console.log(galleryImages)

    return (
        <>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content ">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <img
                                src={`${imgSrc}`}
                                className="card-img-top"
                                alt="..."
                                loading="lazy"
                                decoding="async"
                            />
                        </div>
                    </div>
                </div>
            </div>

            {galleryImages.status === STATUSES.LOADING ?
                <PageLoadingComponent />
                : galleryImages.status === STATUSES.ERROR || galleryImages.gallery_images.length < 1 ?

                    <div className="no_services_div my-4 mb-5">
                        <img src="../../images/no_images.gif" alt="#" />
                        <p>No Images Available</p>
                    </div>
                    :
                    <div className="galler_images_div">
                        {
                            galleryImages.gallery_images.map((e) => {
                                return (
                                    <div className="image" key={e.id} title={e.cat_name}>
                                        <figure data-bs-toggle="modal" data-bs-target="#exampleModal">
                                            <ImageErrorFallback
                                                src={`${process.env.REACT_APP_API_KEY}${e.file[0]}`}
                                                className="card-img-top"
                                                alt={e.cat_name}
                                                onClick={getSrc}
                                            />
                                            <figcaption>{e.cat_name}</figcaption>
                                        </figure>
                                    </div>
                                )

                            })}
                    </div>

            }
        </>
    )
}

export default Gallery_images