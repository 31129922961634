import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Page_loading_component from '../Page_loading_component'
import LazyLoad from 'react-lazyload';


function Gallery_videos() {
    const { CatId } = useParams()
    const navigate = useNavigate()
    // --images list--
    const [videoList, setvideoList] = useState()
    // --images selected--
    const [videoSrc, setvideoSrc] = useState("")
    const getSrc = (e) => {
        setvideoSrc(e)
        document.getElementById("showVideoPopUp").load()
    }

    const fatchImages = async () => {
        await axios.post("https://knocknoc-webpanel.knocknoc.sg/api/gallery", {
            "type": "video",
            "cat_id": `${CatId}`
        }).then((res) => {

            setvideoList(res.data.data ? res.data.data : [])
        }).catch((err) => {
            console.log(err)
        })
    }
    //   --video stop function--
    const videoStop = () => {
        document.getElementById("showVideoPopUp").pause();
    }


    useEffect(() => {
        fatchImages()
    }, [])

    return (
        <>
            <div className="modal fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content ">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={videoStop}></button>
                        </div>
                        <div className="modal-body bg-transparent">
                            <video style={{ width: "100%" }} stop="true" controls autoPlay id='showVideoPopUp' controlsList="nodownload" onContextMenu={e => e.preventDefault()}>
                                <source src={`${process.env.REACT_APP_API_KEY}${videoSrc}`} type="video/mp4" />
                            </video>

                        </div>
                    </div>
                </div>
            </div>

            {/* --backbtn-- */}
            <div className="backBtn">
                <button className='px-3 fs-4 fw-bold border-0 bg-gradient rounded-2 ' onClick={() => navigate(-1)}><i className="bi bi-arrow-left"></i></button>
            </div>

            {
                !videoList ? <>
                    <Page_loading_component />
                </> : [videoList.length < 1 ?
                    //  --if no items available then show it--
                    <>
                        <div className="no_services_div my-4">
                            <img src="../../images/no_video.gif" alt="#" />
                            <p>No Videos Available</p>
                        </div>
                    </>

                    :
                    <>
                        <div className="galler_images_div">
                            {videoList.map((e, ind) => {
                                return (
                                    <div key={`${ind}gVideos`} className="image" title={e.cat_name}>
                                        <LazyLoad height={200} once>
                                            <video style={{ width: "100%", borderRadius: "10px" }} preload={'auto'} data-bs-toggle="modal"
                                                data-bs-target="#exampleModal" onClick={() => getSrc(`${e.file}`)} controlsList="nodownload" onContextMenu={e => e.preventDefault()}>
                                                <source src={`${process.env.REACT_APP_API_KEY}${e.file}`} type="video/mp4" />
                                            </video>
                                        </LazyLoad>
                                        <p>{e.name}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                ]
            }

        </>
    )
}

export default Gallery_videos